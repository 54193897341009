import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'

/**
 * @component
 */
export default styled.div`
  min-height: ${ifProp('isInPopup', 'auto', '100vh')};
  background: ${colorsOptions.greys.white};
`
