import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  & path {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`

const Activate = (props) => (
  <Svg viewBox="0 0 32 32" {...props}>
    <path
      d="M17.7,14.186l3.623-.563L20.764,10l.563,3.618A6.5,6.5,0,1,0,23,17.969"
      transform="translate(-0.5 -1.5)"
    />
  </Svg>
)

export default Activate
