import styled, { css } from 'styled-components'
import { ifNotProp, ifProp, prop } from 'styled-tools'
import { PostActivity } from '../../../Activity/styledComponents'
import EditMedia from '../../../../../../components/Icons/EditMedia'
import colorsOptions from '../../../../../../components/stories/fundamentals/colors-styles/colors'
import {
  sizing,
  weights,
} from '../../../../../../components/stories/fundamentals/text-styles/text-styles'

export const TaskAttributesList = styled.div`
  display: flex;
  flex-direction: column;
`

export const TaskAttributeRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;
  padding: 8px 8px 8px 0;
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  &:last-child {
    border-bottom: none;
  }

  ${ifNotProp('flexsize', 'height: 56px', 'min-height: 56px;')}
  ${ifProp(
    'clickable',
    css`
      cursor: pointer;
    `,
  )}

 .icon {
    height: 40px;
    margin-left: -24px;
    flex: 0 0 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    ${ifNotProp('flexsize', 'align-self: flex-start;')}

    &.top {
      align-self: flex-start;
    }
  }
  .attribute {
    flex: 1;
    overflow: hidden;
    flex-direction: column;

    .label {
      ${sizing['text-s']};
      color: ${colorsOptions.greys['grey-50']};
      ${weights['text-500']};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 2px;
    }

    .flexText {
      ${sizing['text-r']};
      ${weights['text-600']};
    }

    .text {
      ${sizing['text-r']};
      ${weights['text-600']};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .placeholder {
      ${sizing['text-r']};
      color: ${colorsOptions.greys['grey-50']};
      ${weights['text-500']};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .subText {
      padding-top: 2px;
      ${sizing['text-s']};
      ${weights['text-500']};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ${ifProp(
        'warning',
        `color: ${colorsOptions.secondaryColors.red.strong};`,
      )}
    }

    .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .status {
      display: flex;

      .label {
      }
    }
  }
`

export const TaskLoading = styled.div`
  padding: 16px;
  background: ${colorsOptions.greys.white};
`

export const MemberCount = styled.div`
  color: ${ifProp(
    'warning',
    colorsOptions.secondaryColors.red.strong,
    'inherit',
  )};
  ${sizing['text-s']};
  ${weights['text-600']};
`

export const TaskDetailStatus = styled.div`
  & > span {
    vertical-align: middle;
    &:nth-child(2) {
      ${sizing['text-r']};
      ${weights['text-600']};
      color: ${colorsOptions.greys['grey-100']};
      margin-left: 16px;
    }
  }
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  align-items: center;
  background: ${prop('backgroundColor')};
  overflow: hidden;
  padding: 12px 0 12px 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${sizing['text-s']};
  color: ${colorsOptions.greys['grey-50']};
  height: 80px;
  padding: 28px 16px;
`

export const CommentWrapper = styled.div`
  margin-left: 50px;
  border-radius: 9px;
  margin-bottom: 8px;
  background: ${colorsOptions.greys.white};
  border: 1px solid ${colorsOptions.greys['grey-20']};
  padding: 16px;
  overflow: hidden;

  ${sizing['text-r']};
  ${weights['text-400']};

  .imageWrapper {
    margin-bottom: 8px;
    > div {
      border-radius: 0;
      margin-right: 0;
      :nth-child(even) {
        margin-left: 16px;
      }
    }
  }
  .description {
    span {
      white-space: pre-line;
    }
  }
`

export const CommentPostActivity = styled(PostActivity)`
  .date {
    margin-left: 38px;
  }
`
export const EditMediaIcon = styled(EditMedia)`
  cursor: pointer;
  border-radius: 12px;
  flex: 0 0 24px;
  margin-top: -10px;
  margin-right: -10px;
  color: ${colorsOptions.greys['grey-50']};

  &:hover {
    background: ${colorsOptions.greys['grey-30']};
  }
`
export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
  margin-top: 8px;
`
