/*
 *
 * Notification actions
 *
 */

import * as actionTypes from './constants'

export function updateEmailFrequency(emailFrequency, retries = 1) {
  return {
    type: actionTypes.UPDATE_EMAIL_FREQUENCY,
    emailFrequency,
    retries,
  }
}

export function getNotifications(page, size, ifMatch, retries = 1) {
  return {
    type: actionTypes.GET_NOTIFICATIONS,
    page,
    size,
    ifMatch,
    retries,
  }
}
export function markNotificaitonsAsRead(ids, markAllAsRead, retries = 1) {
  return {
    type: actionTypes.MARK_NOTIFICATIONS_AS_READ,
    ids,
    markAllAsRead,
    retries,
  }
}

export function getEmailFrequency(headers, retries = 1) {
  return {
    type: actionTypes.GET_EMAIL_FREQUENCY,
    headers,
    retries,
  }
}
export function getEmailFrequencyDone(emailFrequency) {
  return {
    type: actionTypes.GET_EMAIL_FREQUENCY_DONE,
    emailFrequency,
  }
}

export function getSubscriptions(headers, retries = 1) {
  return {
    type: actionTypes.GET_SUBSCRIPTIONS,
    headers,
    retries,
  }
}
export function getSubscriptionsDone(subscriptions) {
  return {
    type: actionTypes.GET_SUBSCRIPTIONS_DONE,
    subscriptions,
  }
}

export function toggleSubscriptionDone(subscriptionDetails, isSubscribed) {
  return {
    type: actionTypes.TOGGLE_SUBSCRIPTION_DONE,
    subscriptionDetails,
    isSubscribed,
  }
}

export function toggleSubscriptionError(error) {
  return {
    type: actionTypes.TOGGLE_SUBSCRIPTION_ERROR,
    error,
  }
}

export function updateEmailFrequencyError(error) {
  return {
    type: actionTypes.UPDATE_EMAIL_FREQUENCY_ERROR,
    error,
  }
}
export function getNotificationsDone(notifications) {
  return {
    type: actionTypes.GET_NOTIFICATIONS_DONE,
    notifications,
  }
}
export function getNotificationsError(error) {
  return {
    type: actionTypes.GET_NOTIFICATIONS_ERROR,
    error,
  }
}

export function setNotificationPage(page) {
  return {
    type: actionTypes.SET_NOTIFICATIONS_PAGE,
    page,
  }
}
export function checkUnreadNotifications(headers) {
  return {
    type: actionTypes.CHECK_UNREAD_NOTIFICATIONS,
    headers,
  }
}
export function checkUnreadNotificationsDone(status) {
  return {
    type: actionTypes.CHECK_UNREAD_NOTIFICATIONS_DONE,
    status,
  }
}
export function checkUnreadNotificationsError(error) {
  return {
    type: actionTypes.CHECK_UNREAD_NOTIFICATIONS_ERROR,
    error,
  }
}
