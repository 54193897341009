import { call, delay, put } from 'redux-saga/effects'
import { CancelToken } from 'axios'

const MAX_RETRIES = 3
const RETRY_DELAY = 1000

const sources = new Map()

function isForceRetry(options, err) {
  return (
    options.forceRetry &&
    (!err ||
      !err.response ||
      (!!options.forceRetryStatusCodes &&
        options.forceRetryStatusCodes.includes(err.response.status)) ||
      err.response.status >= 500)
  )
}

export function* retry(err, sagaFunc, action, options = {}) {
  const isGet = /method:.?'get'/.test(sagaFunc.toString())
  if (
    (isForceRetry(options, err) ||
      (err &&
        err.response &&
        (isGet || err.response.status !== 504) &&
        err.response.status >= 500)) &&
    (options.maxRetries
      ? action.retries < options.maxRetries
      : action.retries < MAX_RETRIES)
  ) {
    const retryDelay = action.retryDelay ?? options.retryDelay ?? RETRY_DELAY
    yield delay(retryDelay)
    yield call(sagaFunc, {
      ...action,
      retries: action.retries + 1,
      retryDelay: options.increasingDelay ? retryDelay * 2 : retryDelay,
    })
    return true
  }
  return false
}

/**
 * Creates a function that can be used to wrap a saga and returns a new saga.
 * The returned saga will retry the provided saga once. If it fails again it will dispatch the `errorAction`.
 * @param {function} errorAction The Redux action to be dispatched in case of error.
 */
export const makeRetryOnce = (errorAction) => (saga) =>
  function* mro(action) {
    try {
      yield* saga(action)
    } catch (err) {
      const retried = yield call(retry, err, saga, action)
      if (!retried) {
        window.Rollbarerror(`Saga error`, err)
        yield put(errorAction(err))
      }
    }
  }

/**
 * Can be used to wrap a saga and returns a new saga.
 * The inners saga _must_ return the data from a paginated docu tools endpoint, or `content` (array), `totalPages` and `totalElements` (number) at a minimum.
 * The returned saga will dispatch `progressAction` for every page, and `doneAction` at the end if provided.
 * eslint-disable-next-line func-names
 * @param {func} saga
 * @param {func} progressAction
 * @param {func} doneAction
 * @param {bool} isAsync
 */
export const getAllPages = (saga, progressAction, doneAction, isAsync) =>
  function* gap(action) {
    let allContent = []
    let content
    let page = 0
    let totalPages
    let totalElements
    do {
      ;({ content, totalPages, totalElements } = yield* saga({
        ...action,
        page,
      }))
      allContent = allContent.concat(content)
      if (isAsync) yield put(doneAction(allContent))
      if (progressAction) {
        const progress = allContent.length / totalElements
        yield put(progressAction(allContent, { progress }))
      }
    } while (++page < totalPages)
    if (doneAction) yield put(doneAction(allContent))
  }

// Can be used to wrap a saga and returns a new saga.
// The action will be extended by a `cancelToken` that can be passed to axios.
// When the returned saga gets invoked again the token will be cancelled, aborting any requests that are still in progress.
// eslint-disable-next-line func-names
export const withCancelToken = (saga) =>
  function* (action) {
    const source = CancelToken.source()
    sources.get(saga)?.cancel() // eslint-disable-line no-unused-expressions
    sources.set(saga, source)
    yield* saga({ cancelToken: source.token, ...action })
  }
