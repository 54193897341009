import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  makeSelectLoggedIn
} from './selectors'
import Logout from './Logout'

const ForceLogout = ({ children }) => {
  const loggedIn = useSelector(makeSelectLoggedIn())

  if (loggedIn) {
    return <Logout />
  }
  return <>{children}</>
}

ForceLogout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default ForceLogout
