/* eslint-disable arrow-body-style */
import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

export const selectLanguage = (state) => state.get('language')

export const makeSelectLocale = () =>
  createSelector(selectLanguage, (languageState) => languageState.get('locale'))

export const makeSelectLocaleChanged = () =>
  createSelector(selectLanguage, (languageState) =>
    languageState.get('localeChanged'),
  )

export const makeSelectCountryNames = () =>
  createSelector(selectLanguage, (languageState) => {
    // FIXME: Avoid costly immutable conversion
    const countryNames = languageState.get('countryNames')?.toJS()
    delete countryNames.default

    return countryNames
  })

export const makeSelectCountryNamesAsOptions = () =>
  createSelector(makeSelectCountryNames(), (countryNames) => {
    if (countryNames) {
      const options = Object.entries(countryNames).map(([code, name]) => ({
        label: name,
        value: code,
      }))
      return sortBy(options, ['label'])
    }
    return []
  })

export const makeSelectMessages = () =>
  createSelector(selectLanguage, (languageState) => {
    // FIXME: Avoid costly immutable conversion
    return languageState.get('messages')?.toJS()
  })
