import {
  CurrencyIcon,
  DateIcon,
  NumberIcon,
  PercentageIcon,
  SliderIcon,
  TagIcon,
  TextIcon,
  TimeIcon
} from '../../../PinDetails/images'

export const NUMBER = 'NUMBER'
export const CURRENCY = 'CURRENCY'
export const TIME = 'TIME'
export const PERCENTAGE = 'PERCENTAGE'
export const DATE = 'DATE'
export const TAGS = 'TAGS'
export const TEXT = 'TEXT'
export const SLIDER = 'SLIDER'

export const fieldIcon = {
  [NUMBER]: (props) => <NumberIcon {...props} />,
  [CURRENCY]: (props) => <CurrencyIcon {...props} />,
  [PERCENTAGE]: (props) => <PercentageIcon {...props} />,
  [TIME]: (props) => <TimeIcon {...props} />,
  [DATE]: (props) => <DateIcon {...props} />,
  [TEXT]: (props) => <TextIcon {...props} />,
  [SLIDER]: (props) => <SliderIcon {...props} />,
  [TAGS]: (props) => <TagIcon {...props} />
}

export const FIELD_TYPES = {
  SLIDER: 'SLIDER',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  TEXT: 'TEXT',
  TAGS: 'TAGS',
  TIME: 'TIME'
}

export const FIELD_INFO = {
  [SLIDER]: 'projectSettings_slider_info',
  [TAGS]: 'projectSettings_tags_info',
  [DATE]: 'projectSettings_date_info',
  [NUMBER]: [
    'projectSettings_number_info1',
    'projectSettings_number_info2',
    'projectSettings_number_info3',
    'projectSettings_number_info4'
  ],
  [TIME]: 'projectSettings_time_info',
  [TEXT]: 'projectSettings_text_info'
}

export const fieldType = {
  [NUMBER]: 'number',
  [CURRENCY]: 'currency',
  [PERCENTAGE]: 'percentage',
  [TIME]: 'time',
  [DATE]: 'date',
  [TEXT]: 'text',
  [SLIDER]: 'slider',
  [TAGS]: 'tags'
}

export const newFieldDetails = (formatMessage) => ({
  [SLIDER]: {
    type: 'SLIDER',
    labels: [
      '',
      `${formatMessage({ id: 'level' })} 1`,
      `${formatMessage({ id: 'level' })} 2`,
      `${formatMessage({ id: 'level' })} 3`,
      `${formatMessage({ id: 'level' })} 4`,
      `${formatMessage({ id: 'level' })} 5`
    ],
    sliderBounds: {
      from: 0,
      to: 5
    }
  },
  [TAGS]: {
    type: 'TAGS',
    categories: []
  },
  [DATE]: {
    type: 'DATE',
    pattern: 'dd.MM.yy'
  },
  [NUMBER]: {
    type: 'NUMBER',
    format: {
      set: 'REAL',
      precision: 8,
      scale: 2
    },
    numberType: 'NUMBER',
    suffix: 'qm'
  },
  [TIME]: {
    type: 'TIME'
  },
  [TEXT]: {
    type: 'TEXT'
  }
})
