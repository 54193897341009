import { DateTime } from 'luxon'
import safeIsNaN from './safeIsNaN'

export const sortCategories = (a, b) => {
  if (a.active && !b.active) return -1
  if (!a.active && b.active) return 1
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
  return 0
}

export const sortObjectsByName = (a, b) => {
  const collator = Intl.Collator(undefined, { numeric: true })
  return collator.compare(a, b)
}

export const sortPins = (sortBy, sortDirection) => (a, b) => {
  let sortResult = 0
  let direction = sortDirection
  if (sortBy) {
    let aFactor
    let bFactor
    switch (sortBy) {
      case 'Category':
        aFactor = (a.getIn(['category', 'name']) || '').toLowerCase()
        bFactor = (b.getIn(['category', 'name']) || '').toLowerCase()
        break
      case 'Plan':
        aFactor = (a.getIn(['planReference', 'planName']) || '').toLowerCase()
        bFactor = (b.getIn(['planReference', 'planName']) || '').toLowerCase()
        break
      case 'LastModified':
        aFactor = new Date(a.get('lastModified') || '')
        bFactor = new Date(b.get('lastModified') || '')
        // Here we reverse it so that ASC = from most recent to oldest
        direction = sortDirection === 'DESC' ? 'ASC' : 'DESC'
        break
      case 'Status':
        aFactor = (a.get('state') || '').toLowerCase()
        bFactor = (b.get('state') || '').toLowerCase()
        break
      default: {
        const lowerCaseSortBy = sortBy.toLowerCase()
        if (a.has(lowerCaseSortBy) && b.has(lowerCaseSortBy)) {
          aFactor = a.get(lowerCaseSortBy)
          bFactor = b.get(lowerCaseSortBy)
          break
        } else {
          return sortResult
        }
      }
    }
    if (aFactor !== bFactor) {
      if (!safeIsNaN(aFactor) && !safeIsNaN(bFactor)) {
        sortResult = Number(aFactor) < Number(bFactor) ? -1 : 1
      } else {
        sortResult = aFactor.localeCompare(bFactor)
      }
      if (direction === 'DESC') {
        sortResult *= -1
      }
    }
  }
  return sortResult
}

export const sortProjectContent = (sort) => (a, b) => {
  if (a && !b) return -1
  if (sort === 'Title' && a && a.get('name')) {
    return a.get('name').localeCompare(b.get('name'))
  }
  if (a && a.get('lastActivity')) {
    return a.get('lastActivity').localeCompare(b.get('lastActivity')) * -1
  }
  return 0
}

export const sortProjectTeam = (type, sortDirection) => (a, b) => {
  if (sortDirection) {
    const collator = new Intl.Collator(undefined, { numeric: true })
    let aProp
    let bProp

    if (type === 'Role') {
      aProp = a.getIn(['role', 'name'])?.toLowerCase()
      bProp = b.getIn(['role', 'name'])?.toLowerCase()
    } else if (type === 'Company') {
      aProp = a.get('companyName')?.toLowerCase()
      bProp = b.get('companyName')?.toLowerCase()
    } else {
      const typeLowerCase = type?.toLowerCase()
      aProp = a.get(typeLowerCase)?.toLowerCase()
      bProp = b.get(typeLowerCase)?.toLowerCase()
    }
    return collator.compare(aProp, bProp) * (sortDirection === 'ASC' ? 1 : -1)
  }
  return 0
}

const lastModifiedOrder = ['THIS WEEK', 'LAST WEEK', 'THIS MONTH']
export const sortGroupedProjects = (type) => (a, b) => {
  const indexA = lastModifiedOrder.indexOf(a)
  const indexB = lastModifiedOrder.indexOf(b)

  if (type === 'lastModified') {
    if (indexA > -1 && indexB === -1) {
      return -1
    }
    if (indexA > -1 && indexB > -1) {
      if (indexA < indexB) return -1
      if (indexA > indexB) return 1
    }
    if (indexA === -1 && indexB > -1) {
      return 1
    }
    if (indexA === -1 && indexB === -1) {
      if (DateTime.fromFormat(a, 'MMM yyyy') > DateTime.fromFormat(b, 'MMM yyyy')) return -1
      if (DateTime.fromFormat(a, 'MMM yyyy') < DateTime.fromFormat(b, 'MMM yyyy')) return 1
    }
  }

  if (type === 'offlineOnline') {
    if (b === 'offlineAvailableContent') return 1
    return -1
  }
  return 0
}

export const groupProjects = (sortBy) => (project) => {
  if (sortBy === 'lastModified') {
    if (DateTime.fromISO(project.lastModified).hasSame(DateTime.now(), 'week')) {
      return 'THIS WEEK'
    }
    if (DateTime.fromISO(project.lastModified).hasSame(DateTime.now().startOf('week').minus({ days: 1 }), 'week')) {
      return 'LAST WEEK'
    }
    if (DateTime.fromISO(project.lastModified).hasSame(DateTime.now(), 'month')) {
      return 'THIS MONTH'
    }
    return DateTime.fromISO(project.lastModified).toFormat('MMMM yyyy')
  }
  if (sortBy === 'offlineOnline') {
    if (!project.offlineAvailablePlans) {
      return 'onlineOnlyAvailableContent'
    }
    return 'offlineAvailableContent'
  }
  return 'UNGROUPED'
}

export const groupPlans =
  (sortBy, plansSyncStatus = {}, foldersSyncStatus = {}) =>
    (plan) => {
      if (sortBy === 'LastActivity') {
        if (DateTime.fromISO(plan.lastActivity).hasSame(DateTime.now(), 'week')) {
          return 'THIS WEEK'
        }
        if (DateTime.fromISO(plan.lastActivity).hasSame(DateTime.now().startOf('week').minus({ days: 1 }), 'week')) {
          return 'LAST WEEK'
        }
        if (DateTime.fromISO(plan.lastActivity).hasSame(DateTime.now(), 'month')) {
          return 'THIS MONTH'
        }
        return DateTime.fromISO(plan.lastActivity).toFormat('MMMM yyyy')
      }
      if (sortBy === 'offlineOnline') {
        if (plansSyncStatus[plan.id]) {
          return 'offlineAvailableContent'
        }
        if (plan.type === 'Folder' && foldersSyncStatus[plan.id]) {
          return 'offlineAvailableContent'
        }
        return 'onlineOnlyAvailableContent'
      }
      return 'UNGROUPED'
    }

export const sortGroupedPlansKeys = (type) => (a, b) => {
  const indexA = lastModifiedOrder.indexOf(a)
  const indexB = lastModifiedOrder.indexOf(b)

  if (type === 'LastActivity') {
    if (indexA > -1 && indexB === -1) {
      return -1
    }
    if (indexA > -1 && indexB > -1) {
      if (indexA < indexB) return -1
      if (indexA > indexB) return 1
    }
    if (indexA === -1 && indexB > -1) {
      return 1
    }
    if (indexA === -1 && indexB === -1) {
      if (DateTime.fromFormat(a, 'MMM yyyy') > DateTime.fromFormat(b, 'MMM yyyy')) return -1
      if (DateTime.fromFormat(a, 'MMM yyyy') < DateTime.fromFormat(b, 'MMM yyyy')) return 1
    }
  }

  if (type === 'offlineOnline') {
    if (b === 'offlineAvailableContent') return 1
    return -1
  }
  return 0
}

export const sortFolderOnTop = (a, b) => {
  if (a.type === 'Folder' && b.type !== 'Folder') return -1
  if (a.type !== 'Folder' && b.type === 'Folder') return 1
  return 0
}

export const groupPins = (sortBy, formatMessage) => (pin) => {
  if (sortBy === 'LastModified') {
    const now = DateTime.now()
    const then = DateTime.fromISO(pin.lastModified)
    if (then.hasSame(now, 'day')) {
      return formatMessage({ id: 'projectLastUpdated_today' })
    }
    if (then.hasSame(now, 'week')) {
      return formatMessage({ id: 'projects_thisWeek' })
    }
    if (then.hasSame(now.startOf('week').minus({ days: 1 }), 'week')) {
      return formatMessage({ id: 'projects_lastWeek' })
    }
    if (then.hasSame(now.startOf('month').minus({ days: 1 }), 'month')) {
      return formatMessage({ id: 'last_month' })
    }
    if (then.hasSame(now, 'month')) {
      return formatMessage({ id: 'projects_thisMonth' })
    }
    if (then.hasSame(now, 'year')) {
      return then.toFormat('MMMM').toUpperCase()
    }
    return then.toFormat('MMMM yyyy').toUpperCase()
  }
  if (sortBy === 'Category') {
    return pin.category.name
  }
  if (sortBy === 'Status') {
    return pin.state
  }
  return 'UNGROUPED'
}
