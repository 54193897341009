/* eslint-disable max-len */
import { number, string } from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../../../components/stories/fundamentals/colors-styles/colors'

export const DateSvg = styled.svg`
  height: ${prop('size', 18.5)}px;
  width: ${prop('size', 17)}px;
  .a,
  .e {
    fill: none;
  }
  .a,
  .b {
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
  }
  .b,
  .c {
    fill: ${colorsOptions.greys.white};
  }
  .d,
  .f {
    stroke: none;
  }
  .f {
    fill: ${prop('color', colorsOptions.greys['grey-100'])};
  }
`

function DateIcon({ color, size }) {
  return (
    <DateSvg
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      size={size}
      viewBox="0 0 17 18.5"
    >
      <g transform="translate(0.5)">
        <rect
          className="a"
          width="16"
          height="16"
          rx="2"
          transform="translate(0 2)"
        />
        <line className="a" x2="16" transform="translate(0.5 7)" />
        <g className="b" transform="translate(2)">
          <circle className="d" cx="2" cy="2" r="2" />
          <circle className="e" cx="2" cy="2" r="1.5" />
        </g>
        <g className="c" transform="translate(10)">
          <path
            className="d"
            d="M 2 3.5 C 1.172899961471558 3.5 0.5 2.827100038528442 0.5 2 C 0.5 1.172899961471558 1.172899961471558 0.5 2 0.5 C 2.827100038528442 0.5 3.5 1.172899961471558 3.5 2 C 3.5 2.827100038528442 2.827100038528442 3.5 2 3.5 Z"
          />
          <path
            className="f"
            d="M 2 1 C 1.448600053787231 1 1 1.448600053787231 1 2 C 1 2.551399946212769 1.448600053787231 3 2 3 C 2.551399946212769 3 3 2.551399946212769 3 2 C 3 1.448600053787231 2.551399946212769 1 2 1 M 2 0 C 3.104569911956787 0 4 0.8954300880432129 4 2 C 4 3.104569911956787 3.104569911956787 4 2 4 C 0.8954300880432129 4 0 3.104569911956787 0 2 C 0 0.8954300880432129 0.8954300880432129 0 2 0 Z"
          />
        </g>
      </g>
    </DateSvg>
  )
}

DateIcon.propTypes = {
  color: string,
  size: number,
}

export default DateIcon
