/* eslint-disable max-len */
import { number, string } from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../../../components/stories/fundamentals/colors-styles/colors'

export const TextSvg = styled.svg`
  height: ${prop('size', 17)}px;
  width: ${prop('size', 17)}px;
  .a {
    fill: none;
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
  }
  .b {
    fill: ${prop('color', colorsOptions.greys['grey-100'])};
  }
`

function TextIcon({ color, size }) {
  return (
    <TextSvg
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      size={size}
      viewBox="0 0 17 17"
    >
      <g transform="translate(0.5 0.5)">
        <path
          className="a"
          d="M2,0H14a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
        />
        <g transform="translate(-18.506 -313.5)">
          <path
            className="b"
            d="M-2.08-5.52v.952H-.56V0H.56V-4.568H2.08V-5.52Z"
            transform="translate(24 324)"
          />
          <g transform="translate(-131.796 252.574)">
            <g transform="translate(158.796 63.426)">
              <path
                className="b"
                d="M160.8,63.837a1.006,1.006,0,0,1,.878-.406c.33,0,.66,0,.989,0,.107,0,.143.037.141.142q-.008.379,0,.758c0,.1-.039.135-.138.134-.279,0-.559,0-.838,0a.468.468,0,0,0-.515.511q0,3.452,0,6.9a.469.469,0,0,0,.515.512c.279,0,.559,0,.838,0,.1,0,.14.03.138.133-.005.26,0,.521,0,.781,0,.065-.011.118-.092.117-.407,0-.815.021-1.221-.011a.89.89,0,0,1-.69-.393,1.05,1.05,0,0,1-.953.406c-.306-.006-.613,0-.919,0-.1,0-.137-.036-.135-.136q.007-.385,0-.77c0-.1.038-.129.131-.128.283,0,.566,0,.85,0a.466.466,0,0,0,.509-.505q0-3.458,0-6.916a.466.466,0,0,0-.509-.505c-.283,0-.566,0-.85,0-.093,0-.133-.03-.132-.128,0-.26,0-.521,0-.781,0-.062,0-.123.087-.122.423.005.846-.028,1.267.017A.861.861,0,0,1,160.8,63.837Z"
                transform="translate(-158.796 -63.426)"
              />
            </g>
          </g>
        </g>
      </g>
    </TextSvg>
  )
}

TextIcon.propTypes = {
  color: string,
  size: number,
}

export default TextIcon
