import { useEffect, useState } from 'react'
import { number } from 'prop-types'

/**
 * LoadingDots Component,
 * A dot placeholder while loading
 */
const LoadingDots = ({ max = 3, time = 1000 }) => {
  let dotTimeout
  const [dots, setDots] = useState('.'.padEnd(max))

  useEffect(
    () => () => {
      clearTimeout(dotTimeout)
    },
    [],
  )
  useEffect(() => {
    clearTimeout(dotTimeout)
    dotTimeout = setTimeout(() => {
      let newDots = '.'
      const currentDots = dots.replace(/ /g, '')
      if (currentDots.length < max) {
        newDots = `${currentDots}.`
      }

      newDots = newDots.padEnd(max)

      setDots(newDots)
    }, time)
  })
  return <span>{dots}</span>
}

LoadingDots.propTypes = {
  /** The maximum number of dots to display */
  max: number,
  /** time in millisecs between each dot animated display */
  time: number,
}

export default LoadingDots
