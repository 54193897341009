import { put, takeLatest, call, select } from 'redux-saga/effects'

import { makeSelectLocale } from '../LanguageProvider/selectors'
import * as selectors from './selectors'
import axios from '../../utils/api'
import * as actions from './actions'
import * as actionTypes from './constants'
import { retry } from '../../utils/retrySaga'
import { callAPI } from '../../utils/callAPI'
import { mapboxGeocodeUrl } from '../../../proxyConfig/apiUrls'
import config from '../../../proxyConfig'

export function* registerUser(action) {
  try {
    const email = yield select(selectors.makeSelectEmail())
    const emailTakenResponse = yield call(callAPI, {
      method: 'get',
      url: '/api/users/email',
      params: {
        email,
      },
    })

    const emailTaken = emailTakenResponse.data
    if (emailTaken) {
      yield put(actions.emailTaken())
    } else {
      const language = yield select(makeSelectLocale())
      const countryCode = yield select(selectors.makeSelectCountry())
      const firstName = yield select(selectors.makeSelectFirstName())
      const lastName = yield select(selectors.makeSelectLastName())
      const organisationName = yield select(selectors.makeSelectOrganisation())
      const phone = yield select(selectors.makeSelectPhone())
      const originationCode = yield select(
        selectors.makeSelectOriginationCode(),
      )

      yield call(callAPI, {
        method: 'post',
        url: '/api/register',
        data: {
          email,
          organisationName,
          countryCode,
          firstName,
          lastName,
          language,
          phone,
          originationCode,
        },
      })
      yield put(actions.registerUserSuccessful())
    }
  } catch (err) {
    const retried = yield call(retry, err, registerUser, action)
    if (!retried) {
      window.Rollbarerror('app/Register/sagas.js registerUser error', err)
      yield put(actions.registerUserError(err))
    }
  }
}

export function* registerUserWatcher() {
  yield takeLatest(actionTypes.REGISTER_USER, registerUser)
}

export function* getCountry(action) {
  try {
    const { data: countryDetail } = yield call(axios, {
      method: 'get',
      url: `${mapboxGeocodeUrl}/${action.longitude},${action.latitude}.json?access_token=${config.mapboxAccessToken}&limit=1&types=country`,
    })

    yield put(actions.getCountryDone(countryDetail))
  } catch (err) {
    const retried = yield call(retry, err, getCountry, action)
    if (!retried) {
      window.Rollbarerror('app/Register/sagas.js getCountry error', err)
      yield put(actions.getCountryError(err))
    }
  }
}

export function* getCountryWatcher() {
  yield takeLatest(actionTypes.GET_COUNTRY, getCountry)
}

export default [registerUserWatcher, getCountryWatcher]
