import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import * as actions from './actions'
import { Logout } from './styledComponents'
import { withRouter } from '../../utils/hocs'

export const LogoutButton = ({ logout, children }) => (
  <Logout onClick={logout}>{children}</Logout>
)

LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
}

const mapStateToProps = createStructuredSelector({})

function mapDispatchToProps(dispatch, ownProps) {
  const {
    history: { push },
  } = ownProps

  return {
    logout: () => {
      dispatch(actions.logout())
      push('/')
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LogoutButton),
)
