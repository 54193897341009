import styled from 'styled-components'
import { ifProp, withProp } from 'styled-tools'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeSelectIsMobile } from '../../containers/App/selectors'

export const Page = styled.div`
  padding: ${ifProp('isMobile', '0', '10px 48px 50px 48px')};
  position: relative;
  @media (max-width: 767px) {
    padding: 0;
  }
  ${withProp('marginTop', (marginTop) =>
    marginTop ? `margin-top: ${marginTop}px` : '',
  )};
  ${ifProp('nooverflow', 'overflow: hidden;')}
`

const mapStateToProps = createStructuredSelector({
  isMobile: makeSelectIsMobile(),
})

/**
 * A wrapper component to wrap pages.
 * @component
 */
export default connect(mapStateToProps)(Page)
