import React, { useEffect, useRef } from 'react'
import { func, any } from 'prop-types'

/**
 * Hook that closes if you click or press escape outside of it
 */
function useOutsideClickHandler (onCloseIntent, ref) {
  useEffect(() => {
    const handleEscape = (event) => {
      if (
        event.key === 'Escape' ||
        (ref.current && !ref.current.contains(event.target))
      ) {
        onCloseIntent && onCloseIntent(event)
      }
    }
    document.addEventListener('keydown', handleEscape)
    document.addEventListener('mousedown', handleEscape)
    return () => {
      document.removeEventListener('keydown', handleEscape)
      document.removeEventListener('mousedown', handleEscape)
    }
  }, [ref, onCloseIntent])
}

/**
 * Wrapper that closes if you click outside of it
 */
function OutsideClickWrapper (props) {
  const wrapperRef = useRef(null)
  useOutsideClickHandler(props.onCloseIntent, wrapperRef)

  return (
    <div ref={wrapperRef} style={props.style}>
      {props.children}
    </div>
  )
}

OutsideClickWrapper.propTypes = {
  children: any.isRequired,
  onCloseIntent: func.isRequired
}

export default OutsideClickWrapper
