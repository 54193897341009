import { expiredToken } from '../containers/Authentication/actions'

export default function tokenExpiredMiddleware() {
  return (dispatch) => (action) => {
    if (/_ERROR$/.test(action.type)) {
      if (
        action.error &&
        action.error.response &&
        action.error.response.status === 401
      ) {
        if (
          action.error.response.data &&
          action.error.response.data.error === 'invalid_token' &&
          action.error.response.data.error_description.includes(
            'Access token expired:',
          )
        ) {
          dispatch(expiredToken(true))
        }
      }
    }
    dispatch(action)
  }
}
