/* eslint-disable eqeqeq */
import React, { lazy, Suspense, useState } from 'react'
import { bool, func, number, object, string } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { usersAPI } from '../../../../../../../../proxyConfig/apiUrls'
import {
  AssigneeDetail,
  AssigneeOption,
  AssigneeType,
  CategoryName,
  TeamInfo,
} from '../../../../../styledComponents'
import {
  contactIcon,
  contactIconOrange,
  iconRemovedMember as IconRemovedMember,
  infoIcon,
  memberIcon,
  memberIconOrange,
  teamIcon,
  teamIconActive,
  variousIcon,
} from '../../../../../images'
import { MemberCount } from '../styledComponents'
import Image from '../../../../../../../components/Image'

const TeamGroupDetail = lazy(
  () => import(/* webpackChunkName: "TeamGroupDetail" */ './TeamGroupDetail'),
)

const iconByType = {
  User: {
    true: memberIconOrange,
    false: memberIcon,
  },
  Contact: {
    true: contactIconOrange,
    false: contactIcon,
  },
  TeamGroup: {
    true: teamIconActive,
    false: teamIcon,
  },
  Various: {
    true: variousIcon,
    false: variousIcon,
  },
}

const Assignee = React.memo(
  ({
    onClick,
    id,
    type,
    active = false,
    textActive = false,
    expands,
    name,
    companyName,
    memberCount,
    state: status,
    style,
    className,
    accessToken,
    avatarSize,
    nameColor,
  }) => {
    const [expanded, setExpanded] = useState(false)
    const toggleExpanded = (e) => {
      e.stopPropagation()
      setExpanded(!expanded)
    }
    const isTeam = type === 'TeamGroup'
    const isRemoved = status === 'Removed'
    return (
      <>
        <AssigneeDetail
          isRemoved={isRemoved}
          className={className}
          onClick={onClick}
          style={style}
        >
          <AssigneeType isTeamGroup={isTeam && !isRemoved}>
            {isRemoved && (
              <img src={IconRemovedMember} alt="assignee type" width="20" />
            )}
            {!isRemoved &&
              (!avatarSize ? (
                <img src={iconByType[type][active]} alt="assignee type" />
              ) : (
                <Image
                  sources={[`${usersAPI}/${id}/avatar${accessToken}`]}
                  alt="avatar"
                  noPreview={iconByType[type][active]}
                  noPreviewStyle={{ width: 20, height: 20, borderRadius: 10 }}
                  noPreviewImgStyle={{ width: 20, height: 20 }}
                  width={avatarSize}
                  height={avatarSize}
                  style={{
                    width: avatarSize,
                    height: avatarSize,
                    borderRadius: avatarSize,
                    overflow: 'hidden',
                  }}
                />
              ))}
          </AssigneeType>
          <AssigneeOption>
            <CategoryName
              color={nameColor}
              className={active ? 'active' : ''}
              removed={status === 'Removed'}
            >
              {name}
            </CategoryName>
            {!!companyName /* todo dedicated styled component */ && (
              <CategoryName className={textActive ? 'active' : ''}>
                {companyName}
              </CategoryName>
            )}
            {isTeam && !!memberCount && (
              <MemberCount warning={!memberCount}>
                <FormattedMessage
                  id="teams_MemberCount"
                  values={{ count: memberCount }}
                />
              </MemberCount>
            )}
          </AssigneeOption>
          {expands && !!memberCount && (
            <TeamInfo onClick={toggleExpanded}>
              <img src={infoIcon} alt="" />
            </TeamInfo>
          )}
        </AssigneeDetail>
        {expanded && (
          <Suspense fallback={null}>
            <TeamGroupDetail teamId={id} setOpen={toggleExpanded} />
          </Suspense>
        )}
      </>
    )
  },
)

Assignee.propTypes = {
  onClick: func,
  id: string.isRequired,
  name: string.isRequired,
  type: string.isRequired,
  companyName: string,
  memberCount: number,
  state: string,
  active: bool,
  textActive: bool,
  expands: bool,
  style: object,
  className: string,
  nameColor: string,
  accessToken: string,
  avatarSize: number,
}

export default Assignee
