import styled, { css } from 'styled-components'
import { sizeOptions } from './badges'
import colorsOptions from '../../fundamentals/colors-styles/colors'
import { weights } from '../../fundamentals/text-styles/text-styles'

const BadgeBase = styled.div`
  border: none;
  outline: none;
  display: inline-flex;
  white-space: nowrap;
  border-radius: 4px;
  place-items: center;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  ${weights['text-600']}

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ size = 'medium' }) => css`
    ${sizeOptions[size]}
  `}

  & svg {
    ${({ size = 'medium' }) => css`
      width: ${sizeOptions[size]?.iconSize};
      height: ${sizeOptions[size]?.iconSize};
      stroke: inherit;
      color: inherit;
    `}
    & path {
      stroke: inherit;
      stroke-width: 2;
    }
  }

  line-height: 0.7rem;
`

const minimalVariant = styled(BadgeBase)`
  background-color: ${colorsOptions.greys.white};
  border: 1px solid ${colorsOptions.greys['grey-70']};
  color: ${colorsOptions.greys['grey-70']};
  stroke: ${colorsOptions.greys['grey-70']};
`

const subtleColorStyles = {
  'docu-tools-dark': css`
    border: 1px solid ${colorsOptions.greys.black};
    color: ${colorsOptions.greys.black};
    stroke: ${colorsOptions.greys.black};
  `,
  'docu-tools-orange': css`
    border: 1px solid ${colorsOptions.docuToolsOrange['dt-40']};
    color: ${colorsOptions.docuToolsOrange['dt-40']};
    stroke: ${colorsOptions.docuToolsOrange['dt-40']};
  `,
  disabled: css`
    border: 1px solid ${colorsOptions.greys['grey-40']};
    color: ${colorsOptions.greys['grey-40']};
    stroke: ${colorsOptions.greys['grey-40']};
  `,
  default: css`
    border: 1px solid
      ${({ color }) => colorsOptions.secondaryColors[color].strong};
    color: ${({ color }) => colorsOptions.secondaryColors[color].strong};
    stroke: ${({ color }) => colorsOptions.secondaryColors[color].strong};
  `
}

const subtleVariant = styled(BadgeBase)`
  ${({ color = 'docu-tools-dark' }) =>
    subtleColorStyles[color] || subtleColorStyles.default}
`

const strongColorStyles = {
  'docu-tools-dark': css`
    background-color: ${colorsOptions.greys['grey-20']};
    color: ${colorsOptions.greys.black};
    stroke: ${colorsOptions.greys.black};
  `,
  'docu-tools-orange': css`
    background-color: ${colorsOptions.docuToolsOrange['dt-10']};
    color: ${colorsOptions.docuToolsOrange['dt-40']};
    stroke: ${colorsOptions.docuToolsOrange['dt-40']};
  `,
  disabled: css`
    background-color: ${colorsOptions.greys['grey-20']};
    color: ${colorsOptions.greys['grey-40']};
    stroke: ${colorsOptions.greys['grey-40']};
  `,
  default: css`
    background-color: ${({ color }) =>
      colorsOptions.secondaryColors[color].pale};
    color: ${({ color }) => colorsOptions.secondaryColors[color].strong};
    stroke: ${({ color }) => colorsOptions.secondaryColors[color].strong};
  `
}

const strongVariant = styled(BadgeBase)`
  ${({ color = 'docu-tools-dark' }) =>
    strongColorStyles[color] || strongColorStyles.default}
`

const highlightedColorStyles = {
  'docu-tools-dark': css`
    background-color: ${colorsOptions.greys.black};
    color: ${colorsOptions.greys.white};
    stroke: ${colorsOptions.greys.white};
  `,
  'docu-tools-orange': css`
    background-color: ${colorsOptions.docuToolsOrange.orange};
    color: ${colorsOptions.greys.white};
    stroke: ${colorsOptions.greys.white};
  `,
  disabled: css`
    background-color: ${colorsOptions.greys['grey-40']};
    color: ${colorsOptions.greys.white};
    stroke: ${colorsOptions.greys.white};
  `,
  default: css`
    background-color: ${({ color }) =>
      colorsOptions.secondaryColors[color].normal};
    color: ${colorsOptions.greys.white};
    stroke: ${colorsOptions.greys.white};
  `
}

const highlightedVariant = styled(BadgeBase)`
  ${({ color = 'docu-tools-dark' }) =>
    highlightedColorStyles[color] || highlightedColorStyles.default}
`

export { minimalVariant, subtleVariant, strongVariant, highlightedVariant }
