import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { H1 } from './styledComponents'

/**
 * Useful for page headings.
 */
function PageTitle(props) {
  const { className, wrapLine, maxWidth, dataTestid, title, values } = props
  return (
    <H1
      className={className}
      wrapLine={wrapLine}
      maxWidth={maxWidth}
      data-testid={dataTestid}
    >
      <FormattedMessage {...title} values={values} />
    </H1>
  )
}

PageTitle.propTypes = {
  /** formattedMessage object with id and defaultMessage */
  title: PropTypes.object.isRequired,
  /** value data to be injected into the title message */
  values: PropTypes.object,
  maxWidth: PropTypes.number,
  /** defines whit-space to normal else it uses nowrap */
  wrapLine: PropTypes.bool,
  dataTestid: PropTypes.string,
  className: PropTypes.string,
}

export default PageTitle
