import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../Button'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'
import {
  sizing,
  weights,
} from '../stories/fundamentals/text-styles/text-styles'

const CustomBtn = styled(Button)`
  padding: 10px 20px;
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  width: ${({ inline }) => (inline ? 'auto' : '100%')};
  background: none;
  color: ${colorsOptions.greys['grey-100']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 20px;
  margin-top: 30px;
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}px;` : '')}
  border: 1px solid ${colorsOptions.greys['grey-30']};
  ${weights['text-600']};
  &:hover {
    background: ${colorsOptions.greys['grey-20']}; //not confirmed
    color: ${colorsOptions.greys['grey-60']};
    border-color: ${colorsOptions.greys['grey-30']};
  }
`

CustomBtn.propTypes = {
  /** Uses auto width and inline-block */
  inline: PropTypes.bool,
  marginLeft: PropTypes.number,
}

/**
 * Button for input forms
 * @component
 */
export default CustomBtn
