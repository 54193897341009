const docuToolsOrange = {
  'dt-10': '#FCEEE8',
  'dt-20': '#FDBEA7',
  orange: '#FF6633',
  'dt-40': '#FF5118',
  'dt-50': '#F13D00',
  'dt-60': '#BA2D00',
  'dt-70': '#851F00'
}

const greys = {
  black: '#000000',
  'grey-100': '#141A21',
  'grey-90': '#2D323B',
  'grey-80': '#464954',
  'grey-70': '#5F616E',
  'grey-60': '#787887',
  'grey-50': '#A0A0AA',
  'grey-40': '#BEBEC7',
  'grey-30': '#DCDCE4',
  'grey-20': '#EDEDF1',
  'grey-10': '#F6F6FA',
  'grey-05': '#FBFBFD',
  white: '#FFFFFF'
}

const secondaryColors = {
  darkgreen: {
    pale: '#CBE6EA',
    normal: '#008556',
    strong: '#007749'
  },
  green: {
    pale: '#E9F7DD',
    normal: '#91D656',
    strong: '#8DC953'
  },
  turquoise: {
    pale: '#CCF6F4',
    normal: '#00D3C9',
    strong: '#00C8BD'
  },
  petrol: {
    pale: '#D7E8EB',
    normal: '#398D9B',
    strong: '#34848C'
  },
  darkblue: {
    pale: '#D4DEEF',
    normal: '#2859AF',
    strong: '#2758A0'
  },
  blue: {
    pale: '#DBE8FA',
    normal: '#4D8CE8',
    strong: '#5485D3'
  },
  violet: {
    pale: '#ECDEF7',
    normal: '#A25AD6',
    strong: '#9155C6'
  },
  purple: {
    pale: '#F4DFEE',
    normal: '#C95FAB',
    strong: '#B758A1'
  },
  pink: {
    pale: '#FFE0E8',
    normal: '#FF668E',
    strong: '#F2618D'
  },
  red: {
    pale: '#FBDADA',
    normal: '#EA4444',
    strong: '#D64949'
  },
  orange: {
    pale: '#FFEAD6',
    normal: '#FF9533',
    strong: '#F98A3E'
  },
  yellow: {
    pale: '#FFF4D8',
    normal: '#FFC93E',
    strong: '#F2BA3D'
  },
  ochre: {
    pale: '#F8F1E5',
    normal: '#DDBB7C',
    strong: '#CCA77A'
  },
  muesli: {
    pale: '#EBE6DE',
    normal: '#99815B',
    strong: '#8C7554'
  },
  darkbrown: {
    pale: '#DEDCD9',
    normal: '#5B5142',
    strong: '#4C4338'
  }
}

const semantics = {
  success: {
    pale: '#E9F7DD',
    normal: '#91D656',
    strong: '#8DC953'
  },
  info: {
    pale: '#DBE8FA',
    normal: '#4D8CE8',
    strong: '#5485D3'
  },
  danger: {
    'error-10': '#FBDADA',
    'error-20': '#F3B7AF',
    'error-30': '#EE7A6F',
    error: '#EA4444',
    'error-50': '#D64949',
    'error-60': '#C13435'
  },
  warning: {
    pale: '#FFEAD6',
    normal: '#FF9533',
    strong: '#F98A3E'
  },
  inactive: '#A0A0AA',
  cta: '#FF6633'
}

export const hexToRgb = (hex) => {
  let r = 0
  let g = 0
  let b = 0

  // 3 digits
  if (hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`
    g = `0x${hex[2]}${hex[2]}`
    b = `0x${hex[3]}${hex[3]}`

    // 6 digits
  } else if (hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`
    g = `0x${hex[3]}${hex[4]}`
    b = `0x${hex[5]}${hex[6]}`
  }

  return { r: +r, g: +g, b: +b }
}

export const rgbToHex = ({ r, g, b } = {}) => {
  const toHex = (value) => {
    const hex = value.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  if (!!r && !!g && !!b) {
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
  }

  return greys['grey-100']
}

export const applyOpacity = (color, opacity) => {
  const rgb = hexToRgb(color)
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`
}

// all normal colors
export const pinsColors = {
  DarkBrown: secondaryColors.darkbrown.normal,
  Muesli: secondaryColors.muesli.normal,
  Ochre: secondaryColors.ochre.normal,
  Yellow: secondaryColors.yellow.normal,
  Orange: secondaryColors.orange.normal,
  Red: secondaryColors.red.normal,
  Pink: secondaryColors.pink.normal,
  Purple: secondaryColors.purple.normal,
  Violet: secondaryColors.violet.normal,
  Blue: secondaryColors.blue.normal,
  DarkBlue: secondaryColors.darkblue.normal,
  Petrol: secondaryColors.petrol.normal,
  Turquoise: secondaryColors.turquoise.normal,
  Green: secondaryColors.green.normal,
  DarkGreen: secondaryColors.darkgreen.normal
}

export const pinsRGBColors = {
  DarkBrown: hexToRgb(secondaryColors.darkbrown.normal),
  Muesli: hexToRgb(secondaryColors.muesli.normal),
  Ochre: hexToRgb(secondaryColors.ochre.normal),
  Yellow: hexToRgb(secondaryColors.yellow.normal),
  Orange: hexToRgb(secondaryColors.orange.normal),
  Red: hexToRgb(secondaryColors.red.normal),
  Pink: hexToRgb(secondaryColors.pink.normal),
  Purple: hexToRgb(secondaryColors.purple.normal),
  Violet: hexToRgb(secondaryColors.violet.normal),
  Blue: hexToRgb(secondaryColors.blue.normal),
  DarkBlue: hexToRgb(secondaryColors.darkblue.normal),
  Petrol: hexToRgb(secondaryColors.petrol.normal),
  Turquoise: hexToRgb(secondaryColors.turquoise.normal),
  Green: hexToRgb(secondaryColors.green.normal),
  DarkGreen: hexToRgb(secondaryColors.darkgreen.normal)
}

// all strong colors
export const categoryColors = {
  Ochre: secondaryColors.ochre.strong,
  Yellow: secondaryColors.yellow.strong,
  Orange: secondaryColors.orange.strong,
  Red: secondaryColors.red.strong,
  Pink: secondaryColors.pink.strong,
  Purple: secondaryColors.purple.strong,
  Violet: secondaryColors.violet.strong,
  Blue: secondaryColors.blue.strong,
  DarkBlue: secondaryColors.darkblue.strong,
  Petrol: secondaryColors.petrol.strong,
  Turquoise: secondaryColors.turquoise.strong,
  Green: secondaryColors.green.strong,
  DarkGreen: secondaryColors.darkgreen.strong,
  Muesli: secondaryColors.muesli.strong,
  DarkBrown: secondaryColors.darkbrown.strong
}

export const categoryBgColors = {
  Ochre: secondaryColors.ochre.pale,
  Yellow: secondaryColors.yellow.pale,
  Orange: secondaryColors.orange.pale,
  Red: secondaryColors.red.pale,
  Pink: secondaryColors.pink.pale,
  Purple: secondaryColors.purple.pale,
  Violet: secondaryColors.violet.pale,
  Blue: secondaryColors.blue.pale,
  DarkBlue: secondaryColors.darkblue.pale,
  Petrol: secondaryColors.petrol.pale,
  Turquoise: secondaryColors.turquoise.pale,
  Green: secondaryColors.green.pale,
  DarkGreen: secondaryColors.darkgreen.pale,
  Muesli: secondaryColors.muesli.pale,
  DarkBrown: secondaryColors.darkbrown.pale
}

export const taskStatusColor = {
  Done: semantics.success.pale,
  Overdue: semantics.danger['error-10'],
  Rejected: semantics.danger['error-10'],
  Closed: semantics.info.pale,
  Open: greys['grey-05'],
  Inactive: `repeating-linear-gradient(135deg, ${greys['grey-10']} 2%, #fff 2%, #fff 6%, ${greys['grey-10']} 6%, ${greys['grey-10']} 15%, #fff 15%, #fff 15%, ${greys['grey-10']} 15%)` // not-found
}

export { docuToolsOrange, greys, secondaryColors, semantics }

const colorsOptions = { docuToolsOrange, greys, secondaryColors, semantics }

export default colorsOptions
