import { useCallback, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { func, object, string, array } from 'prop-types'
import { injectIntl } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import {
  AddFieldbtn,
  DropDownMenu,
  ItemLabel,
  ListItem,
} from '../../styledComponents'
import { AddIcon } from '../../../PinDetails/images'
import { createPinField as createPinFieldAction } from '../../actions'
import { makeSelectProjectPermissions } from '../../selectors'
import { fieldIcon, newFieldDetails } from './constants'
import OutsideClickWrapper from '../../../../utils/OutsideClickWrapper'
import colorsOptions from '../../../../components/stories/fundamentals/colors-styles/colors'

const ALL_FIELDS = [
  {
    label: 'number',
    icon: fieldIcon.NUMBER,
  },
  {
    label: 'text',
    icon: fieldIcon.TEXT,
  },
  {
    label: 'date',
    icon: fieldIcon.DATE,
  },
  {
    label: 'time',
    icon: fieldIcon.TIME,
  },
  {
    label: 'slider',
    icon: fieldIcon.SLIDER,
  },
  {
    label: 'tags',
    icon: fieldIcon.TAGS,
  },
]

function AddNewFieldButton(props) {
  const {
    text,
    createPinField,
    projectId,
    projectPermissions,
    intl: { formatMessage },
  } = props

  const [isOpen, setIsOpen] = useState(false)

  const authorized = useMemo(
    () => projectPermissions.includes('EditSettings'),
    [projectPermissions],
  )

  const openDropDown = useCallback((e) => {
    e.stopPropagation()
    if (!isOpen) {
      setIsOpen(true)
    }
  }, [])

  const createField = (fieldType) => {
    const type = fieldType.toUpperCase()
    const updateField = { ...newFieldDetails(formatMessage)[type] }

    createPinField(projectId, updateField)
    setIsOpen(false)
  }

  const fieldDropDown = useMemo(
    () =>
      ALL_FIELDS.map((field, index) => (
        <ListItem
          disabled={field.label === 'tags'}
          key={`${field.id}-${index}`}
          onClick={() => createField(field.label)}
        >
          <field.icon
            color={
              field.label === 'tags'
                ? colorsOptions.greys['grey-50']
                : colorsOptions.greys['grey-100']
            }
          />
          <ItemLabel>{formatMessage({ id: field.label })}</ItemLabel>
        </ListItem>
      )),
    [],
  )

  const closePopOver = useCallback(() => setIsOpen(false), [])

  return (
    <>
      {authorized && (
        <>
          <AddFieldbtn onClick={openDropDown} isOpen={isOpen}>
            <AddIcon
              color={
                isOpen
                  ? colorsOptions.docuToolsOrange.orange
                  : colorsOptions.greys.white
              }
              size={32}
            />
            <span>{text}</span>
          </AddFieldbtn>
          <OutsideClickWrapper onCloseIntent={closePopOver}>
            <DropDownMenu
              className={isOpen ? 'active' : 'inactive'}
              isOpen={isOpen}
            >
              <ul>{fieldDropDown}</ul>
            </DropDownMenu>
          </OutsideClickWrapper>
        </>
      )}
    </>
  )
}

AddNewFieldButton.propTypes = {
  text: string,
  projectId: string,
  intl: object.isRequired,
  createPinField: func,
  projectPermissions: array,
}

const mapStateToProps = createStructuredSelector({
  projectPermissions: makeSelectProjectPermissions(),
})

function mapDispatchToProps(dispatch) {
  return {
    createPinField: (...attributes) =>
      dispatch(createPinFieldAction(...attributes)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddNewFieldButton))
