import PropTypes from 'prop-types'
import { iconSizes } from '../icons-sizes'
import { AnimateSpin, SvgIconComponent } from '../styledComponents'

export function SeparatorLine({ size, ...props }) {
  return (
    <SvgIconComponent
      size={size}
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#141A21"
      {...props}
    >
      <path d="M12.4375 2.20068V22.2007" strokeLinecap="round" />
    </SvgIconComponent>
  )
}

export function SeparatorDot({ size, ...props }) {
  return (
    <SvgIconComponent
      size={size}
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.3125 14.2007C13.4171 14.2007 14.3125 13.3053 14.3125 12.2007C14.3125 11.0961 13.4171 10.2007 12.3125 10.2007C11.2079 10.2007 10.3125 11.0961 10.3125 12.2007C10.3125 13.3053 11.2079 14.2007 12.3125 14.2007Z"
        fill="#141A21"
      />
    </SvgIconComponent>
  )
}

export function SeparatorSquare({ size, ...props }) {
  return (
    <SvgIconComponent
      size={size}
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.6875 10.2007H10.6875C10.4114 10.2007 10.1875 10.4245 10.1875 10.7007V13.7007C10.1875 13.9768 10.4114 14.2007 10.6875 14.2007H13.6875C13.9636 14.2007 14.1875 13.9768 14.1875 13.7007V10.7007C14.1875 10.4245 13.9636 10.2007 13.6875 10.2007Z"
        fill="#141A21"
      />
    </SvgIconComponent>
  )
}

export function SpinnerOrange({ size, ...props }) {
  return (
    <AnimateSpin
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      size={size}
    >
      <path
        d="M34.639 3.60895C37.5795 4.7434 40.2677 6.44592 42.5502 8.61929C44.8327 10.7927 46.6648 13.3943 47.9419 16.2757C49.2189 19.1571 49.9159 22.2619 49.9931 25.4126C50.0703 28.5634 49.5261 31.6985 48.3916 34.639C47.2572 37.5795 45.5547 40.2677 43.3813 42.5502C41.2079 44.8327 38.6063 46.6648 35.7249 47.9419C32.8435 49.2189 29.7387 49.9159 26.5879 49.9931C23.4372 50.0703 20.302 49.5261 17.3616 48.3916C14.4211 47.2572 11.7329 45.5547 9.45035 43.3813C7.16784 41.2079 5.33576 38.6063 4.05871 35.7249C2.78166 32.8435 2.08465 29.7387 2.00748 26.588C1.93031 23.4372 2.47448 20.3021 3.60894 17.3616C4.74339 14.4211 6.4459 11.7329 8.61927 9.45036C10.7926 7.16786 13.3943 5.33578 16.2757 4.05873C19.1571 2.78168 22.2618 2.08467 25.4126 2.0075C28.5634 1.93032 31.6985 2.4745 34.639 3.60895L34.639 3.60895Z"
        stroke="#FDBEA7"
        strokeWidth={4}
      />
      <path
        d="M34.639 3.60895C38.1726 4.97223 41.3334 7.15245 43.8629 9.97136C46.3924 12.7903 48.2189 16.1678 49.193 19.8279C50.1671 23.4879 50.2611 27.3265 49.4674 31.0299C48.6736 34.7332 47.0147 38.1962 44.6262 41.1355"
        stroke="#FF6633"
        strokeWidth={4}
      />
    </AnimateSpin>
  )
}

export function SpinnerGrey({ size, ...props }) {
  return (
    <AnimateSpin
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      size={size}
    >
      <path
        d="M34.639 3.60895C37.5795 4.7434 40.2677 6.44592 42.5502 8.61929C44.8327 10.7927 46.6648 13.3943 47.9419 16.2757C49.2189 19.1571 49.9159 22.2619 49.9931 25.4126C50.0703 28.5634 49.5261 31.6985 48.3916 34.639C47.2572 37.5795 45.5547 40.2677 43.3813 42.5502C41.2079 44.8327 38.6063 46.6648 35.7249 47.9419C32.8435 49.2189 29.7387 49.9159 26.5879 49.9931C23.4372 50.0703 20.302 49.5261 17.3616 48.3916C14.4211 47.2572 11.7329 45.5547 9.45035 43.3813C7.16784 41.2079 5.33576 38.6063 4.05871 35.7249C2.78166 32.8435 2.08465 29.7387 2.00748 26.588C1.93031 23.4372 2.47448 20.3021 3.60894 17.3616C4.74339 14.4211 6.4459 11.7329 8.61927 9.45036C10.7926 7.16786 13.3943 5.33578 16.2757 4.05873C19.1571 2.78168 22.2618 2.08467 25.4126 2.0075C28.5634 1.93032 31.6985 2.4745 34.639 3.60895L34.639 3.60895Z"
        stroke="#DCDCE4"
        strokeWidth={4}
      />
      <path
        d="M34.639 3.60895C38.1726 4.97223 41.3334 7.15245 43.8629 9.97136C46.3924 12.7903 48.2189 16.1678 49.193 19.8279C50.1671 23.4879 50.2611 27.3265 49.4674 31.0299C48.6736 34.7332 47.0147 38.1962 44.6262 41.1355"
        stroke="#787887"
        strokeWidth={4}
      />
    </AnimateSpin>
  )
}

export function SpinnerWhite({ size, ...props }) {
  return (
    <AnimateSpin
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      size={size}
    >
      <g
        style={{
          mixBlendMode: 'hard-light',
        }}
      >
        <path
          d="M34.639 3.60895C37.5795 4.7434 40.2677 6.44592 42.5502 8.61929C44.8327 10.7927 46.6648 13.3943 47.9418 16.2757C49.2189 19.1571 49.9159 22.2619 49.9931 25.4126C50.0702 28.5634 49.5261 31.6985 48.3916 34.639C47.2572 37.5795 45.5547 40.2677 43.3813 42.5502C41.2079 44.8327 38.6063 46.6648 35.7248 47.9419C32.8434 49.2189 29.7387 49.9159 26.5879 49.9931C23.4371 50.0703 20.302 49.5261 17.3615 48.3916C14.4211 47.2572 11.7328 45.5547 9.45033 43.3813C7.16783 41.2079 5.33575 38.6063 4.0587 35.7249C2.78165 32.8435 2.08464 29.7387 2.00746 26.588C1.93029 23.4372 2.47447 20.3021 3.60892 17.3616C4.74337 14.4211 6.44589 11.7329 8.61926 9.45036C10.7926 7.16786 13.3943 5.33578 16.2757 4.05873C19.1571 2.78168 22.2618 2.08467 25.4126 2.0075C28.5634 1.93032 31.6985 2.4745 34.639 3.60895L34.639 3.60895Z"
          stroke="#BEBEC7"
          strokeWidth={4}
        />
        <path
          d="M34.639 3.60895C38.1726 4.97223 41.3334 7.15245 43.8629 9.97136C46.3924 12.7903 48.2189 16.1678 49.193 19.8279C50.167 23.4879 50.2611 27.3265 49.4673 31.0299C48.6736 34.7332 47.0147 38.1962 44.6262 41.1355"
          stroke="white"
          strokeWidth={4}
        />
      </g>
    </AnimateSpin>
  )
}

export function SpinnerIos({ size, ...props }) {
  return (
    <AnimateSpin
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      size={size}
    >
      <path
        opacity={0.5}
        d="M12 21.99C12.5304 21.99 13.0391 22.2007 13.4142 22.5758C13.7893 22.9508 14 23.4596 14 23.99C14 24.5204 13.7893 25.0291 13.4142 25.4042C13.0391 25.7793 12.5304 25.99 12 25.99L6 25.99C5.46957 25.99 4.96086 25.7793 4.58579 25.4042C4.21071 25.0291 4 24.5204 4 23.99C4 23.4596 4.21071 22.9508 4.58579 22.5758C4.96086 22.2007 5.46957 21.99 6 21.99L12 21.99Z"
        fill="#141A21"
      />
      <path
        d="M41.99 21.99C42.5204 21.99 43.0291 22.2007 43.4042 22.5758C43.7793 22.9508 43.99 23.4596 43.99 23.99C43.99 24.5204 43.7793 25.0291 43.4042 25.4042C43.0291 25.7793 42.5204 25.99 41.99 25.99L35.99 25.99C35.4596 25.99 34.9509 25.7793 34.5758 25.4042C34.2007 25.0291 33.99 24.5204 33.99 23.99C33.99 23.4596 34.2007 22.9508 34.5758 22.5758C34.9509 22.2007 35.4596 21.99 35.99 21.99L41.99 21.99Z"
        fill="#141A21"
      />
      <path
        opacity={0.25}
        d="M22 35.99C22 35.4596 22.2107 34.9508 22.5858 34.5758C22.9609 34.2007 23.4696 33.99 24 33.99C24.5304 33.99 25.0391 34.2007 25.4142 34.5758C25.7893 34.9508 26 35.4596 26 35.99L26 41.99C26 42.5204 25.7893 43.0291 25.4142 43.4042C25.0391 43.7793 24.5304 43.99 24 43.99C23.4696 43.99 22.9609 43.7793 22.5858 43.4042C22.2107 43.0291 22 42.5204 22 41.99L22 35.99Z"
        fill="#141A21"
      />
      <path
        opacity={0.65}
        d="M22 6C22 5.46957 22.2107 4.96086 22.5858 4.58579C22.9609 4.21071 23.4696 4 24 4C24.5304 4 25.0391 4.21071 25.4142 4.58579C25.7893 4.96086 26 5.46957 26 6L26 12C26 12.5304 25.7893 13.0391 25.4142 13.4142C25.0391 13.7893 24.5304 14 24 14C23.4696 14 22.9609 13.7893 22.5858 13.4142C22.2107 13.0391 22 12.5304 22 12L22 6Z"
        fill="#141A21"
      />
      <path
        opacity={0.2}
        d="M28.2651 35.3697C28.1337 35.1422 28.0485 34.8911 28.0142 34.6306C27.9799 34.3701 27.9973 34.1055 28.0653 33.8517C28.1333 33.598 28.2506 33.3601 28.4106 33.1517C28.5706 32.9433 28.77 32.7685 28.9976 32.6372C29.4568 32.3717 30.0026 32.2994 30.515 32.4363C31.0275 32.5732 31.4645 32.9081 31.7301 33.3672L34.7476 38.5747C34.8783 38.8033 34.9625 39.0554 34.9955 39.3165C35.0285 39.5777 35.0097 39.8428 34.94 40.0967C34.8703 40.3506 34.7512 40.5882 34.5895 40.7959C34.4278 41.0036 34.2266 41.1774 33.9976 41.3072C33.5388 41.5702 32.9946 41.6407 32.4839 41.5035C31.9732 41.3662 31.5377 41.0323 31.2726 40.5747L28.2651 35.3697Z"
        fill="#141A21"
      />
      <path
        opacity={0.6}
        d="M13.2626 9.40285C12.998 8.94361 12.9266 8.39816 13.0639 7.88628C13.2013 7.37441 13.5362 6.93798 13.9951 6.67285C14.454 6.40811 14.9992 6.33629 15.5111 6.47316C16.0229 6.61002 16.4595 6.94438 16.7251 7.40285L19.7451 12.6078C19.8753 12.8363 19.9593 13.0882 19.9921 13.3491C20.0249 13.61 20.0059 13.8748 19.9362 14.1284C19.8666 14.382 19.7476 14.6193 19.5861 14.8268C19.4246 15.0344 19.2237 15.208 18.9951 15.3378C18.5366 15.601 17.9926 15.6719 17.4819 15.5351C16.9713 15.3984 16.5356 15.065 16.2701 14.6078L13.2626 9.40285Z"
        fill="#141A21"
      />
      <path
        opacity={0.8}
        d="M35.3829 19.7239C35.1554 19.8553 34.9042 19.9406 34.6437 19.9748C34.3833 20.0091 34.1186 19.9918 33.8649 19.9237C33.6111 19.8557 33.3732 19.7384 33.1649 19.5784C32.9565 19.4184 32.7816 19.219 32.6504 18.9914C32.519 18.7639 32.4337 18.5128 32.3994 18.2523C32.3651 17.9918 32.3825 17.7272 32.4505 17.4734C32.5186 17.2197 32.6359 16.9818 32.7959 16.7734C32.9558 16.565 33.1553 16.3902 33.3829 16.2589L38.5879 13.2414C38.8163 13.1111 39.0682 13.0272 39.3291 12.9944C39.59 12.9616 39.8548 12.9806 40.1084 13.0502C40.362 13.1199 40.5993 13.2389 40.8068 13.4004C41.0144 13.5619 41.188 13.7627 41.3179 13.9914C41.581 14.4499 41.6519 14.9939 41.5152 15.5045C41.3784 16.0152 41.045 16.4509 40.5879 16.7164L35.3829 19.7239Z"
        fill="#141A21"
      />
      <path
        opacity={0.45}
        d="M9.41449 34.7278C9.18699 34.8592 8.93584 34.9445 8.67537 34.9787C8.41491 35.013 8.15025 34.9957 7.8965 34.9276C7.64275 34.8596 7.40488 34.7423 7.1965 34.5823C6.98811 34.4223 6.81328 34.2229 6.68199 33.9953C6.41746 33.5361 6.34603 32.9906 6.48337 32.4788C6.62071 31.9669 6.9556 31.5305 7.41449 31.2653L12.6195 28.2453C12.8479 28.1151 13.0998 28.0311 13.3607 27.9983C13.6216 27.9655 13.8865 27.9845 14.14 28.0541C14.3936 28.1238 14.631 28.2428 14.8385 28.4043C15.046 28.5658 15.2197 28.7666 15.3495 28.9953C15.6126 29.4538 15.6836 29.9978 15.5468 30.5085C15.41 31.0191 15.0766 31.4548 14.6195 31.7203L9.41449 34.7278Z"
        fill="#141A21"
      />
      <path
        opacity={0.15}
        d="M33.3896 31.7049C32.9307 31.4398 32.5958 31.0033 32.4585 30.4915C32.3211 29.9796 32.3926 29.4341 32.6571 28.9749C32.7884 28.7474 32.9632 28.5479 33.1716 28.3879C33.38 28.228 33.6178 28.1106 33.8716 28.0426C34.1253 27.9746 34.39 27.9572 34.6505 27.9915C34.9109 28.0258 35.1621 28.111 35.3896 28.2424L40.5996 31.2424C41.0587 31.508 41.3936 31.945 41.5305 32.4575C41.6674 32.9699 41.5951 33.5157 41.3296 33.9749C41.0645 34.4338 40.628 34.7687 40.1162 34.906C39.6043 35.0434 39.0588 34.9719 38.5996 34.7074L33.3896 31.7049Z"
        fill="#141A21"
      />
      <path
        opacity={0.55}
        d="M7.40288 16.7387C7.17532 16.6074 6.97586 16.4325 6.81589 16.2242C6.65592 16.0158 6.53857 15.7779 6.47055 15.5242C6.40253 15.2704 6.38516 15.0057 6.41945 14.7453C6.45374 14.4848 6.539 14.2337 6.67038 14.0062C6.80166 13.7786 6.97649 13.5791 7.18488 13.4192C7.39326 13.2592 7.63113 13.1419 7.88488 13.0738C8.13863 13.0058 8.40329 12.9884 8.66376 13.0227C8.92422 13.057 9.17537 13.1423 9.40288 13.2737L14.6129 16.2737C15.072 16.5392 15.4069 16.9763 15.5438 17.4887C15.6807 18.0012 15.6084 18.547 15.3429 19.0062C15.0777 19.465 14.6413 19.7999 14.1294 19.9373C13.6176 20.0746 13.0721 20.0032 12.6129 19.7387L7.40288 16.7387Z"
        fill="#141A21"
      />
      <path
        opacity={0.7}
        d="M31.7203 14.5988C31.5891 14.8264 31.4142 15.0258 31.2058 15.1858C30.9974 15.3458 30.7596 15.4631 30.5058 15.5312C30.2521 15.5992 29.9874 15.6165 29.727 15.5823C29.4665 15.548 29.2153 15.4627 28.9878 15.3313C28.7603 15.2 28.5608 15.0252 28.4009 14.8168C28.2409 14.6084 28.1235 14.3706 28.0555 14.1168C27.9875 13.8631 27.9701 13.5984 28.0044 13.338C28.0387 13.0775 28.124 12.8263 28.2553 12.5988L31.2553 7.39133C31.3866 7.16378 31.5615 6.96432 31.7698 6.80435C31.9782 6.64438 32.2161 6.52703 32.4698 6.45901C32.7236 6.39099 32.9883 6.37362 33.2487 6.40791C33.5092 6.44219 33.7603 6.52746 33.9878 6.65883C34.4471 6.92355 34.7826 7.3598 34.9204 7.8717C35.0582 8.38361 34.9872 8.92929 34.7228 9.38883L31.7203 14.5988Z"
        fill="#141A21"
      />
      <path
        opacity={0.35}
        d="M16.7505 40.587C16.6192 40.8145 16.4444 41.014 16.236 41.174C16.0276 41.3339 15.7898 41.4513 15.536 41.5193C15.2823 41.5873 15.0176 41.6047 14.7572 41.5704C14.4967 41.5361 14.2455 41.4509 14.018 41.3195C13.7905 41.1882 13.591 41.0134 13.4311 40.805C13.2711 40.5966 13.1537 40.3587 13.0857 40.105C13.0177 39.8512 13.0003 39.5866 13.0346 39.3261C13.0689 39.0657 13.1542 38.8145 13.2855 38.587L16.2855 33.377C16.5511 32.9179 16.9882 32.583 17.5006 32.4461C18.013 32.3092 18.5589 32.3815 19.018 32.647C19.4773 32.9117 19.8128 33.348 19.9506 33.8599C20.0884 34.3718 20.0174 34.9175 19.753 35.377L16.7505 40.587Z"
        fill="#141A21"
      />
    </AnimateSpin>
  )
}

const iconPropTypes = {
  size: PropTypes.oneOf(Object.keys(iconSizes)),
}

SeparatorLine.propTypes = iconPropTypes
SeparatorDot.propTypes = iconPropTypes
SeparatorSquare.propTypes = iconPropTypes
SpinnerOrange.propTypes = iconPropTypes
SpinnerGrey.propTypes = iconPropTypes
SpinnerWhite.propTypes = iconPropTypes
SpinnerIos.propTypes = iconPropTypes

const iconsSpecial = {
  SeparatorLine,
  SeparatorDot,
  SeparatorSquare,
  SpinnerOrange,
  SpinnerGrey,
  SpinnerWhite,
  SpinnerIos,
}

export default iconsSpecial
