import production from './production'
import staging from './staging'
import development from './development'

const { VITE_TARGET_ENV, VITE_DEVSERVER_TARGET } = import.meta.env
const configs = { production, staging, development }

const common = {
  notification: {
    path: '/notifications-api',
    version: '/v1'
  },
  plansApi: {
    path: '/plans-api',
    version: '/v3.1',
    version3: '/v3',
    version4: '/v4'
  },
  projectsApi: {
    path: '/projects-api',
    version: '/v3',
    version4: '/v4'
  },
  tasksApi: {
    path: '/tasks-api',
    version: '/v3'
  },
  mfaApi: {
    path: '/mfa-api',
    version: '/v1'
  },
  membershipsApi: {
    path: '/memberships-api',
    version: '/v4'
  },
  teamGroupsApi: {
    path: '/team-groups-api',
    version: '/v4'
  },
  pinsApi: {
    path: '/pins-api',
    version: '/v3'
  },
  assigneesApi: {
    path: '/assignee-api',
    version: '/v4'
  },
  filterApi: {
    path: '/filter-api',
    version: '/v3'
  },
  templateV3: {
    path: '/templates/projects',
    version: '/api/v3'
  },
  ouathApi: {
    path: '/oauth/token'
  },
  apiv2: '/api/v2',
  fcmSubscription: {
    prefix: 'https://iid.googleapis.com/iid/v1',
    suffix: '/rel/topics'
  },
  mapboxAccessToken:
    'pk.eyJ1IjoiZGRzZy1hZG1pbiIsImEiOiJja3A3YXI2Y2ExY29lMnFyMnBnOGIyOGJhIn0.GlBMrjDr72e0wQQbYgFplQ',
  e2eTestUrl: 'https://dev.docu-tools.com'
}

const config = {
  ...common,
  ...configs[VITE_DEVSERVER_TARGET || VITE_TARGET_ENV]
}
export default config
