import { put, takeLatest, call, select } from 'redux-saga/effects'

import * as actions from './actions'
import * as actionTypes from './constants'
import { makeSelectEmail } from './selectors'
import { callAPI } from '../../utils/callAPI'

export function* resetPassword() {
  try {
    const email = yield select(makeSelectEmail())

    yield call(callAPI, {
      method: 'post',
      url: '/api/me/passwordReset',
      params: {
        email,
      },
    })

    yield put(actions.resetPasswordDone())
  } catch (err) {
    if (err.response && err.response.status === 400) {
      yield put(actions.emailNotValid())
    } else {
      window.Rollbarerror(
        'app/ForgotPassword/sagas.js resetPassword error',
        err,
      )
      yield put(actions.resetPasswordError(err))
    }
  }
}

export function* resetPasswordWatcher() {
  yield takeLatest(actionTypes.RESET_PASSWORD, resetPassword)
}

export default [resetPasswordWatcher]
