import { sizing } from '../../fundamentals/text-styles/text-styles'
import { iconSizes } from '../icons/icons-sizes'
import {
  PrimaryButton,
  PrimaryDestructiveButton,
  LinkButton,
  SecondaryHollowButton,
  SecondaryWhiteFillButton,
  SecondaryInvertedButton,
  SecondaryDestructiveHollowButton,
  SecondaryDestructiveWhiteFillButton,
  SecondaryDestructiveInvertedButton,
  TertiaryHollowButton,
  TertiaryWhiteFillButton,
  GhostButton,
  GhostInvertedButton,
  GhostDestructiveButton,
  GhostDestructiveInvertedButton
} from './styledComponents'

const buttonSizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
}

const linkSizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge'
}

const buttonType = {
  STANDARD: 'standard',
  'ICON-ONLY': 'icon-only'
}

const buttonVariants = {
  PRIMARY: 'primary',
  'PRIMARY-DESTRUCTIVE': 'primary-destructive',

  'SECONDARY-HOLLOW': 'secondary-hollow',
  'SECONDARY-WHITE-FILL': 'secondary-white-fill',
  'SECONDARY-INVERTED': 'secondary-inverted',
  'SECONDARY-DESTRUCTIVE-HOLLOW': 'secondary-destructive-hollow',
  'SECONDARY-DESTRUCTIVE-WHITE-FILL': 'secondary-destructive-white-fill',
  'SECONDARY-DESTRUCTIVE-INVERTED': 'secondary-destructive-inverted',

  'TERTIARY-HOLLOW': 'tertiary-hollow',
  'TERTIARY-WHITE-FILL': 'tertiary-white-fill',

  GHOST: 'ghost',
  'GHOST-INVERTED': 'ghost-inverted',
  'GHOST-DESTRUCTIVE': 'ghost-destructive',
  'GHOST-DESTRUCTIVE-INVERTED': 'ghost-destructive-inverted',

  LINK: 'link'
}

const variantComponents = {
  [buttonVariants.PRIMARY]: PrimaryButton,
  [buttonVariants['PRIMARY-DESTRUCTIVE']]: PrimaryDestructiveButton,

  [buttonVariants['SECONDARY-HOLLOW']]: SecondaryHollowButton,
  [buttonVariants['SECONDARY-WHITE-FILL']]: SecondaryWhiteFillButton,
  [buttonVariants['SECONDARY-INVERTED']]: SecondaryInvertedButton,
  [buttonVariants['SECONDARY-DESTRUCTIVE-HOLLOW']]: SecondaryDestructiveHollowButton,
  [buttonVariants['SECONDARY-DESTRUCTIVE-WHITE-FILL']]: SecondaryDestructiveWhiteFillButton,
  [buttonVariants['SECONDARY-DESTRUCTIVE-INVERTED']]: SecondaryDestructiveInvertedButton,

  [buttonVariants['TERTIARY-HOLLOW']]: TertiaryHollowButton,
  [buttonVariants['TERTIARY-WHITE-FILL']]: TertiaryWhiteFillButton,

  [buttonVariants.GHOST]: GhostButton,
  [buttonVariants['GHOST-INVERTED']]: GhostInvertedButton,
  [buttonVariants['GHOST-DESTRUCTIVE']]: GhostDestructiveButton,
  [buttonVariants['GHOST-DESTRUCTIVE-INVERTED']]: GhostDestructiveInvertedButton,

  [buttonVariants.LINK]: LinkButton
}

const sizeOptions = {
  [buttonSizes.SMALL]: {
    iconSize: iconSizes['icon-s'],
    height: '32px',
    ...sizing['text-r'],
    borderWidth: '1px',
    padding: '0 1rem'
  },
  [buttonSizes.MEDIUM]: {
    iconSize: iconSizes['icon-m'],
    height: '40px',
    ...sizing['text-l'],
    borderWidth: '1.25px',
    padding: '0 2rem'
  },
  [buttonSizes.LARGE]: {
    iconSize: iconSizes['icon-l'],
    height: '48px',
    ...sizing['text-xl'],
    borderWidth: '1.5px',
    padding: '0 2.5rem'
  }
}

const linkSizeOptions = {
  [linkSizes.SMALL]: {
    ...sizing['text-s']
  },
  [linkSizes.MEDIUM]: {
    ...sizing['text-r']
  },
  [linkSizes.LARGE]: {
    ...sizing['text-l']
  },
  [linkSizes.XLARGE]: {
    ...sizing['text-xl']
  }
}

export {
  variantComponents,
  sizeOptions,
  buttonSizes,
  linkSizes,
  buttonType,
  buttonVariants,
  linkSizeOptions
}
