import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { detect } from 'detect-browser'
import { FormattedMessage, injectIntl } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import lodash from 'lodash'
import isEmail from 'validator/lib/isEmail'
import * as actions from './actions'
import * as selectors from './selectors'
import { makeSelectIsMobile } from '../App/selectors'
import { Loader, NoBgLoaderWrapper } from '../../components/PageLoader'
import Error from '../../components/Error'
import {
  CustomInputs,
  Errormsg,
  Errormsgblock,
  FloatingLabel,
  FormContainer,
  Labelmsg,
  LogoImg,
  OtherLinks,
  StyledLink,
  StyledLinkA
} from '../../components/FormFields'

import {
  AppAvailableDiv,
  Cross,
  CustomBtn,
  DownloadAppLink,
  FullContainer,
  InfoMsgTwoFa,
  InsideContainer,
  InsideFormContainer,
  Logo,
  LogoSection,
  OtherContainer,
  SingleSignOnWrapper,
  TitleText
} from './styledComponents'
import icClearLine from './images/ic_Clear_line.svg'
import appIconIos from './images/app_icon_ios.png'
import logo from '@images/logo.svg'
import { makeZendeskRedirectLink } from '../../utils/getZendeskLinks'
import { withLocation, withRouteMatch } from '../../utils/hocs'
import ButtonComponent from '../../components/stories/atoms/button'
import { Link } from 'react-router-dom'
import {
  buttonSizes,
  buttonVariants
} from '../../components/stories/atoms/button/buttons'
import { Shovel } from '../../components/stories/atoms/icons/versions'
import colorsOptions from '../../components/stories/fundamentals/colors-styles/colors'

export class Login extends React.Component {
  constructor(props) {
    super(props)

    const {
      match: {
        params: { mode, token }
      },
      location: { search },
      intl: { formatMessage }
    } = props

    if (mode === 'verifyEmail' && token) {
      props.verifyEmail(token)
    }

    if (props.userType === 'SAML') {
      props.setEmail('')
    }

    const query = new URLSearchParams(search)
    const returnTo = query.get('return_to')

    this.state = {
      props: {
        redirect_token: props.redirect_token,
        loginError: props.loginError,
        show2FA: props.show2FA
      },
      returnTo,
      versionString: formatMessage({ id: 'helpMenu_version' })
    }

    // ref to count clicks to open token login popup
    this.clickCountRef = React.createRef()
    this.clickCountRef.current = 0

    this.debouncedReset = lodash.debounce(this.resetClickCount, 500)

    this.handleClick = this.handleShowTokenLogin.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {
      loginError: nextProps.loginError,
      show2FA: nextProps.show2FA
    }

    if (!prevState.props.redirect_token && nextProps.redirect_token) {
      window.location = makeZendeskRedirectLink(
        nextProps.redirect_token.access_token,
        prevState.returnTo
      )
    }

    if (
      prevState.loggingIn &&
      ((!prevState.props.loginError && nextProps.loginError) ||
        (!prevState.props.show2FA && nextProps.show2FA))
    ) {
      state.loggingIn = false
    }

    return state
  }

  componentWillUnmount() {
    clearTimeout(this.animationTimeout)
  }

  handleSetEmail = ({ target: { value } }) => {
    const { setEmail } = this.props
    setEmail(value)
    if (isEmail(value)) {
      this.setState({
        emailMissing: false
      })
    }
  }

  setPassword = ({ target: { value } }) => {
    const { setPassword } = this.props
    setPassword(value)
    if (value.length > 7) {
      this.setState({
        passwordMissing: false
      })
    }
  }

  setTwoFaCode = (e) => {
    const value = e.target.value.replace(/\D/g, '')
    const { setTwoFaCode } = this.props

    setTwoFaCode(`${value}`)
    if (value) {
      this.setState({
        twoFaCodeMissing: false
      })
    }
  }

  loginKeypress = (e) => {
    if (e.key === 'Enter') {
      this.login()
    }
  }

  login = () => {
    const { show2FA } = this.props

    let loginInitState
    if (!show2FA) {
      loginInitState = {
        emailMissing: false,
        passwordMissing: false
      }
    } else {
      loginInitState = {
        twoFaCodeMissing: false
      }
    }

    this.setState(loginInitState, () => {
      this.setState(
        (
          { returnTo },
          { loginWithRedirect, login, twoFaLogin, email, password, twoFaCode }
        ) => {
          const state = {}

          if (!show2FA) {
            if (email && isEmail(email) && password) {
              if (returnTo) {
                loginWithRedirect(email, password)
              } else {
                login(email, password)
              }
              state.loggingIn = true
            } else {
              if (!email || !isEmail(email)) {
                state.emailMissing = true
              }
              if (!password) {
                state.passwordMissing = true
              }
            }
          } else {
            state.twoFaCodeMissing = false
            if (twoFaCode) {
              twoFaLogin(email, password, twoFaCode, show2FA)
              state.loggingIn = true
            } else {
              state.twoFaCodeMissing = true
            }
          }
          return state
        }
      )
    })
  }

  setAnimationTimeout = () => {
    this.animationTimeout = setTimeout(() => {
      this.setState(({ versionString }) => {
        let newVersionString = versionString
        let indexOfUnderscore = versionString.indexOf('_')

        if (indexOfUnderscore !== 0) {
          if (indexOfUnderscore === -1) {
            indexOfUnderscore = versionString.length
          }

          newVersionString = lodash.padEnd(
            versionString.substring(0, indexOfUnderscore - 2),
            versionString.length,
            '_'
          )
          this.setAnimationTimeout()
        }
        return {
          versionString: newVersionString
        }
      })
    }, 300)
  }

  startAnimation = () => {
    this.setState(({ animationReady, runVersionAnimation }) => {
      if (runVersionAnimation) {
        this.stopAnimation()
        return {
          animationReady: false
        }
      }
      if (animationReady && this.versionAnimationSound) {
        this.versionAnimationSound.play()
        this.setAnimationTimeout()
        return {
          animationReady: false,
          runVersionAnimation: true
        }
      }

      return {}
    })
  }

  setAnimationReady = () => {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      animationReady: true
    })
  }

  stopAnimation = () => {
    const {
      intl: { formatMessage }
    } = this.props

    if (this.versionAnimationSound) this.versionAnimationSound.stop()
    clearTimeout(this.animationTimeout)
    this.setState({
      runVersionAnimation: false,
      versionString: formatMessage({ id: 'helpMenu_version' })
    })
  }

  getErrorMessageId = (loginError) => {
    if (
      loginError &&
      loginError.response &&
      loginError.response.status === 400
    ) {
      return `registerForm_${loginError.response.data.error_description?.replace(
        / /g,
        '_'
      )}`
    }
    return undefined
  }

  loginViaAccessToken = () => {
    const token = prompt('Please enter your access token', '') // eslint-disable-line

    if (token) {
      const today = new Date()
      today.setHours(today.getHours() + 6)
      localStorage.setItem(
        'dtToken',
        JSON.stringify({
          access_token: token.trim(),
          token_type: 'Bearer',
          expires_at: today.getTime()
        })
      )

      location.reload() // eslint-disable-line
    }
  }

  // functions to handle login via token.
  handleShowTokenLogin = () => {
    this.clickCountRef.current += 1

    if (this.clickCountRef.current === 5) {
      this.loginViaAccessToken()
      this.clickCountRef.current = 0
      this.debouncedReset.cancel()
    }

    this.debouncedReset()
  }

  resetClickCount = () => {
    this.clickCountRef.current = 0
  }

  render() {
    const {
      email,
      password,
      loginError,
      intl: { formatMessage },
      show2FA,
      twoFaCode,
      emailVerifying,
      emailVerified,
      verifyEmailWrong,
      match: { params },
      isMobile,
      isInPopup,
      hideLogo,
      backToLogin
    } = this.props

    const {
      loggingIn,
      hideAppInfo,
      emailMissing,
      passwordMissing,
      twoFaCodeMissing,
      versionString
    } = this.state

    const errorMessageId = this.getErrorMessageId(loginError)

    const browser = detect()
    const browserNotSupported = !(
      (browser.name === 'chrome' &&
        Number(browser.version.split('.')[0]) >= 60) ||
      (browser.name === 'firefox' &&
        Number(browser.version.split('.')[0]) >= 56) ||
      (browser.name === 'edge-chromium' &&
        Number(browser.version.split('.')[0]) >= 79) ||
      (browser.name === 'safari' &&
        Number(browser.version.split('.')[0]) >= 11) ||
      (browser.name === 'ios' && Number(browser.version.split('.')[0]) >= 11) ||
      (browser.name === 'crios' &&
        Number(browser.version.split('.')[0]) >= 60) ||
      (browser.name === 'fxios' && Number(browser.version.split('.')[0]) >= 9)
    )

    return (
      <FullContainer isInPopup={isInPopup}>
        {loggingIn && (
          <NoBgLoaderWrapper className="noscroller">
            <Loader className="loader" />
          </NoBgLoaderWrapper>
        )}
        {!loggingIn && (
          <span>
            {!isMobile &&
              !hideAppInfo &&
              (browser.os === 'Android OS' || browser.os === 'iOS') && (
                <AppAvailableDiv>
                  <Cross onClick={() => this.setState({ hideAppInfo: true })}>
                    <img src={icClearLine} alt="" width={26} />
                  </Cross>
                  <Logo>
                    <img src={appIconIos} alt="app icon" width={36} />
                  </Logo>
                  <TitleText>
                    <h3>docu tools</h3>
                    <p>
                      {browser.os === 'Android OS' && (
                        <FormattedMessage id="androidAppAvailable" />
                      )}
                      {browser.os === 'iOS' && (
                        <FormattedMessage id="iosAppAvailable" />
                      )}
                    </p>
                  </TitleText>
                  <DownloadAppLink
                    href={
                      browser.os === 'Android OS'
                        ? 'https://play.google.com/store/apps/details?id=com.docutools.app'
                        : 'https://itunes.apple.com/at/app/docu-tools-3/id1316586033?mt=8'
                    }
                    target="_blank"
                  >
                    <FormattedMessage id="show" />
                  </DownloadAppLink>
                </AppAvailableDiv>
            )}
            <InsideContainer
              isInPopup={isInPopup}
              maxWidth={400}
              onKeyPress={this.loginKeypress}
            >
              <div>
                <LogoSection isInPopup={isInPopup}>
                  {!hideLogo && (
                    <LogoImg
                      onClick={this.handleShowTokenLogin}
                      src={logo}
                      alt="docu tools"
                    />
                  )}
                </LogoSection>
                <Errormsgblock>
                  {errorMessageId && (
                    <Errormsg data-testid="Login-errorMsg-wrongCredentials">
                      <FormattedMessage
                        id={errorMessageId}
                        defaultMessage={formatMessage({
                          id: 'registerForm_wrongCredentials'
                        })}
                      />
                    </Errormsg>
                  )}
                  {params && params.mode === 'activated' && (
                    <Labelmsg>
                      <FormattedMessage id="registerForm_activationSuccessful" />
                    </Labelmsg>
                  )}
                  {!errorMessageId && loginError && (
                    <Error error={loginError} />
                  )}
                  {emailVerifying && (
                    <Labelmsg>
                      <FormattedMessage id="registerForm_emailVerifying" />
                    </Labelmsg>
                  )}
                  {verifyEmailWrong && (
                    <Errormsg>
                      <FormattedMessage id="registerForm_emailVerifyingWrong" />
                    </Errormsg>
                  )}
                  {emailVerified && (
                    <Labelmsg>
                      <FormattedMessage id="registerForm_emailVerified" />
                    </Labelmsg>
                  )}
                  {browserNotSupported && (
                    <Labelmsg>
                      <FormattedMessage id="browserNotSupported" />
                    </Labelmsg>
                  )}
                </Errormsgblock>
                {!show2FA && !emailVerifying && (
                  <span>
                    <FormContainer>
                      <FloatingLabel visible={!!email || emailMissing}>
                        {!emailMissing && (
                          <Labelmsg>
                            <FormattedMessage id="registerForm_email" />
                          </Labelmsg>
                        )}
                        {emailMissing && (
                          <Errormsg data-testid="Login-errorMsg-email">
                            <FormattedMessage id="registerForm_validEmail" />
                          </Errormsg>
                        )}
                      </FloatingLabel>
                      <CustomInputs
                        data-testid="Login-input-email"
                        autoFocus
                        type="email"
                        placeholder={formatMessage({
                          id: 'registerForm_email'
                        })}
                        value={email}
                        onChange={this.handleSetEmail}
                        wrongInput={emailMissing}
                      />
                    </FormContainer>
                    <FormContainer>
                      <InsideFormContainer>
                        <FloatingLabel visible={!!password || passwordMissing}>
                          {!passwordMissing && (
                            <Labelmsg>
                              <FormattedMessage id="registerForm_password" />
                            </Labelmsg>
                          )}
                          {passwordMissing && (
                            <Errormsg data-testid="Login-errorMsg-password">
                              <FormattedMessage id="registerForm_validPassword" />
                            </Errormsg>
                          )}
                        </FloatingLabel>
                        <CustomInputs
                          data-testid="Login-input-password"
                          type="password"
                          value={password}
                          placeholder={formatMessage({
                            id: 'registerForm_password'
                          })}
                          onChange={this.setPassword}
                          wrongInput={passwordMissing}
                        />
                      </InsideFormContainer>
                    </FormContainer>
                  </span>
                )}
                {show2FA && (
                  <FormContainer>
                    <InfoMsgTwoFa>
                      {show2FA.otp && (
                        <Fragment>
                          <FormattedMessage id="registerForm_codeDescription" />
                          <br />
                          <span>{`'docutools:${email}'`}</span>
                        </Fragment>
                      )}
                      {show2FA.sms && (
                        <FormattedMessage id="registerForm_smsCodeDescription" />
                      )}
                    </InfoMsgTwoFa>
                    <FloatingLabel visible={!!twoFaCode || twoFaCodeMissing}>
                      {!twoFaCodeMissing && (
                        <Labelmsg>
                          <FormattedMessage id="registerForm_code" />
                        </Labelmsg>
                      )}
                      {twoFaCodeMissing && (
                        <Errormsg>
                          <FormattedMessage id="registerForm_validCode" />
                        </Errormsg>
                      )}
                    </FloatingLabel>
                    <CustomInputs
                      type="text"
                      autoFocus
                      placeholder={formatMessage({ id: 'registerForm_code' })}
                      value={twoFaCode}
                      onChange={this.setTwoFaCode}
                      wrongInput={twoFaCodeMissing}
                    />
                  </FormContainer>
                )}
                {!emailVerifying && (
                  <FormContainer center>
                    <ButtonComponent
                      fullWidth
                      variant={buttonVariants.PRIMARY}
                      size={buttonSizes.LARGE}
                      disabled={show2FA && !twoFaCode}
                      onClick={this.login}
                      data-testid="Login-button-login"
                      style={{ marginTop: '30px' }}
                    >
                      <FormattedMessage id="registerform_login" />
                    </ButtonComponent>
                    {show2FA && (
                      <CustomBtn onClick={backToLogin}>
                        <FormattedMessage id="registerForm_back" />
                      </CustomBtn>
                    )}
                  </FormContainer>
                )}
              </div>
              <OtherContainer>
                {show2FA && (
                  <OtherLinks>
                    <FormattedMessage id="registerForm_reset2StepAuth" />
                    <span>&nbsp;</span>
                    <StyledLink to="/forgotPassword">
                      <FormattedMessage id="registerForm_reset2StepAuth" />
                    </StyledLink>
                  </OtherLinks>
                )}
                {!show2FA && !isInPopup && (
                  <OtherLinks>
                    <FormattedMessage id="registerForm_noAcc" />
                    <span>&nbsp;</span>
                    <ButtonComponent
                      variant={buttonVariants.LINK}
                      size={buttonSizes.SMALL}
                      emphasis
                      to={formatMessage({ id: 'registerForm_createAccHref' })}
                      data-testid="Login-linkToRegister"
                    >
                      <FormattedMessage id="registerForm_createAccLink" />
                    </ButtonComponent>
                  </OtherLinks>
                )}
                {!isInPopup && (
                  <OtherLinks>
                    <FormattedMessage id="registerForm_forgotPassword" />
                    <span>&nbsp;</span>
                    <ButtonComponent
                      variant={buttonVariants.LINK}
                      size={buttonSizes.SMALL}
                      emphasis
                      to="/forgotPassword"
                      data-testid="Login-link-forgotPassword"
                    >
                      <FormattedMessage id="registerForm_resetPassword" />
                    </ButtonComponent>
                  </OtherLinks>
                )}
                <OtherLinks>
                  <FormattedMessage id="registerForm_needHelp" />
                  <span>&nbsp;</span>
                  <ButtonComponent
                    variant={buttonVariants.LINK}
                    size={buttonSizes.SMALL}
                    emphasis
                    to="https://support.docu-tools.com/hc/de/requests/new"
                    target="_blank"
                    data-testid="Login-link-contactSupport"
                  >
                    <FormattedMessage id="registerForm_contactSupport" />
                  </ButtonComponent>
                </OtherLinks>
                <OtherLinks>
                  <span>
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                    <span onClick={this.startAnimation}>{versionString}</span>
                    <span>:</span>
                    <span>&nbsp;</span>
                    <Shovel
                      style={{
                        width: '16px',
                        height: '16px',
                        stroke: colorsOptions.docuToolsOrange.orange
                      }}
                    />
                    <span>&nbsp;</span>
                    <StyledLinkA as="span">
                      <span>{import.meta.env.VITE_VERSION_NAME || 'Tool'}</span>
                      <span> – </span>
                      <span>
                        {import.meta.env.VITE_REACT_APP_VERSION || '00'}
                      </span>
                      {!!import.meta.env.VITE_TARGET_ENV &&
                        import.meta.env.VITE_TARGET_ENV !== 'production' && (
                          <span>
                            {import.meta.env.VITE_TARGET_ENV.substring(0, 2)}
                          </span>
                      )}
                    </StyledLinkA>
                  </span>
                </OtherLinks>
              </OtherContainer>
              <SingleSignOnWrapper>
                <ButtonComponent
                  as={Link}
                  to="/idpSelection"
                  variant={buttonVariants['TERTIARY-HOLLOW']}
                  size={buttonSizes.LARGE}
                  fullWidth
                  style={{ padding: 0 }}
                >
                  <FormattedMessage id="singleSignOn_title" />
                </ButtonComponent>
              </SingleSignOnWrapper>
            </InsideContainer>
          </span>
        )}
      </FullContainer>
    )
  }
}
Login.propTypes = {
  login: PropTypes.func.isRequired,
  twoFaLogin: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  setTwoFaCode: PropTypes.func.isRequired,
  backToLogin: PropTypes.func.isRequired,
  email: PropTypes.string,
  password: PropTypes.string,
  loginError: PropTypes.object,
  intl: PropTypes.object,
  show2FA: PropTypes.object,
  twoFaCode: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  verifyEmail: PropTypes.func.isRequired,
  emailVerifying: PropTypes.bool,
  emailVerified: PropTypes.bool,
  verifyEmailWrong: PropTypes.bool,
  isMobile: PropTypes.bool,
  loginWithRedirect: PropTypes.func.isRequired,
  isInPopup: PropTypes.bool,
  hideLogo: PropTypes.bool,
  redirect_token: PropTypes.object,
  userType: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  email: selectors.makeSelectEmail(),
  userType: selectors.makeSelectUserType(),
  password: selectors.makeSelectPassword(),
  loginError: selectors.makeSelectLoginError(),
  show2FA: selectors.makeSelectShow2FA(),
  twoFaCode: selectors.makeSelectTwoFaCode(),
  emailVerifying: selectors.makeSelectEmailVerifying(),
  emailVerified: selectors.makeSelectEmailVerified(),
  verifyEmailWrong: selectors.makeSelectVerifyEmailWrong(),
  redirect_token: selectors.makeSelectRedirectToken(),
  isMobile: makeSelectIsMobile()
})

function mapDispatchToProps(dispatch) {
  return {
    login: (...attributes) => dispatch(actions.login(...attributes)),
    twoFaLogin: (...attributes) => dispatch(actions.twoFaLogin(...attributes)),
    setEmail: (value) => dispatch(actions.setEmail(value)),
    setPassword: (value) => dispatch(actions.setPassword(value)),
    setTwoFaCode: (value) => dispatch(actions.setTwoFaCode(value)),
    backToLogin: () => dispatch(actions.backToLogin()),
    verifyEmail: (token) => dispatch(actions.verifyEmail(token)),
    loginWithRedirect: (...attributes) =>
      dispatch(actions.loginWithRedirect(...attributes))
  }
}

export default withLocation(
  withRouteMatch(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login))
  )
)
