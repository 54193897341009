import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { prop, ifProp, withProp } from 'styled-tools'
import Button from '../../components/Button'
import sortedBullets from '@images/sorted_bullets.png'
import colorsOptions, {
  applyOpacity,
} from '../../components/stories/fundamentals/colors-styles/colors'
import {
  sizing,
  weights,
} from '../../components/stories/fundamentals/text-styles/text-styles'

export const Page = styled.div`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.5s;
`

export const GalleryWrapper = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  overflow: hidden;
  z-index: 999;
`

export const Post = styled.div`
  overflow: hidden;
  position: relative;
  background: ${colorsOptions.greys['grey-100']};
  color: ${colorsOptions.greys.white};
  width: ${({ width, isMobile }) => (isMobile ? width : width - 322)}px;
  height: ${({ height }) => height}px;
  float: left;
  z-index: 9999;
`

export const PostInfo = styled.div`
  position: absolute;
  width: 322px;
  right: 0;
  height: ${({ height }) => height}px;
  z-index: 999;
  background: ${colorsOptions.greys['grey-10']};
`

export const PostBar = styled.div`
  position: absolute;
  z-index: 2;
  background: ${applyOpacity(colorsOptions.greys['grey-100'], 0.4)};
  ${({ top }) => (top ? 'top: 0' : 'bottom: 0')};
  opacity: ${({ isMobile, showArrows }) => (!isMobile || showArrows ? '1' : 0)};
  width: 100%;
  height: 56px;
  transition: all 0.3s;
  padding: 16px 8px;
`
export const PostBarBottom = styled.div`
  position: absolute;
  z-index: 2;
  background: ${applyOpacity(colorsOptions.greys['grey-100'], 0.4)};
  ${({ top }) => (top ? 'top: 0' : 'bottom: 0')};
  opacity: ${({ isMobile, showArrows }) => (!isMobile || showArrows ? '1' : 0)};
  visibility: ${withProp(['isMobile', 'showArrows'], (isMobile, showArrows) =>
    !isMobile || showArrows ? 'visible' : 'hidden',
  )};
  width: 100%;
  transition: all 0.3s;
  padding: 8px 8px;
  .userInfo {
    .userText {
      margin-left: 50px;
      .bold {
        color: ${colorsOptions.greys.white};
        ${sizing['text-r']};
        ${weights['text-600']};
        background: url('${sortedBullets}') no-repeat 96% 7px;
        padding: 0 17px 0 0;
        margin-right: 10px;
      }
      .info {
        color: ${colorsOptions.greys['grey-50']};
        ${sizing['text-r']};
        ${weights['text-600']};
      }
    }
  }
`

export const PostMedia = styled.div`
  position: absolute;
  background: ${colorsOptions.greys['grey-100']};
  z-index: 1;
  top: ${({ isMobile }) => (isMobile ? 0 : '56px')};
  width: 100%;
  height: ${({ height, isMobile }) => (isMobile ? height : height - 50)}px;

  .arrow {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    width: 96px;
    height: ${({ height, isPlayer }) =>
      isPlayer ? height - 105 : height - 112}px;
    @media (max-width: 767px) {
      height: ${({ height, isPlayer }) =>
        isPlayer ? height - 160 : height - 112}px;
    }
    top: ${({ isMobile }) => (isMobile ? '56px' : 0)};
    opacity: ${({ showArrows }) => (showArrows ? '1' : '0')};
    transition: all 0.3s;
    background-color: ${applyOpacity(colorsOptions.greys['grey-100'], 0.4)};
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    &:hover {
      background-color: ${applyOpacity(colorsOptions.greys['grey-100'], 0.4)};
    }
  }
  &:hover {
    .arrow {
      opacity: ${({ isMobile, showArrows }) =>
        !isMobile || (isMobile && showArrows) ? '1' : '0'};
    }
  }

  .media {
    width: 100%;
    height: 100%;
    text-align: center;

    audio {
      width: 100%;
      height: ${({ playerHeight }) => playerHeight}px;
    }
  }
`

export const FullImage = styled.img`
  max-height: ${({ height }) => height - 56}px;
  max-width: ${({ width, isMobile }) => (!isMobile ? width - 320 : width)}px;
  width: auto;
  height: auto;
  display: ${ifProp('hide', 'none', 'block')};
  transform: rotate(${prop('rotateImage')}deg);
`

export const PlayerButton = styled(Button)`
  width: 42px;
  height: 42px;
`

export const PostInfoFooter = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`

export const PostInfoFooterBottons = styled.div`
  text-align: center;
  padding: 10px;
`

export const PostInfoFooterMetadata = styled.div`
  padding: 10px;
  ${sizing['text-s']};
  ${weights['text-700']};

  .label {
    color: ${colorsOptions.greys['grey-60']};
  }
  .content {
    display: inline-block;
    margin-left: 3px;
    color: ${colorsOptions.greys['grey-100']};
  }
  .show-all {
    cursor: pointer;
  }
  ${({ borderTop }) =>
    borderTop === 'true' &&
    `border-top: 1px solid ${colorsOptions.greys['grey-30']};`}
`
export const ViewAllMetaData = styled.div``

export const GalleryButton = styled.a`
  padding: 8px 42px;
  display: inline-block;
  color: ${({ copied }) =>
    copied
      ? colorsOptions.secondaryColors.orange.normal
      : colorsOptions.greys['grey-100']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 40px;
  border: 1px solid ${colorsOptions.greys['grey-30']};

  background: ${colorsOptions.greys.white};
  ${weights['text-600']};
  text-decoration: none;
  width: 90%;
  transition: color 0.5s ease-out;

  &:hover {
    background: ${colorsOptions.greys['grey-20']}; //not confirmed
    color: ${({ copied }) =>
      copied
        ? colorsOptions.docuToolsOrange.orange
        : colorsOptions.greys['grey-60']};

    border-color: ${colorsOptions.greys['grey-30']};
    cursor: pointer;
  }
  &:focus {
    @media (max-width: 1024px) {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 1);
      background: ${colorsOptions.greys.white};
    }
  }
  &:disabled {
    background: none !important;
    opacity: 0.6;
    color: ${colorsOptions.greys['grey-50']};
    border: 1px solid ${colorsOptions.greys['grey-30']};
    cursor: not-allowed;
  }
  @media (max-width: 767px) {
    padding: 7px 42px;
  }

  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`
export const GalleryButtonLink = styled(Link)`
  padding: 8px 42px;
  display: inline-block;
  color: ${({ copied }) =>
    copied
      ? colorsOptions.secondaryColors.orange.normal
      : colorsOptions.greys['grey-100']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 40px;
  border: 1px solid ${colorsOptions.greys['grey-30']};

  background: ${colorsOptions.greys.white};
  ${weights['text-600']};
  text-decoration: none;
  width: 90%;
  transition: color 0.5s ease-out;

  &:hover {
    background: ${colorsOptions.greys['grey-20']}; //not confirmed
    color: ${({ copied }) =>
      copied
        ? colorsOptions.docuToolsOrange.orange
        : colorsOptions.greys['grey-60']};

    border-color: ${colorsOptions.greys['grey-30']};
    cursor: pointer;
  }
  &:focus {
    @media (max-width: 1024px) {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 1);
      background: ${colorsOptions.greys.white};
    }
  }
  &:disabled {
    background: none !important;
    opacity: 0.6;
    color: ${colorsOptions.greys['grey-50']};
    border: 1px solid ${colorsOptions.greys['grey-30']};
    cursor: not-allowed;
  }
  @media (max-width: 767px) {
    padding: 7px 42px;
  }

  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const AudioInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: ${colorsOptions.greys['grey-100']};
  width: 100%;
  height: 100%;
  background: ${colorsOptions.greys['grey-30']};

  .icon {
    width: 160px;
    height: 160px;
  }
`

export const MediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const Close = styled.button`
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border-radius: 25px;
  height: 48px;
  width: 48px;
  overflow: hidden;
  text-align: center;
  margin-top: -12px;
  @media (max-width: 767px) {
    float: left;
  }
`

export const MediaPagination = styled.span`
  display: inline-block;
  padding: 3px 10px;
  ${sizing['text-l']};
  ${weights['text-500']};
  @media (max-width: 767px) {
    display: inline-block;
    width: 75%;
    margin: 0 auto;
    text-align: center;
    ${weights['text-600']};
  }
`
export const AudioContentWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

export const NameText = styled.div`
  ${sizing['text-l']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
`

export const DetailText = styled.div`
  ${sizing['text-l']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-60']};
`

export const DetailWrapper = styled.div`
  margin-top: -23px;
`

export const Player = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 60px;
  background: ${colorsOptions.greys.white};
  border-top: 1px solid ${colorsOptions.greys['grey-30']};
  display: flex;
  align-items: center;
  padding: 0 10px;
`

export const BtnBlockTwo = styled.div``

export const AudioPlaySecionTwo = styled.div`
  color: ${colorsOptions.greys['grey-100']};
  white-space: nowrap;
  span {
    ${sizing['text-l']};
    ${weights['text-600']};
    color: ${colorsOptions.greys['grey-100']};
  }
`
export const PopupInside = styled.div`
  padding: 15px 16px 15px;
`
