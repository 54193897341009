export const OPEN_PROJECTS = 'app/QuickSelectProject/OPEN_PROJECTS'
export const CLOSE_PROJECTS = 'app/QuickSelectProject/CLOSE_PROJECTS'
export const OPEN_PROJECT_FOLDER = 'app/QuickSelectProject/OPEN_PROJECT_FOLDER'
export const OPEN_PLANS = 'app/QuickSelectProject/OPEN_PLANS'
export const CLOSE_PLANS = 'app/QuickSelectProject/CLOSE_PLANS'
export const OPEN_PLAN_FOLDER = 'app/QuickSelectProject/OPEN_PLAN_FOLDER'
export const SET_PROJECT_FILTER = 'app/QuickSelectProject/SET_PROJECT_FILTER'
export const SET_PLAN_FILTER = 'app/QuickSelectProject/SET_PLAN_FILTER'

export const CLEAR_PLAN_FOLDER_SELECT_BOX =
  'app/QuickSelectProject/CLEAR_PLAN_FOLDER_SELECT_BOX'
export const CLEAR_PLANS = 'app/QuickSelectProject/CLEAR_PLANS'
export const CLEAR_FOLDER_PLANS = 'app/QuickSelectProject/CLEAR_FOLDER_PLANS'
export const GET_ROOT_PLANS = 'app/QuickSelectProject/GET_ROOT_PLANS'
export const GET_ROOT_PLANS_DONE = 'app/QuickSelectProject/GET_ROOT_PLANS_DONE'
export const GET_ROOT_PLANS_ERROR =
  'app/QuickSelectProject/GET_ROOT_PLANS_ERROR'
export const GET_FOLDER_PLANS = 'app/QuickSelectProject/GET_FOLDER_PLANS'
export const GET_FOLDER_PLANS_DONE =
  'app/QuickSelectProject/GET_FOLDER_PLANS_DONE'
export const GET_FOLDER_PLANS_ERROR =
  'app/QuickSelectProject/GET_FOLDER_PLANS_ERROR'
export const SET_CURRENT_PLAN_FOLDER_ID =
  'app/QuickSelectProject/SET_CURRENT_PLAN_FOLDER_ID'
export const SET_GET_PLAN_TREE_CANCEL_TOKEN =
  'app/QuickSelectProject/SET_GET_PLAN_TREE_CANCEL_TOKEN'
