import styled from 'styled-components'

/**
 *
 * @deprecated Please use Errormsg
 * @component
 */
export default styled.div`
  margin-bottom: 10px;
  text-align: center;
`
