import styled, { css } from 'styled-components'
import { iconSizeOptions, iconSizes } from './icons-sizes'

const SvgIconComponent = styled.svg`
  ${({ size = iconSizeOptions['ICON-M'] }) => css`
    ${iconSizes[size]}
  `}

  // used to give an special color for couple Specific Icons 
  // such as ( ImportPlan, NewFolder, NewProject... )
  .special-color {
    stroke: ${({ specialStroke }) => specialStroke};
    fill: ${({ specialFill }) => specialFill};
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
`

const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const AnimateSpin = styled(SvgIconComponent)`
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export {
  SvgIconComponent,
  Container,
  IconsContainer,
  TextContainer,
  AnimateSpin
}
