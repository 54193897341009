import * as actionTypes from './constants'

export function setEmail(email) {
  return {
    type: actionTypes.SET_EMAIL,
    email,
  }
}

export function resetPassword() {
  return {
    type: actionTypes.RESET_PASSWORD,
  }
}

export function resetPasswordDone() {
  return {
    type: actionTypes.RESET_PASSWORD_DONE,
  }
}

export function emailNotValid() {
  return {
    type: actionTypes.EMAIL_NOT_VALID,
  }
}

export function resetPasswordError(error) {
  return {
    type: actionTypes.RESET_PASSWORD_ERROR,
    error,
  }
}
