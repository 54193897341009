const weights = {
  'text-400': { fontFamily: '"Raleway-Regular", "sans-serif"' }, // default
  'text-500': { fontFamily: '"Raleway-Medium", "sans-serif"' },
  'text-600': { fontFamily: '"Raleway-SemiBold", "sans-serif"' },
  'text-700': { fontFamily: '"Raleway-Bold", "sans-serif"' },
}

const sizing = {
  'text-xs': {
    fontSize: '0.625rem',
    lineHeight: '0.8125rem',
    letterSpacing: '0.013rem',
  },
  'text-s': {
    fontSize: '0.75rem',
    lineHeight: '0.975rem',
    letterSpacing: '0.013rem',
  },
  'text-r': {
    fontSize: '0.875rem',
    lineHeight: '1.1475rem',
    letterSpacing: '0.013rem',
  }, // default
  'text-l': {
    fontSize: '1rem',
    lineHeight: '1.3rem',
    letterSpacing: '0.013rem',
  },
  'text-xl': {
    fontSize: '1.125rem',
    lineHeight: '1.4625rem',
    letterSpacing: '0.013rem',
  },
  'text-xxl': {
    fontSize: '1.5rem',
    lineHeight: '1.95rem',
    letterSpacing: '0.013rem',
  },
  'text-xxxl': {
    fontSize: '2rem',
    lineHeight: '2.6rem',
    letterSpacing: '0.013rem',
  },
}

export { sizing, weights }

// ${({ size = 'text-r' }) => sizing[size]}
// ${({ weight = 'text-400' }) => weights[weight]}
