import styled from 'styled-components'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'
import { sizing, weights } from '../stories/fundamentals/text-styles/text-styles'

/**
 * Orange link using the a html element
 * @deprecated should be merged into StyledLink
 * @component
 */
export default styled.a`
  cursor: pointer;
  ${weights['text-500']};
  ${sizing['text-s']};
  color: ${colorsOptions.docuToolsOrange.orange};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
