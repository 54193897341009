import styled, { css } from 'styled-components'
import { ifProp, prop, ifNotProp } from 'styled-tools'
import PropTypes from 'prop-types'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'
import {
  sizing,
  weights,
} from '../stories/fundamentals/text-styles/text-styles'

export const BUTTON_TYPES = {
  round: 'round',
  wide: 'wide',
  leftRounded: 'leftRounded',
  rightRounded: 'rightRounded',
  text: 'text',
  dropdown: 'dropdown',
}

const PrimaryButtonCss = css`
  color: ${ifProp(
    'invert',
    colorsOptions.docuToolsOrange.orange,
    colorsOptions.greys.white,
  )};
  background-color: ${ifProp(
    'invert',
    colorsOptions.greys.white,
    colorsOptions.docuToolsOrange.orange,
  )};
  border-color: ${colorsOptions.docuToolsOrange.orange};

  &:hover {
    color: ${colorsOptions.greys.white};
    background-color: ${colorsOptions.docuToolsOrange.orange};
    border-color: ${colorsOptions.docuToolsOrange.orange};
  }

  &:disabled,
  &[disabled] {
    color: ${colorsOptions.greys['grey-60']};
    background-color: ${colorsOptions.greys.white};
    border-color: ${colorsOptions.greys['grey-30']};
  }

  &:disabled:hover,
  &[disabled]:hover {
    color: ${colorsOptions.greys['grey-60']};
    background-color: ${colorsOptions.greys.white};
    border-color: ${colorsOptions.greys['grey-30']};
  }
`

const canShrink = css`
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
`

const makePrimary = (component) => styled(component)`
  ${PrimaryButtonCss}
`

export const Button = styled.button`
  display: inline-block;
  padding: 0 ${prop('padding', '42px')};
  min-height: ${prop('height', '36px')};
  height: fit-content;
  width: ${prop('width')};
  white-space: ${prop('whiteSpace', 'nowrap')};
  text-align: center;
  ${weights['text-600']};
  ${prop('fontSize', sizing['text-l'])};
  border-radius: 40px;
  color: ${prop('color', colorsOptions.greys['grey-100'])};
  background-color: ${prop('bgColor', colorsOptions.greys.white)};
  line-height: ${prop('height')};

  &:hover {
    cursor: pointer;
    color: ${colorsOptions.greys['grey-60']};
    background-color: ${colorsOptions.greys['grey-20']};
    border-color: ${colorsOptions.greys['grey-30']};
  }

  &:focus {
    @media (max-width: 1024px) {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 1);
      background-color: ${colorsOptions.greys.white};
    }
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    color: ${prop('disabledColor', colorsOptions.greys['grey-60'])};
    border: ${ifNotProp(
      'noBorder',
      `1px solid ${colorsOptions.greys['grey-30']}`,
    )};
  }

  /*variations by props */
  ${ifNotProp(
    'noBorder',
    css`
      border: 1px solid ${prop('borderColor', colorsOptions.greys['grey-30'])};
    `,
  )}
  ${ifProp('$noPadding', 'padding: initial')};
  ${ifProp(
    'withShadow',
    css`
      box-shadow: 0 1px 2px 0 ${colorsOptions.greys['grey-100']}1A;
    `,
  )}
  ${ifProp(
    { as: 'a' },
    css`
      text-decoration: none;
    `,
  )}
  ${ifProp(
    'size',
    css`
      width: ${prop('size')}px;
      height: ${prop('size')}px;
    `,
  )}
  ${ifProp(
    '$center',
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `,
  )}
  ${ifProp('isPrimary', PrimaryButtonCss)}
  ${ifProp('canShrink', canShrink)}

  /* Button Types */
  ${ifProp(
    { type: BUTTON_TYPES.round },
    css`
      border-radius: ${prop('size')}px;
      padding: 0;
      min-height: auto;
    `,
  )}

  ${ifProp(
    { type: BUTTON_TYPES.wide },
    css`
      width: 100%;
      display: block;
    `,
  )}

  ${ifProp(
    { type: BUTTON_TYPES.leftRounded },
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid ${colorsOptions.greys['grey-30']};
    `,
  )}

  ${ifProp(
    { type: BUTTON_TYPES.rightRounded },
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px solid ${colorsOptions.greys['grey-30']};
    `,
  )}

  ${ifProp(
    { type: BUTTON_TYPES.text },
    css`
      background: none;
      border: none;
      padding: 0;
      &:hover {
        background: none;
        border: none;
      }
    `,
  )}
`

export const ButtonPrimary = makePrimary(Button)
export default Button

Button.propTypes = {
  canShrink: PropTypes.bool,
  noBorder: PropTypes.bool,
  disabledColor: PropTypes.string,
  whiteSpace: PropTypes.string,
  noPadding: PropTypes.bool,
  withShadow: PropTypes.bool,
  size: PropTypes.number,
  center: PropTypes.bool,
  isPrimary: PropTypes.bool,
  type: PropTypes.oneOf([
    BUTTON_TYPES.round,
    BUTTON_TYPES.wide,
    BUTTON_TYPES.leftRounded,
    BUTTON_TYPES.rightRounded,
    BUTTON_TYPES.text,
  ]),
}
