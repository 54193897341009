import styled from 'styled-components'
import { weights } from '../stories/fundamentals/text-styles/text-styles'

/**
 * @component
 */
export default styled.h3`
  ${weights['text-600']};
  text-align: center;
`
