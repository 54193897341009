import { fromJS } from 'immutable'
import { LOGOUT } from '../Authentication/constants'
import { CHANGE_LOCALE, CHANGE_LOCALE_DONE } from './constants'

import {
  messages as enMessages,
  countryNames as enCountryNames,
} from './chunks/en'

const initialState = fromJS({
  locale: 'en',
  messages: enMessages,
  countryNames: enCountryNames,
})

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      if (typeof sessionStorage !== 'undefined') {
        // needed for test
        if (sessionStorage) {
          sessionStorage.setItem('language', action.locale)
        }
      }

      return state

    case CHANGE_LOCALE_DONE:
      return (
        state
          .set('locale', action.locale)
          .set('localeChanged', true)
          // TODO: Avoid immutable conversion if possible
          .set('countryNames', fromJS(action.countryNames))
          .set('messages', fromJS(action.messages))
      )

    case LOGOUT:
      if (typeof sessionStorage !== 'undefined') {
        // needed for test
        if (sessionStorage) {
          sessionStorage.removeItem('language')
        }
      }
      return state.set('localeChanged', false)
    default:
      return state
  }
}

export default languageProviderReducer
