const iconSizeOptions = {
  'ICON-S': 'icon-s',
  'ICON-M': 'icon-m',
  'ICON-L': 'icon-l',
  'ICON-XL': 'icon-xl'
}

const iconSizes = {
  [iconSizeOptions['ICON-S']]: {
    width: '16px',
    height: '16px'
  },
  [iconSizeOptions['ICON-M']]: {
    width: '24px',
    height: '24px'
  },
  [iconSizeOptions['ICON-L']]: {
    width: '32px',
    height: '32px'
  },
  [iconSizeOptions['ICON-XL']]: {
    width: '48px',
    height: '48px'
  }
}

export { iconSizeOptions, iconSizes }
