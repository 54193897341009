import * as actionTypes from './constants'

export function setFirstName(firstName) {
  return {
    type: actionTypes.SET_FIRST_NAME,
    firstName,
  }
}

export function setLastName(lastName) {
  return {
    type: actionTypes.SET_LAST_NAME,
    lastName,
  }
}

export function setEmail(email) {
  return {
    type: actionTypes.SET_EMAIL,
    email,
  }
}

export function emailTaken() {
  return {
    type: actionTypes.EMAIL_TAKEN,
  }
}

export function setOrganisation(organisation) {
  return {
    type: actionTypes.SET_ORGANISATION,
    organisation,
  }
}

export function setCountry(country) {
  return {
    type: actionTypes.SET_COUNTRY,
    country,
  }
}

export function setOriginationCode(originationCode) {
  return {
    type: actionTypes.SET_ORIGINATION_CODE,
    originationCode,
  }
}

export function registerUser(retries = 1) {
  return {
    type: actionTypes.REGISTER_USER,
    retries,
  }
}

export function registerUserSuccessful() {
  return {
    type: actionTypes.REGISTER_USER_SUCCESSFUL,
  }
}

export function registerUserError(error) {
  return {
    type: actionTypes.REGISTER_USER_ERROR,
    error,
  }
}

export function setPhone(phone) {
  return {
    type: actionTypes.SET_PHONE,
    phone,
  }
}

export function getCountry(longitude, latitude) {
  return {
    type: actionTypes.GET_COUNTRY,
    longitude,
    latitude,
  }
}
export function getCountryDone(countryDetail) {
  return {
    type: actionTypes.GET_COUNTRY_DONE,
    countryDetail,
  }
}
export function getCountryError(error) {
  return {
    type: actionTypes.GET_COUNTRY_ERROR,
    error,
  }
}
