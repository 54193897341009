import styled from 'styled-components'
import { weights, sizing } from './text-styles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Heading = styled.span`
  ${({ loading }) => loading ? 'gap: 8px;' : ''}
  ${({ size = 'text-r' }) => sizing[size]}
  ${({ weight = 'text-400' }) => weights[weight]}
  color: ${(color) => color};
  white-space: pre;
`

const TextContainer = styled.p`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export { Heading, Container, TextContainer }
