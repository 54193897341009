import styled from 'styled-components'
import {
  sizing,
  weights,
} from '../stories/fundamentals/text-styles/text-styles'

export const H1 = styled.h1`
  font-weight: normal;
  ${weights['text-700']};
  ${sizing['text-xxl']};
  margin-top: 38px;
  word-wrap: break-word;
  @media (max-width: 767px) {
    margin-top: 6px;
    ${sizing['text-xl']};
    white-space: ${({ wrapLine }) => (wrapLine ? 'normal' : 'nowrap')};
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  }
`
