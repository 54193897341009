import { createSelector } from 'reselect'

const selectForgotPasswordDomain = (state) => state.get('forgotPassword')

const makeSelectEmail = () =>
  createSelector(selectForgotPasswordDomain, (substate) =>
    substate.get('email'),
  )

const makeSelectResetPasswordError = () =>
  createSelector(selectForgotPasswordDomain, (substate) =>
    substate.get('resetPasswordError'),
  )

const makeSelectResetPasswordDone = () =>
  createSelector(selectForgotPasswordDomain, (substate) =>
    substate.get('resetPasswordDone'),
  )

const makeSelectResetingPassword = () =>
  createSelector(selectForgotPasswordDomain, (substate) =>
    substate.get('resetingPassword'),
  )

const makeSelectEmailNotValid = () =>
  createSelector(selectForgotPasswordDomain, (substate) =>
    substate.get('emailNotValid'),
  )

export {
  selectForgotPasswordDomain,
  makeSelectEmail,
  makeSelectResetPasswordError,
  makeSelectResetPasswordDone,
  makeSelectResetingPassword,
  makeSelectEmailNotValid,
}
