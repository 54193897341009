import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import Button from '../../components/Button'
import colorsOptions from '../../components/stories/fundamentals/colors-styles/colors'
import {
  sizing,
  weights,
} from '../../components/stories/fundamentals/text-styles/text-styles'

export const ResultCount = styled.div`
  margin-left: auto;
`

export const Heading = styled.h6`
  ${sizing['text-l']};
  ${weights['text-500']};
`
export const FilterSelect = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  margin-right: 4px;
  ${({ minwidth }) => (minwidth ? `min-width: ${minwidth}px;` : '')}
  width: 100%;

  input.input {
    border: 1px solid ${colorsOptions.greys['grey-100']};
    border-radius: 4px;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
  }

  /* Custom React Select Styling */
  .selectbox .Select__control {
    height: 32px;
    min-height: 32px;

    .Select__input {
      height: 32px;
    }

    .Select__placeholder {
      font-weight: 300;
      ${weights['text-500']};
      ${sizing['text-r']};
      opacity: 1 !important;
      color: ${colorsOptions.greys['grey-50']} !important;
    }

    .Select__value {
      text-overflow: inherit;
    }

    .Select__single-value {
      ${sizing['text-r']};
      text-overflow: inherit;
    }
  }

  .selectbox .Select__control .Select__arrow-zone {
    opacity: 0;
  }
  .selectbox .Select__clear-zone {
    display: none !important;
  }

  .Select__control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    border-radius: 4px !important;
    border-color: ${colorsOptions.greys['grey-100']};
    background-size: 14px 14px;
    min-width: 180px;
    &:hover {
      box-shadow: none !important;
      border-color: ${colorsOptions.greys['grey-100']};
    }
  }
  .selectbox .Select__input > input {
    ${weights['text-600']};
  }

  .selectbox .Select__option {
    font-family: ${weights['text-600'].fontFamily} !important;
    color: ${colorsOptions.greys['grey-100']} !important;
    ${sizing['text-r']};
    font-size: ${sizing['text-r'].fontSize} !important;
    word-wrap: break-word;
    &.is-focused {
      background: ${colorsOptions.greys['grey-20']} !important;
    }
    &.is-selected {
      background: ${colorsOptions.greys['grey-10']} !important;
    }
  }
  .is-focused:not(.is-open) > .Select__control {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .selectbox .Select__placeholder,
  .selectbox .Select__single-value {
    font-family: ${weights['text-500'].fontFamily} !important;
  }

  .Select__indicator-separator {
    width: 0;
  }

  .Select__menu-list {
    padding-top: 0;
  }

  .Select__option--is-focused,
  .Select__option--is-selected {
    background-color: ${colorsOptions.greys['grey-20']};
    color: ${colorsOptions.greys['grey-100']};
  }

  .Select__option:active {
    background-color: ${colorsOptions.greys['grey-50']};
  }

  svg {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`
export const CustomSelectMenuItems = styled.div`
  ${weights['text-500']};
  cursor: pointer;
  ${sizing['text-l']};
  word-wrap: break-word;
  display: block;
  padding: 8px 10px;
  color: #666666;
  &:hover {
    background-color: ${colorsOptions.greys['grey-20']};
  }
`

export const CustomSelectMenuItems2 = styled.div`
  display: flex;
  span {
    ${ifProp(
      { state: 'Removed' },
      `vertical-align: bottom;
      color: ${colorsOptions.greys['grey-50']}
      `,
    )}
  }
`

export const SelectBoxStyle = ({ setContainerBgGrey, maxHeight, width }) => ({
  control: (base) => ({
    ...base,
    height: '32px',
    minHeight: '32px',
    width,
    background: setContainerBgGrey && colorsOptions.greys['grey-20'],
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: '14px',
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: '14px',
    maxLength: '100px',
  }),
  menuList: (base) => ({
    ...base,
    maxHeight,
    zIndex: '999',
  }),
})

export const CustomBtn = styled(Button)`
  padding: 8px 37px;
  display: inline-block;
  background: ${colorsOptions.greys.white};
  color: ${({ active }) =>
    active
      ? colorsOptions.secondaryColors.orange.normal
      : colorsOptions.greys['grey-100']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 20px;
  border: 1px solid ${colorsOptions.greys['grey-30']};
  ${weights['text-600']};
  margin-right: 20px;
  /* height:40px; */
  &:hover {
    background: ${colorsOptions.greys['grey-20']}; //not confirmed
    border-color: ${colorsOptions.greys['grey-30']};
  }
`
export const ApplyBtn = styled(CustomBtn)`
  &:not(:disabled) {
    :hover {
      color: ${colorsOptions.docuToolsOrange.orange};
      border-color: ${colorsOptions.docuToolsOrange.orange};
      background-color: ${colorsOptions.greys.white};
    }
  }
`

export const DatepickerButton = styled.button`
  cursor: ${ifProp('disabled', 'default', 'pointer')};
  text-transform: uppercase;
  border: 1px solid ${colorsOptions.greys['grey-100']};
  border-radius: 4px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  ${ifProp('disabled', `color: ${colorsOptions.greys['grey-50']};`)}

  &:not(:first-child) {
    margin-left: 8px;
  }
  &:not(:last-child) {
    margin-right: 8px;
  }
`

export const FilterDiv = styled.div`
  position: relative;
`
export const CloseBtn = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`

export const Row = styled.div`
  margin: 16px 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  & > * {
    margin: 8px 8px 0;
  }
  & .Select__control {
    ${ifProp('disabled', `background: ${colorsOptions.greys['grey-20']};`)}
  }

  & > .filter-select:nth-child(4) {
    flex: 1 1 auto;
  }
  ${ifProp(
    'hardDisabled',
    css`
      pointer-events: none;
      & input {
        background: ${colorsOptions.greys['grey-20']};
      }
    `,
  )}
`

export const Wrapper = styled.div`
  position: relative;
  padding: 16px;
  margin-top: 32px;

  background-color: ${colorsOptions.greys.white};
  border: 1px solid ${colorsOptions.greys['grey-20']};

  ${sizing['text-r']};
  ${weights['text-600']};
`
