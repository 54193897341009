import { fromJS } from 'immutable'
import { LOGOUT } from '../Authentication/constants'
import * as actionTypes from './constants'

const initialState = fromJS({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  organisation: '',
  country: '',
})

function registerReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_FIRST_NAME:
      return state.set('firstName', action.firstName)
    case actionTypes.SET_LAST_NAME:
      return state.set('lastName', action.lastName)
    case actionTypes.SET_EMAIL:
      return state.set('email', action.email).set('emailTaken', false)
    case actionTypes.SET_PHONE:
      return state.set('phone', action.phone)
    case actionTypes.SET_ORGANISATION:
      return state.set('organisation', action.organisation)
    case actionTypes.SET_COUNTRY:
      return state.set('country', action.country)
    case actionTypes.SET_ORIGINATION_CODE:
      return state.set('originationCode', action.originationCode)
    case actionTypes.REGISTER_USER:
      return state.set('registerUser', true)
    case actionTypes.REGISTER_USER_SUCCESSFUL:
      return state
        .set('userRegistered', true)
        .set('emailTaken', false)
        .set('registerUser', false)
    case actionTypes.REGISTER_USER_ERROR:
      return state
        .set('registerUserError', action.error)
        .set('registerUser', false)
        .set('userRegistered', false)
    case actionTypes.EMAIL_TAKEN:
      return state.set('emailTaken', true).set('registerUser', false)
    case actionTypes.GET_COUNTRY:
      return state
        .set('gettingCountry', true)
        .delete('getCountryError')
        .delete('countryDetail')
    case actionTypes.GET_COUNTRY_DONE:
      return state
        .set('gettingCountry', false)
        .set('countryDetail', action.countryDetail)
    case actionTypes.GET_COUNTRY_ERROR:
      return state
        .set('gettingCountry', false)
        .set('getCountryError', action.error)

    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default registerReducer
