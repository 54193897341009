import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import { bool } from 'prop-types'
import {
  sizing,
  weights,
} from '../stories/fundamentals/text-styles/text-styles'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'

const Labelmsg = styled.span`
  ${ifProp('smaller', sizing['text-s'], sizing['text-r'])};
  ${weights['text-600']};
  color: ${prop('color', colorsOptions.greys['grey-50'])};
  cursor: ${ifProp('clickable', 'pointer', 'default')};
  ${ifProp('hide', 'visibility: hidden;')}
  word-break: normal;
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`

Labelmsg.propTypes = {
  smaller: bool,
  hide: bool,
  clickable: bool,
}

/**
 * @component
 */
export default Labelmsg
