/**
 * Checks if the current url is part of a specified page
 */
export const isActivePath = (page, routerMatch = {}) => {
  switch (page) {
    case pageType.PROJECTS:
      return !!(routerMatch.url && /\/project.*|\/$/.test(routerMatch.url))
    case pageType.SYNC_STATUS:
      return !!(
        routerMatch.url !== '/' &&
        routerMatch.url &&
        routerMatch.url.startsWith('/syncStatus')
      )
    default:
      return false
  }
}

export const pageType = {
  PROJECTS: 'PROJECTS',
  SYNC_STATUS: 'SYNC_STATUS',
}
