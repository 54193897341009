import { createSelector } from 'reselect'
import { mobileOSList, tabletUserAgent } from './constants'
import { deserializeQueryString } from '../../utils/urlManager'

const selectRouteDomain = (state, props) => {
  const location = props?.location ?? window.location
  return location
    ? {
        currentRoute: location.pathname,
        currentRouteHref: `${location.pathname}${location.search}`,
        currentQuery: deserializeQueryString(location.search),
      }
    : { currentQuery: {} }
}

const makeSelectCurrentRoute = () =>
  createSelector(selectRouteDomain, (props) => props.currentRoute)
const makeSelectCurrentRouteHref = () =>
  createSelector(selectRouteDomain, (props) => props.currentRouteHref)

const makeSelectCurrentQuery = () =>
  createSelector(selectRouteDomain, (props) => props.currentQuery)

const selectAppDomain = (state) => state.get('app')

const makeSelectShowPopupOverlay = () =>
  createSelector(selectAppDomain, (substate) =>
    substate.get('showPopupOverlay'),
  )
const makeSelectShowMobilePopupOverlay = () =>
  createSelector(selectAppDomain, (substate) =>
    substate.get('showMobilePopupOverlay'),
  )
const makeSelectMenuOpen = () =>
  createSelector(selectAppDomain, (substate) => substate.get('menuOpen'))
const makeSelectIsMobile = () =>
  createSelector(
    selectAppDomain,
    // In light of recent devices being more and more wide, we have to make as sure as we can that we are not dealing with a tablet.
    (substate) =>
      substate.get('windowWidth') < 900 &&
      mobileOSList.includes(substate.get('browserInfo')?.os) &&
      !tabletUserAgent.some(
        (uA) => window.navigator.userAgent.indexOf(uA) > -1,
      ),
  )
const makeSelectIsDesktop = () =>
  createSelector(
    selectAppDomain,
    (substate) => substate.get('windowWidth') > 1024,
  )
const makeSelectIsTablet = () =>
  createSelector(
    selectAppDomain,
    (substate) =>
      substate.get('windowWidth') >= 900 &&
      mobileOSList.includes(substate.get('browserInfo')?.os),
  )

const makeSelectWindowWidth = () =>
  createSelector(selectAppDomain, (substate) => substate.get('windowWidth'))
const makeSelectWindowHeight = () =>
  createSelector(selectAppDomain, (substate) => substate.get('windowHeight'))
const makeSelectGalleryIndex = () =>
  createSelector(selectAppDomain, (substate) => substate.get('galleryIndex'))
const makeSelectGalleryShowEdit = () =>
  createSelector(selectAppDomain, (substate) => substate.get('galleryShowEdit'))

const makeSelectShowBottomBar = () =>
  createSelector(selectAppDomain, (substate) => substate.get('showBottomBar'))

const makeSelectPwaBannerDismissed = () =>
  createSelector(selectAppDomain, () =>
    localStorage.getItem('pwaBannerDismissed'),
  )

const makeSelectBrowserInfo = () =>
  createSelector(selectAppDomain, (subState) => subState.get('browserInfo'))

export {
  selectAppDomain,
  selectRouteDomain,
  makeSelectCurrentRoute,
  makeSelectCurrentRouteHref,
  makeSelectCurrentQuery,
  makeSelectShowPopupOverlay,
  makeSelectShowMobilePopupOverlay,
  makeSelectMenuOpen,
  makeSelectIsMobile,
  makeSelectIsDesktop,
  makeSelectIsTablet,
  makeSelectWindowWidth,
  makeSelectWindowHeight,
  makeSelectGalleryIndex,
  makeSelectGalleryShowEdit,
  makeSelectShowBottomBar,
  makeSelectPwaBannerDismissed,
  makeSelectBrowserInfo,
}
