import { createSelector } from 'reselect'

const selectPlanDomain = (state) => state.get('plan')

const makeSelectPlanRevision = () =>
  createSelector(selectPlanDomain, (substate) => substate.get('planRevision'))
const makeSelectPlanRevisionId = () =>
  createSelector(
    selectPlanDomain,
    (substate) => substate.get('planRevision')?.id,
  )
const makeSelectPlan = () =>
  createSelector(selectPlanDomain, (substate) => substate?.get('plan'))
const makeSelectGettingPlanRevision = () =>
  createSelector(selectPlanDomain, (substate) =>
    substate.get('gettingPlanRevision'),
  )
const makeSelectGetPlanRevisionError = () =>
  createSelector(selectPlanDomain, (substate) =>
    substate.get('getPlanRevisionError'),
  )

const makeSelectMovingPinToPlan = () =>
  createSelector(selectPlanDomain, (substate) =>
    substate.get('movingPinToPlan'),
  )
const makeSelectCurrentPlanId = () =>
  createSelector(selectPlanDomain, (substate) => substate?.get('currentPlanId'))

const makeSelectPinMoved = () =>
  createSelector(selectPlanDomain, (subState) => subState.get('pinMoved'))

const makeSelectMovePinToPlanError = () =>
  createSelector(selectPlanDomain, (substate) =>
    substate.get('movePinToPlanError'),
  )
const makeSelectGetPlanError = () =>
  createSelector(selectPlanDomain, (substate) => substate.get('getPlanError'))

const makeSelectMobilePinListSearch = () =>
  createSelector(selectPlanDomain, (substate) =>
    substate.get('mobilePinListSearch'),
  )

export {
  selectPlanDomain,
  makeSelectPlanRevision,
  makeSelectPlanRevisionId,
  makeSelectPlan,
  makeSelectGettingPlanRevision,
  makeSelectGetPlanRevisionError,
  makeSelectMovingPinToPlan,
  makeSelectCurrentPlanId,
  makeSelectMovePinToPlanError,
  makeSelectGetPlanError,
  makeSelectMobilePinListSearch,
  makeSelectPinMoved,
}
