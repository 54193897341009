import * as actionTypes from './constants'

export function showPopupOverlay() {
  return {
    type: actionTypes.SHOW_POPUP_OVERLAY,
  }
}
export function hidePopupOverlay() {
  return {
    type: actionTypes.HIDE_POPUP_OVERLAY,
  }
}
export function showMobilePopupOverlay() {
  return {
    type: actionTypes.SHOW_MOBILE_POPUP_OVERLAY,
  }
}
export function hideMobilePopupOverlay() {
  return {
    type: actionTypes.HIDE_MOBILE_POPUP_OVERLAY,
  }
}

export function toggleMenu() {
  return {
    type: actionTypes.TOGGLE_MENU,
  }
}

export function closeMenu() {
  return {
    type: actionTypes.CLOSE_MENU,
  }
}

export function resize(width, height) {
  return {
    type: actionTypes.RESIZE,
    width,
    height,
  }
}

export function hideGallery() {
  return {
    type: actionTypes.HIDE_GALLERY,
  }
}
export function switchGalleryIndex(index) {
  return {
    type: actionTypes.SWITCH_GALLERY_INDEX,
    index,
  }
}

export function updateDynamicLoadedScripts(key) {
  return {
    type: actionTypes.UPDATE_DYNAMIC_LOADED_SCRIPT,
    key,
  }
}

export function showBottomBar(status) {
  return {
    type: actionTypes.SHOW_BOTTOM_BAR,
    status,
  }
}
