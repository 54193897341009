import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Route, Router } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import * as Sentry from '@sentry/react'
import rollbarIntegration from './utils/rollbarIntegration'
import App from './containers/App'
import LanguageProvider from './containers/LanguageProvider'
import { getAsyncInjectors } from './utils/asyncInjectors'
import authenticationSagas from './containers/Authentication/sagas'
import registerSagas from './containers/Register/sagas'
import forgotPasswordSagas from './containers/ForgotPassword/sagas'
import * as languageSagas from './containers/LanguageProvider/sagas'
import { logout } from './containers/Authentication/actions'
import { changeLocale } from './containers/LanguageProvider/actions'
import configureStore from './store'
import GlobalStyle from './globalStyle'
import { appLocales } from './i18n'
import { CompatRouter } from 'react-router-dom-v5-compat'
import history from './utils/history'
import './global.css'
import FlagProvider from '@unleash/proxy-client-react'

const DEFAULT_LOCALE = 'en'

const getBrowserLanguage = () => {
  const lang = navigator.language || navigator.userLanguage
  const ln = lang.substring(0, 2)
  if (lang && appLocales.includes(ln)) {
    return ln
  }
  return null
}

const getLanguage = () => {
  if (typeof sessionStorage !== 'undefined') {
    if (sessionStorage && sessionStorage.language) {
      return sessionStorage.getItem('language')
    }
  }
  return null
}

const AppRoot = () => {
  rollbarIntegration()

  const initialState = {}
  const store = configureStore(initialState, history)
  const { injectSagas } = getAsyncInjectors(store)
  injectSagas(authenticationSagas)
  injectSagas(registerSagas)
  injectSagas(forgotPasswordSagas)
  injectSagas(languageSagas)

  if (typeof window !== 'undefined') {
    window.__STORE__ = store
  }

  const {
    VITE_UNLEASH_BASE_URL,
    VITE_UNLEASH_CLIENT_KEY
  } = import.meta.env

  const unleashConfig = {
    url: `${VITE_UNLEASH_BASE_URL}/api/frontend`,
    clientKey: VITE_UNLEASH_CLIENT_KEY,
    refreshInterval: 15,
    appName: 'web-app'
  }

  const browserLocale = getLanguage() || getBrowserLanguage() || DEFAULT_LOCALE

  if (browserLocale !== 'en') {
    store.dispatch(changeLocale(browserLocale))
  }

  useEffect(() => {
    const handleStorageEvent = (event) => {
      if (event.key === 'docuToolsLogout') {
        store.dispatch(logout())
      }
    }

    window.addEventListener('storage', handleStorageEvent, false)

    return () => {
      window.removeEventListener('storage', handleStorageEvent)
    }
  }, [])

  const SentryRoute = Sentry.withSentryRouting(Route)
  return (
    <>
      <Provider store={store}>
        <LanguageProvider>
          <DndProvider backend={HTML5Backend}>
            <Router history={history}>
              <CompatRouter>
                <React.StrictMode>
                  <FlagProvider config={unleashConfig}>
                    <SentryRoute path="*" component={App} />
                  </FlagProvider>
                </React.StrictMode>
              </CompatRouter>
            </Router>
          </DndProvider>
        </LanguageProvider>
      </Provider>
      <GlobalStyle />
    </>
  )
}

ReactDOM.createRoot(document.getElementById('root')).render(<AppRoot />)
