import styled from 'styled-components'
import { string } from 'prop-types'
import { prop } from 'styled-tools'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'

const Info = styled.svg`
  .a {
    fill: none;
  }
  .a,
  .b {
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
    stroke-width: 1.5px;
  }
  .b,
  .e {
    fill: none;
  }
  .b {
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .c {
    fill: ${prop('color', colorsOptions.greys['grey-100'])};
  }
  .d {
    stroke: none;
  }
  &:hover {
    .a,
    .b {
      stroke: ${prop('hoverColor', colorsOptions.greys['grey-100'])};
    }
    .c {
      fill: ${prop('hoverColor', colorsOptions.greys['grey-100'])};
    }
  }
`

function InfoIcon({ color, ...props }) {
  return (
    <Info
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      {...props}
    >
      <g transform="translate(1.5 1.5)">
        <g className="a">
          <circle className="d" cx="8" cy="8" r="8" />
          <circle className="e" cx="8" cy="8" r="8.75" />
        </g>
        <g transform="translate(-337.4 -788.949)">
          <path
            className="b"
            d="M2561,601h1.5v5.02"
            transform="translate(-2217 195.279)"
          />
          <path
            className="b"
            d="M2561,617h3"
            transform="translate(-2217 184.299)"
          />
          <circle
            className="c"
            cx="1.125"
            cy="1.125"
            r="1.125"
            transform="translate(344.1 791.949)"
          />
        </g>
      </g>
    </Info>
  )
}

InfoIcon.propTypes = {
  color: string,
}

export default InfoIcon
