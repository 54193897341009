/**
 * Combine ~~all reducers~~ a minimal subset of reducers to show the login screen
 * in this file and export the combined reducers.
 */
import { combineReducers } from 'redux-immutable'

import language from './containers/LanguageProvider/reducer'
import authentication from './containers/Authentication/reducer'
import app from './containers/App/reducer'
// import projectSelection from './containers/ProjectSelection/reducer'
import planSelection from './containers/PlanSelection/reducer'
// import quickSelect from './containers/QuickSelect/reducer'
// import projectSettings from './containers/ProjectSettings/reducer'
// import importReducer from './containers/Import/reducer'
// import importRevision from './containers/ImportRevision/reducer'
// import importStatus from './containers/ImportStatus/reducer'
// import taskList from './containers/TaskList/reducer'
// import manageUsers from './containers/ManageUsers/reducer'
// import teamManagementUsers from './containers/TeamManagementUsers/reducer'
// import datasets from './containers/Datasets/reducer'
// import datasetEdit from './containers/DatasetEdit/reducer'
// import search from './containers/Search/reducer'
// import media from './containers/Media/reducer'
// import filter from './containers/Filter/reducer'
// import groups from './containers/Groups/reducer'
// import group from './containers/Group/reducer'
// import planVersions from './containers/PlanVersions/reducer'
// import reports from './containers/Reports/reducer'
import register from './containers/Register/reducer'
import userSettings from './containers/UserSettings/reducer'
// import activateUser from './containers/ActivateUser/reducer'
import forgotPassword from './containers/ForgotPassword/reducer'
// import createProject from './containers/CreateProject/reducer'
// import planViewerNew from './containers/PlanViewerNew/reducer'
// import plan from './containers/Plan/reducer'
// import pinOptions from './containers/PinDetails/reducer'
// import folderSelectBox from './containers/FolderSelectBox/reducer'
// import notifications from './containers/NotificationMenu/reducer'
// import mobileTopBar from './containers/TopBar/reducer'

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    app,
    language,
    authentication,
    // projectSelection,
    planSelection,
    // quickSelect,
    // projectSettings,
    // import: importReducer,
    // importRevision,
    // importStatus,
    // taskList,
    // manageUsers,
    // teamManagementUsers,
    // datasets,
    // datasetEdit,
    // search,
    // media,
    // filter,
    // groups,
    // group,
    // planVersions,
    // reports,
    register,
    userSettings,
    // activateUser,
    forgotPassword,
    // createProject,
    // planViewerNew,
    // plan,
    // pinOptions,
    // folderSelectBox,
    // notifications,
    // mobileTopBar,
    ...asyncReducers,
  })
}
