import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'

const Svg = styled.svg`
  .pencil {
    fill: none;
    stroke: ${prop('color', colorsOptions.greys.white)};
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`

function EditMedia(props) {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
      <path
        className="pencil"
        d="M21.39 14.14l-3.18-3.18M13.61 21.92l9.2-9.19a2.17 2.17 0 0 0 0-2.83l-.71-.71a2.17 2.17 0 0 0-2.83 0l-9.19 9.2-1.42 4.95z"
      />
    </Svg>
  )
}

export default EditMedia
