import styled from 'styled-components'
import { Link } from 'react-router-dom'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'
import { weights } from '../stories/fundamentals/text-styles/text-styles'

/**
 * Orange link using react router dom Link component
 * @component
 */
export default styled(Link)`
  cursor: pointer;
  ${weights['text-500']};
  color: ${colorsOptions.docuToolsOrange.orange};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
