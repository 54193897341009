import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { IntlProvider } from 'react-intl'

import { makeSelectLocale, makeSelectMessages } from './selectors'

export const LanguageProvider = ({ locale, messages, children }) => (
  <IntlProvider locale={locale} messages={messages}>
    {React.Children.only(children)}
  </IntlProvider>
)

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
}

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
  messages: makeSelectMessages(),
})

export default connect(mapStateToProps)(LanguageProvider)
