export const LOGIN = 'app/Authentication/LOGIN'
export const LOGIN_SUCCESSFUL = 'app/Authentication/LOGIN_SUCCESSFUL'
export const LOGIN_ERROR = 'app/Authentication/LOGIN_ERROR'
export const LOGOUT = 'app/Authentication/LOGOUT'
export const SET_USER = 'app/Authentication/SET_USER'
export const UPDATE_USER = 'app/Authentication/UPDATE_USER'
export const SET_EMAIL = 'app/Authentication/SET_EMAIL'
export const SET_PASSWORD = 'app/Authentication/SET_PASSWORD'
export const GET_USER = 'app/Authentication/GET_USER'
export const GET_USER_DONE = 'app/Authentication/GET_USER_DONE'
export const SET_USER_ERROR = 'app/Authentication/SET_USER_ERROR'
export const SHOW_2FA = 'app/Authentication/SHOW_2FA'
export const SET_PASSWORD_POLICY = 'app/Authentication/SET_PASSWORD_POLICY'
export const SET_TWOFA_CODE = 'app/Authentication/SET_TWOFA_CODE'
export const TWOFA_LOGIN = 'app/Authentication/TWOFA_LOGIN'
export const BACK_TO_LOGIN = 'app/Authentication/BACK_TO_LOGIN'
export const VERIFY_EMAIL = 'app/Authentication/VERIFY_EMAIL'
export const VERIFY_EMAIL_DONE = 'app/Authentication/VERIFY_EMAIL_DONE'
export const VERIFY_EMAIL_WRONG_EMAIL =
  'app/Authentication/VERIFY_EMAIL_WRONG_EMAIL'
export const VERIFY_EMAIL_ERROR = 'app/Authentication/VERIFY_EMAIL_ERROR'
export const LOGIN_VIA_SAML = 'app/Authentication/LOGIN_VIA_SAML'
export const LOGIN_VIA_SAML_ERROR = 'app/Authentication/LOGIN_VIA_SAML_ERROR'
export const LOGIN_VIA_SAML_DONE = 'app/Authentication/LOGIN_VIA_SAML_DONE'
export const HIDE_MY_PROJECTS = 'app/Authentication/HIDE_MY_PROJECTS'
export const SHOW_MY_PROJECTS = 'app/Authentication/SHOW_MY_PROJECTS'
export const LOGIN_WITH_REDIRECT = 'app/Authentication/LOGIN_WITH_REDIRECT'
export const LOGIN_WITH_REDIRECT_SUCCESSFUL =
  'app/Authentication/LOGIN_WITH_REDIRECT_SUCCESSFUL'
export const EXPIRED_TOKEN = 'app/Authentication/EXPIRED_TOKEN'
export const SHOW_LOGIN_POPUP = 'app/Authentication/SHOW_LOGIN_POPUP'
export const GET_TOKEN_BY_REFRESH_TOKEN =
  'app/Authentication/GET_TOKEN_BY_REFRESH_TOKEN'
export const GET_TOKEN_BY_REFRESH_TOKEN_DONE =
  'app/Authentication/GET_TOKEN_BY_REFRESH_TOKEN_DONE'
export const GET_TOKEN_BY_REFRESH_TOKEN_ERROR =
  'app/Authentication/GET_TOKEN_BY_REFRESH_TOKEN_ERROR'

export const samlLoginLink = {
  development: 'https://dev.docu-tools.com/idpSelection',
  staging: 'https://dev.docu-tools.com/idpSelection',
  'production-ready': 'https://saml.docu.solutions',
  production: 'https://saml.docu.solutions',
  strabag: 'https://saml.docutools.strabag.io'
}
