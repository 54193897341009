import PropTypes from 'prop-types'
import styled from 'styled-components'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'

const Svg = styled.svg`
  enable-background: new 0 0 44 44;
  &:hover {
    polyline {
      stroke: ${({ hoverColor }) => hoverColor};
    }
  }
`
const PolyLine = styled.polyline`
  fill: none;
  stroke: ${({ color }) => color};
  stroke-width: 1.8;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
`

export const directions = {
  right: 'right',
  down: 'down',
  left: 'left',
  up: 'up',
}

/**
 * Arrow Icon,
 * can be to top, right, bottom, left
 */
function Arrow(props) {
  const {
    color = colorsOptions.greys['grey-100'],
    hoverColor = colorsOptions.greys['grey-100'],
    height = 44,
    width = 44,
    direction,
    className,
  } = props

  const polyLineProps = {
    color,
  }

  switch (direction) {
    case directions.down:
      polyLineProps.points = '30.2,17.9 22,26.1 13.8,17.9'
      break
    case directions.up:
      polyLineProps.points = '30.2,26.1 22,17.9 13.8,26.1'
      break
    case directions.right:
      polyLineProps.points = '17.9,30.2 26.1,22 17.9,13.8'
      break
    case directions.left:
    default:
      polyLineProps.points = '26.1,30.2 17.9,22 26.1,13.8'
  }

  return (
    <Svg
      version="1.1"
      className={className}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 44 44"
      height={height}
      hoverColor={hoverColor}
      width={width}
      color={color}
    >
      <PolyLine {...polyLineProps} />
    </Svg>
  )
}

Arrow.propTypes = {
  /** direction of the arrow */
  direction: PropTypes.oneOf(['right', 'down', 'left', 'up']),
  /** color of the arrow */
  color: PropTypes.string,
  /** color on hover on the arrow */
  hoverColor: PropTypes.string,
  /** height of the arrow surrounding box */
  height: PropTypes.number,
  /** width of the arrow surrounding box */
  width: PropTypes.number,
  /** className will be forwarded */
  className: PropTypes.string,
}

export default Arrow
