import styled from 'styled-components'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'

/**
 * @deprecated
 */
export default styled.div`
  position: fixed;
  background: ${colorsOptions.greys.white};
  top: 50%;
  left: 50%;
  z-index: 9999;
  padding: 0;
  max-width: 391.5px;
  width: 100%;
  transform: translate(-50%, -49%);
  border: 1px solid ${colorsOptions.greys['grey-30']};
  @media (max-width: 767px) {
    width: 89%;
  }
`

/**
 * @deprecated
 */
export const PopupLite = styled.div`
  position: absolute;
  background: ${colorsOptions.greys.white};
  z-index: 9999;
  padding: 0;
  border: 1px solid ${colorsOptions.greys['grey-30']};
`
