import { fromJS } from 'immutable'
import { detect } from 'detect-browser'
import * as actionTypes from './constants'
import { LOGOUT } from '../Authentication/constants'

const initialState = fromJS({
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  menuOpen: window.innerWidth > 1024,
  dynamicLoadedScripts: {},
  showBottomBar: true,
  browserInfo: detect(),
}).set('browserInfo', detect())

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_POPUP_OVERLAY:
      return state.set('showPopupOverlay', true)
    case actionTypes.HIDE_POPUP_OVERLAY:
      return state.set('showPopupOverlay', false)
    case actionTypes.SHOW_MOBILE_POPUP_OVERLAY:
      return state.set('showMobilePopupOverlay', true)
    case actionTypes.HIDE_MOBILE_POPUP_OVERLAY:
      return state.set('showMobilePopupOverlay', false)
    case actionTypes.TOGGLE_MENU:
      return state.set('menuOpen', !state.get('menuOpen'))
    case actionTypes.CLOSE_MENU:
      return state.set('menuOpen', false)
    case actionTypes.RESIZE:
      return state
        .set('windowWidth', action.width)
        .set('windowHeight', action.height)
    case actionTypes.UPDATE_DYNAMIC_LOADED_SCRIPT:
      return state.setIn(['dynamicLoadedScripts', action.key], true)
    case actionTypes.SHOW_BOTTOM_BAR:
      return state.set('showBottomBar', action.status)
    case LOGOUT:
      return state
        .set('windowWidth', window.innerWidth)
        .set('windowHeight', window.innerHeight)
        .set('menuOpen', window.innerWidth > 1024)
        .delete('showPopupOverlay')
        .delete('showMobilePopupOverlay')
    default:
      return state
  }
}

export default reducer
