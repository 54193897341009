import styled, { css } from 'styled-components'
import { prop, withProp, switchProp, ifNotProp, ifProp } from 'styled-tools'
import EditMedia from '../../../../components/Icons/EditMedia'
import Deactivate from '../../../../components/Icons/Deactivate'
import Activate from '../../../../components/Icons/Activate'

import colorsOptions from '../../../../components/stories/fundamentals/colors-styles/colors'

import {
  sizing,
  weights,
} from '../../../../components/stories/fundamentals/text-styles/text-styles'

export const ActivityWrapper = styled.div`
  background: ${colorsOptions.greys['grey-10']};
  display: flex;
  flex-direction: column;
  ${ifNotProp('nomarginbottom', 'margin-bottom: 16px;')}
`

export const HighlightedName = styled.span`
  ${sizing['text-r']};
  ${weights['text-700']};
`

export const HistoryDate = styled.div`
  color: ${colorsOptions.greys['grey-50']};
  ${sizing['text-r']};
`

export const PostActivity = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};

  &:last-child {
    border-bottom: none;
  }
  padding: 16px 24px 12px 8px;

  .creator {
    display: flex;
    align-items: center;

    .avatar {
      flex: 0 0 32px;
      border-radius: 50%;
      margin-right: 8px;
      overflow: hidden;
    }
    .name {
      flex: 1;
      ${sizing['text-r']};
      ${weights['text-500']};
      margin-bottom: 8px;
    }
  }

  .post {
    margin-left: 40px;
    border-radius: 9px;
    margin-bottom: 8px;
    background: ${colorsOptions.greys.white};
    border: 1px solid ${colorsOptions.greys['grey-20']};
    padding-top: 8px;
    padding-left: 8px;
    overflow: hidden;

    ${sizing['text-r']};
    ${weights['text-400']};

    .description {
      padding: 8px 16px 16px 8px;

      display: flex;

      .text {
        flex: 1;
        color: ${colorsOptions.greys['grey-100']};
      }

      .editIcon {
        opacity: 0;
      }

      &:hover {
        .editIcon {
          opacity: 1;
        }
      }
    }
  }

  .post.inactive {
    background-color: ${colorsOptions.greys['grey-10']};

    .text {
      color: ${colorsOptions.greys['grey-50']};
    }
  }

  .date {
    margin-left: 48px;
    color: ${colorsOptions.greys['grey-50']};
    ${sizing['text-s']};
    ${weights['text-500']};
  }
  .comment-date {
    ${weights['text-600']};
  }
`

export const CommentHeader = styled.div`
  justify-content: space-between;
  flex-wrap: wrap;
`

export const PostImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${withProp('count', (count) =>
    count === 1 ? 'justify-content: center;' : '',
  )}
`

export const ThumbnailWrapper = styled.div`
  cursor: ${ifNotProp('disabled', 'pointer', 'default')};
  position: relative;
  width: ${prop('size')}px;
  height: ${prop('size')}px;
  margin-right: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  ${withProp('count', (count) =>
    count > 1 ? 'flex: 0 0 calc(50% - 8px);' : '',
  )}
  ${switchProp(
    'count',
    {
      1: css`
        border-radius: 9px;
      `,
      2: css`
        &:first-child {
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
        }
        &:last-child {
          border-top-right-radius: 9px;
          border-bottom-right-radius: 9px;
        }
      `,
    },
    css`
      &:first-child {
        border-top-left-radius: 9px;
      }
      &:nth-child(2) {
        border-top-right-radius: 9px;
      }
      &:nth-child(3) {
        border-bottom-left-radius: 9px;
      }
      &:nth-child(4) {
        border-bottom-right-radius: 9px;
      }
    `,
  )}
  ${withProp('src', (src) => {
    if (src) {
      return css`
        background: url('${src}');
        background-size: cover;
        background-repeat: unset;
      `
    }
    return ''
  })}
`

export const PinHistoryRow = styled.div`
  ${sizing['text-r']};
  ${weights['text-400']};
  align-items: flex-start;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 8px;
  }

  .label {
    white-space: nowrap;
  }
  .value {
    margin-left: 8px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    ${weights['text-600']};

    .icon {
      margin-top: -4px;
      margin-left: -4px;
    }

    .colorBox {
      flex: 0 0 12px;
      margin-right: 8px;
      background: ${prop('color')};
      height: 12px;
      width: 12px;
    }

    .listElements {
      overflow: hidden;
      li {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`

export const LoadingWrapper = styled.div`
  padding: 16px;
`

export const EditMediaIcon = styled(EditMedia)`
  cursor: pointer;
  border-radius: 12px;
  flex: 0 0 24px;
  margin-top: -10px;
  margin-right: -10px;
  color: ${colorsOptions.greys['grey-50']};

  &:hover {
    background: ${colorsOptions.greys['grey-30']};
  }
`

export const DeactivateIcon = styled(Deactivate)`
  cursor: pointer;
  border-radius: 12px;
  flex: 0 0 24px;
  margin-top: -10px;
  margin-right: -10px;
  color: ${colorsOptions.greys['grey-50']};

  &:hover {
    background: ${colorsOptions.greys['grey-30']};
    color: ${colorsOptions.secondaryColors.red.normal};
  }
`

export const ActivateIcon = styled(Activate)`
  cursor: pointer;
  border-radius: 12px;
  flex: 0 0 24px;
  margin-top: -10px;
  margin-right: -10px;
  color: ${colorsOptions.greys['grey-100']};

  &:hover {
    background: ${colorsOptions.greys['grey-30']};
  }
`

export const ActivityFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ActivityFooterActionButtons = styled.div`
  margin-left: auto;

  & > svg {
    margin: 0;
  }
`
