import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import Page from '../../components/Page'
import PageTitle from '../../components/PageTitle'
import { CustomBtn } from '../../components/FormFields/index'

const RefreshButton = styled(CustomBtn)`
  width: initial;
`

const JavascriptErrorPage = () => {
  const location = useLocation()
  const {
    state: { error, previousPath },
  } = location
  return (
    <Page>
      <PageTitle wrapLine title={{ id: 'errorPage_heading' }} />
      {error && error.match(/Loading chunk.*failed./) ? (
        <p>
          <FormattedMessage id="errorPage_chunkInfo" />
          <div>
            <RefreshButton
              onClick={() =>
                location.replace(
                  previousPath
                    ? `${location.origin}${previousPath}`
                    : location.origin,
                )
              }
            >
              <FormattedMessage id="refreshPage" />
            </RefreshButton>
          </div>
        </p>
      ) : (
        <p>
          <FormattedMessage id="errorPage_info" />
        </p>
      )}
    </Page>
  )
}

export default JavascriptErrorPage
