export const LOAD_PLANS = 'app/PlanSelection/LOAD_PLANS'
export const PLANS_LOADED = 'app/PlanSelection/PLANS_LOADED'
export const ACTIVE_PLANS_LOADED = 'app/PlanSelection/ACTIVE_PLANS_LOADED'
export const PLANS_LOADING_ERROR = 'app/PlanSelection/PLANS_LOADING_ERROR'
export const FILTER_PLANS = 'app/PlanSelection/FILTER_PLANS'
export const CREATE_FOLDER = 'app/PlanSelection/CREATE_FOLDER'
export const FOLDER_CREATED = 'app/PlanSelection/FOLDER_CREATED'
export const CREATE_FOLDER_ERROR = 'app/PlanSelection/CREATE_FOLDER_ERROR'
export const CLEAR_CREATE_FOLDER = 'app/PlanSelection/CLEAR_CREATE_FOLDER'
export const PLAN_DRAG_N_DROP = 'app/PlanSelection/PLAN_DRAG_N_DROP'
export const FOLDER_DRAG_N_DROP = 'app/PlanSelection/FOLDER_DRAG_N_DROP'
export const MOVE_PLAN = 'app/PlanSelection/MOVE_PLAN'
export const PLAN_MOVED = 'app/PlanSelection/PLAN_MOVED'
export const MOVE_PLAN_ERROR = 'app/PlanSelection/MOVE_PLAN_ERROR'
export const CLEAR_MOVE_PLAN = 'app/PlanSelection/CLEAR_MOVE_PLAN'
export const MOVE_FOLDER = 'app/PlanSelection/MOVE_FOLDER'
export const FOLDER_MOVED = 'app/PlanSelection/FOLDER_MOVED'
export const MOVE_FOLDER_ERROR = 'app/PlanSelection/MOVE_FOLDER_ERROR'
export const CLEAR_MOVE_FOLDER = 'app/PlanSelection/CLEAR_MOVE_FOLDER'
export const SET_PROJECT_NAME = 'app/PlanSelection/SET_PROJECT_NAME'
export const CLEAR_PROJECT = 'app/PlanSelection/CLEAR_PROJECT'
export const SET_SORT_BY = 'app/PlanSelection/SET_SORT_BY'
export const SELECT_FOLDER_BATCH = 'app/PlanSelection/SELECT_FOLDER_BATCH'
export const DESELECT_FOLDER_BATCH = 'app/PlanSelection/DESELECT_FOLDER_BATCH'
export const DESELECT_ALL_FOLDER_BATCH =
  'app/PlanSelection/DESELECT_ALL_FOLDER_BATCH'
export const SELECT_PLAN_BATCH = 'app/PlanSelection/SELECT_PLAN_BATCH'
export const DESELECT_PLAN_BATCH = 'app/PlanSelection/DESELECT_PLAN_BATCH'
export const DESELECT_ALL_PLAN_BATCH =
  'app/PlanSelection/DESELECT_ALL_PLAN_BATCH'
export const MOVE_ITEMS_BATCH = 'app/PlanSelection/MOVE_ITEMS_BATCH'
export const CLEAR_MOVE_ITEMS_BATCH = 'app/PlanSelection/CLEAR_MOVE_ITEMS_BATCH'
export const ITEMS_BATCH_MOVED = 'app/PlanSelection/ITEMS_BATCH_MOVED'
export const MOVE_ITEMS_BATCH_ERROR = 'app/PlanSelection/MOVE_ITEMS_BATCH_ERROR'

export const SAVE_UNSYNCED_MEDIA_DONE =
  'app/PlanSelection/SAVE_UNSYNCED_MEDIA_DONE'

export const UPDATE_FOLDER = 'app/PlanSelection/UPDATE_FOLDER'
export const UPDATE_FOLDER_DONE = 'app/PlanSelection/UPDATE_FOLDER_DONE'
export const UPDATE_FOLDER_ERROR = 'app/PlanSelection/UPDATE_FOLDER_ERROR'

export const CHECK_CAN_CREATE_PLAN =
  'app/ProjectSelection/CHECK_CAN_CREATE_PLAN'
export const CHECK_CAN_CREATE_PLAN_DONE =
  'app/ProjectSelection/CHECK_CAN_CREATE_PLAN_DONE'
export const CHECK_CAN_CREATE_PLAN_ERROR =
  'app/ProjectSelection/CHECK_CAN_CREATE_PLAN_ERROR'

export const TOGGLE_SUBSCRIPTION = 'app/Authentication/TOGGLE_SUBSCRIPTION'
export const SUBSCRIBE_NOTIFICATION =
  'app/Authentication/SUBSCRIBE_NOTIFICATION'
export const UNSUBSCRIBE_NOTIFICATION =
  'app/Authentication/UNSUBSCRIBE_NOTIFICATION'
export const SUBSCRIBE_NOTIFICATION_DONE =
  'app/Authentication/SUBSCRIBE_NOTIFICATION_DONE'
export const SUBSCRIBE_NOTIFICATION_ERROR =
  'app/Authentication/SUBSCRIBE_NOTIFICATION_ERROR'

export const DELETE_EMPTY_FOLDER = 'app/PlanSelection/DELETE_EMPTY_FOLDER'
export const DELETE_EMPTY_FOLDER_DONE =
  'app/PlanSelection/DELETE_EMPTY_FOLDER_DONE'
export const DELETE_EMPTY_FOLDER_ERROR =
  'app/PlanSelection/DELETE_EMPTY_FOLDER_ERROR'

export const CLEAR_PLANS = 'app/PlanSelection/CLEAR_PLANS'
export const CLEAR_FOLDER_PLANS = 'app/PlanSelection/CLEAR_FOLDER_PLANS'
export const GET_ROOT_PLANS = 'app/PlanSelection/GET_ROOT_PLANS'
export const GET_ROOT_PLANS_DONE = 'app/PlanSelection/GET_ROOT_PLANS_DONE'
export const GET_ROOT_PLANS_ERROR = 'app/PlanSelection/GET_ROOT_PLANS_ERROR'
export const GET_ROOT_PLANS_WITH_FOLDERS =
  'app/PlanSelection/GET_ROOT_PLANS_WITH_FOLDERS'
export const GET_ROOT_PLANS_WITH_FOLDERS_DONE =
  'app/PlanSelection/GET_ROOT_PLANS_WITH_FOLDERS_DONE'
export const GET_ROOT_PLANS_WITH_FOLDERS_ERROR =
  'app/PlanSelection/GET_ROOT_PLANS_WITH_FOLDERS_ERROR'
export const GET_FOLDER_PLANS = 'app/PlanSelection/GET_FOLDER_PLANS'
export const GET_FOLDER_PLANS_DONE = 'app/PlanSelection/GET_FOLDER_PLANS_DONE'
export const GET_FOLDER_PLANS_ERROR = 'app/PlanSelection/GET_FOLDER_PLANS_ERROR'
export const OPEN_PLANS_FOLDER = 'app/PlanSelection/OPEN_PLANS_FOLDER'
export const CLOSE_PLANS_FOLDER = 'app/PlanSelection/CLOSE_PLANS_FOLDER'
export const SET_GET_PLANS_TREE_CANCEL_TOKEN =
  'app/PlanSelection/SET_GET_PLANS_TREE_CANCEL_TOKEN'

export const SET_PLAN_SORT = 'app/PlanSelection/SET_PLAN_SORT'

export const VIEW_TYPES = {
  list: 'list',
  tiles: 'tiles',
}
export const SORT_TYPES = {
  name: 'Title',
  size: 'Size',
  lastActivity: 'LastActivity',
  type: 'type',
  openTaskCount: 'openTaskCount',
  openPins: 'openPins',
}

export const PLAN_TYPES = {
  Plan: 'Plan',
}

export const ERROR_TYPES = {
  NEWER_CALL_CANCELED_PREVIOUS: 'NEWER_CALL_CANCELED_PREVIOUS',
}

export const CAN_DOWNLOAD_UNSYNCED = ['suster@pcd-zt.at']
export const REGEX_VALID_EMAILS = /(?!.*@)docu-tools.com$/
