import { createSelector } from 'reselect'

export const selectPinOptionsDomain = (state) => state.get('pinOptions')

export const makeSelectPin = () =>
  createSelector(selectPinOptionsDomain, (substate) => {
    if (substate?.get('pin')?.toJS) return substate.get('pin').toJS()
    return substate?.get('pin')
  })
export const makeSelectOldPin = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('oldPin')?.toJS()
  )
export const makeSelectGettingPin = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('gettingPin')
  )
export const makeSelectGetPinError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('getPinError')
  )

export const makeSelectLastPinTitles = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('lastPinTitles')
  )
export const makeSelectLastTaskTitles = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('lastTaskTitles')
  )

export const makeSelectSuggestedTags = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('suggestedTags')
  )

export const makeSelectTaskList = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('taskList') ? substate.get('taskList').toJS() : undefined
  )

export const makeSelectTaskListNrOfOpenTasks = () =>
  createSelector(
    selectPinOptionsDomain,
    (substate) =>
      substate.get('taskList')?.filter((task) => task.get('state') === 'Open')
        .size ?? 0
  )

export const makeSelectPinTags = () =>
  createSelector(selectPinOptionsDomain, (substate) => substate.get('pinTags'))

export const makeSelectPostList = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('postList') ? substate.get('postList').toJS() : undefined
  )

export const makeSelectGetPostListError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('getPostListError')
  )

export const makeSelectPostListVisuals = () =>
  createSelector(selectPinOptionsDomain, (substate) => {
    const postList = substate.get('postList')?.toJS()
    return Array.isArray(postList)
      ? postList.filter((post) => post.type === 'Visuals')
      : undefined
  })

export const makeSelectPostListVisualsFiles = () =>
  createSelector(
    makeSelectPin(),
    makeSelectPersistentTempPost(),
    makeSelectPostListVisuals(),
    makeSelectPendingSetToInactive(),
    makeSelectPendingSetToInactiveErrors(),
    makeSelectShowInactiveMedia(),
    makeSelectPendingSetToActive(),
    (
      pin,
      tempPosts = {},
      posts,
      pendingSetToInactive,
      pendingSetToInactiveErrors,
      showInactiveMedia,
      pendingSetToActive
    ) => {
      const tempDocumentPostsOfPin = (tempPosts[pin?.id] ?? []).filter(
        (post) => post.type === 'Visuals'
      )

      let allPosts = posts
      if (posts && tempDocumentPostsOfPin?.length) {
        allPosts = [...tempDocumentPostsOfPin, ...posts]
      }

      if (!allPosts) return undefined

      const visualFiles = []
      if (allPosts?.length) {
        const makeVisualFile = (file, id, tempId, updating, permissions) => {
          visualFiles.push({
            ...file,
            postId: id || tempId,
            postUpdating: updating || !!tempId,
            permissions,
            settingToInactive: pendingSetToInactive[file.id],
            setToInactiveError: pendingSetToInactiveErrors[file.id],
            settingToActive: pendingSetToActive[file.id]
          })
        }

        allPosts.forEach(
          ({
            id,
            updating,
            files,
            inactiveFiles = [],
            tempId,
            previews = [],
            permissions,
            active
          }) => {
            (files || previews).forEach((file) =>
              makeVisualFile(
                { ...file, active, postStatus: active },
                id,
                tempId,
                updating,
                permissions
              )
            )
            if (showInactiveMedia) {
              inactiveFiles.forEach((file) =>
                makeVisualFile(
                  { ...file, active: false, postStatus: active },
                  id,
                  tempId,
                  updating,
                  permissions
                )
              )
            }
          }
        )
      }
      return visualFiles
    }
  )

export const makeSelectPostListDocuments = () =>
  createSelector(selectPinOptionsDomain, (substate) => {
    const postList = substate.get('postList')?.toJS()
    if (Array.isArray(postList)) {
      return postList.filter((post) =>
        ['Audio', 'Documents'].includes(post.type)
      )
    }
    return undefined
  })

export const makeSelectPostMap = () =>
  createSelector(selectPinOptionsDomain, (substate) => {
    const postMap = {}
    const postList = substate.get('postList')
    if (postList) {
      postList.forEach((post) => {
        postMap[post.get('id')] = post.toJS()
      })
    }
    return postMap
  })

export const makeSelectPostListFiles = () =>
  createSelector(selectPinOptionsDomain, (substate) => {
    const files = {}
    const postList = substate.get('postList')
    if (postList) {
      postList.forEach((post) => {
        const postFiles = post.get('files')
        if (postFiles) {
          postFiles.forEach((file) => {
            files[file.get('id')] = file.set('postId', post.get('id')).toJS()
          })
        }
      })
    }
    return files
  })

export const makeSelectTaskListOptions = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('taskListOptions')
  )
export const makeSelectPostListOptions = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('postListOptions')
  )
export const makeSelectTaskListSortOptions = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('taskListSortOptions')
  )
export const makeSelectPostListSortOptions = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('postListSortOptions')
  )
export const makeSelectOpenPostId = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('openPostId')
  )
export const makeSelectOpenListPostId = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('openListPostId')
  )

export const getOpenPost = (substate) => {
  const openPostId = substate.get('openPostId')
  if (openPostId) {
    const taskList = substate.get('taskList')
    if (taskList) {
      const post = taskList.find((task) => task.get('id') === openPostId)
      if (post) {
        return post.toJS()
      }
    }
    const postList = substate.get('postList')
    if (postList) {
      const post = postList.find((p) => p.get('id') === openPostId)
      if (post) {
        return post.toJS()
      }
    }
  }
  return undefined
}
export const makeSelectOpenPost = () =>
  createSelector(selectPinOptionsDomain, (substate) => getOpenPost(substate))

export const makeSelectOpenPostFiles = () =>
  createSelector(selectPinOptionsDomain, (substate) => {
    const openPosts = getOpenPost(substate)
    const files = {}
    if (openPosts && Array.isArray(openPosts.files)) {
      openPosts.files.forEach((file) => {
        files[file.id] = { ...file, postId: openPosts.id }
      })
    }
    return files
  })

export const makeSelectCommentsOfPost = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('commentsOfPost')?.toJS()
  )

export const makeSelectCommentsOfPostFiles = () =>
  createSelector(selectPinOptionsDomain, (substate) => {
    const files = {}
    const openPostId = substate.get('openPostId')
    const commentsOfPost = substate.get('commentsOfPost')
    if (commentsOfPost) {
      commentsOfPost.forEach(({ attachments, id }) => {
        (attachments || []).forEach((file) => {
          files[file.id] = { ...file, postId: openPostId, commentId: id }
        })
      })
    }
    return files
  })

export const makeSelectCommentsOfPostMap = () =>
  createSelector(selectPinOptionsDomain, (substate) => {
    const commentsOfPost = substate.get('commentsOfPost')
    const commentMap = {}
    if (commentsOfPost) {
      commentsOfPost.forEach(({ id, text, createdBy, created }) => {
        commentMap[id] = { text, createdBy, created }
      })
    }
    return commentMap
  })

export const makeSelectCreatingCommentForPost = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('creatingCommentForPost')
  )

export const makeSelectUpdateCommentError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('updateCommentError')
  )

export const makeSelectFullScreen = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('fullScreen')
  )

export const makeSelectCompanies = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('companies')
  )
export const makeSelectAssignees = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('assignees')
  )
export const makeSelectAssigneesForSelect = () =>
  createSelector(selectPinOptionsDomain, (substate) => {
    const assignees = substate.get('assignees')
    return assignees?.map((assignee) => ({
      ...assignee,
      value: assignee.id,
      label: assignee.name
    }))
  })
export const makeSelectUsers = (type) =>
  createSelector(selectPinOptionsDomain, (substate) => {
    const assignees = substate.get('assignees')
    if (assignees && assignees.length) {
      return assignees.filter((assignee) => assignee.type === type)
    }
    return []
  })
export const makeSelectGettingAssignees = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('gettingAssignees')
  )

export const makeSelectProjectDependencySuggestions = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('projectDependencySuggestions')
  )
export const makeSelectIssueDependencySuggestions = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('issueDependencySuggestions')
  )

export const makeSelectCreatingTask = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('creatingTask')
  )
export const makeSelectCreateTaskError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('createTaskError')
  )

export const makeSelectUpdatingTask = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('updatingTask')
  )
export const makeSelectUpdateTaskError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('updateTaskError')
  )
export const makeSelectGettingTask = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('gettingTaskList')
  )
export const makeSelectTaskListCurrentPage = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('taskListCurrentPage')
  )
export const makeSelectTaskListTotalPages = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('taskListTotalPages')
  )

export const makeSelectCreatingPost = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('creatingPost')
  )
export const makeSelectCreatePostError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('createPostError')
  )

export const makeSelectUpdatingPost = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('updatingPost')
  )
export const makeSelectUpdatingPostIds = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('updatingPostIds')
  )
export const makeSelectUpdatePostError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('updatePostError')
  )

export const makeSelectProjectRecentNotes = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('projectRecentNotes')
  )

export const makeSelectDatasets = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('datasets')?.toJS()
  )
export const makeSelectGetDatasetError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('getDatasetError')
  )
export const makeSelectDatasetsRecords = () =>
  createSelector(
    selectPinOptionsDomain,
    (substate) => substate.get('datasetsRecords')?.toJS() ?? {}
  )
export const makeSelectGettingDatasetRecordsPage = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('gettingDatasetRecordsPage')
  )
export const makeSelectGetDatasetRecordsPageError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('getDatasetRecordsPageError')
  )

export const makeSelectUpdatingPin = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('updatingPin')
  )
export const makeSelectUpdatePinError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('updatePinError')
  )

export const makeSelectGettingAllDatasets = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('gettingAllDatasets')
  )

export const makeSelectGetAllDatasetsError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('getAllDatasetsError')
  )

export const makeSelectMovingPinPosition = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('movingPinPosition')
  )
export const makeSelectCopyingPin = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('copyingPin')
  )

export const makeSelectGetIssueHistoryError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('getIssueHistoryError')
  )
export const makeSelectHistory = () =>
  createSelector(selectPinOptionsDomain, (substate) => substate.get('history'))
export const makeSelectGetIssuePropertyHistoryError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('getIssuePropertyHistoryError')
  )
export const makeSelectPropertyHistory = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('propertyHistory')
  )

export const makeSelectGetPostHistoryError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('getPostHistoryError')
  )
export const makeSelectPostHistory = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('postHistory')
  )

export const makeSelectGettingAllHistories = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('gettingAllHistories')
  )
export const makeSelectGetAllHistoriesError = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('getAllHistoriesError')
  )
export const makeSelectAllHistories = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('allHistories')
  )

export const makeSelectPersistentTempPost = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('persistentTempPost')?.toJS()
  )

export const makeSelectPersistentTempTask = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('persistentTempTask')?.toJS()
  )

export const makeSelectShowInactiveMedia = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('showInactiveMedia')
  )

export const makeSelectPendingSetToInactive = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('pendingSetToInactive')?.toJS()
  )

export const makeSelectPendingSetToInactiveErrors = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('pendingSetToInactiveErrors')?.toJS()
  )

export const makeSelectPendingSetToActive = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('pendingSetToActive')?.toJS()
  )

export const makeSelectExtraPinPanToTop = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('extraPinPanToTop')
  )

export const makeSelectGettingNrOfOpenTasks = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('gettingNrOfOpenTasks')
  )
export const makeSelectGettingPinTags = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('gettingPinTags')
  )

export const makeSelectNrOfOpenTasks = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('nrOfOpenTasks')
  )

export const makeSelectPinFields = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('pinFields')
  )

export const makeSelectGettingTagsSearchSuggestions = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('gettingTagsSearchSuggestions')
  )

export const makeSelectTagsSearchSuggestions = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('tagsSearchSuggestions')
  )

export const makeSelectGettingTagsSuggestions = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('gettingTagsSuggestions')
  )

export const makeSelectTagsSuggestions = () =>
  createSelector(selectPinOptionsDomain, (substate) =>
    substate.get('tagsSuggestions')
  )

export const makeSelectUserRole = () =>
  createSelector(selectPinOptionsDomain, (substate) => substate.get('userRole'))

export const makeSelectModalCameraNoFoundIsOpen = () =>
  createSelector(selectPinOptionsDomain, (substate) => substate.get('isOpenCameraNotFound'))
