import { createSelector } from 'reselect'

const selectAuthenticationDomain = (state) => state.get('authentication')

const makeSelectTokenAvailable = () =>
  createSelector(
    selectAuthenticationDomain,
    (substate) => !!substate.get('token')
  )

const makeSelectGettingUser = () =>
  createSelector(selectAuthenticationDomain, (subState) =>
    subState.get('gettingUser')
  )
const makeSelectloggingIn = () =>
  createSelector(selectAuthenticationDomain, (subState) =>
    subState.get('loggingIn')
  )

const makeSelectUserAvailable = () =>
  createSelector(
    selectAuthenticationDomain,
    (substate) => !!substate.get('user')
  )

const makeSelectPrivacyPolicyAccepted = () =>
  createSelector(
    selectAuthenticationDomain,
    (substate) => substate.getIn(['user', 'privacyPolicyAccepted']) ?? false
  )
const makeSelectTermsAndConditionsAccepted = () =>
  createSelector(
    selectAuthenticationDomain,
    (substate) =>
      substate.getIn(['user', 'termsAndConditionsAccepted']) ?? false
  )

const makeSelectLoggedIn = () =>
  createSelector(
    selectAuthenticationDomain,
    (substate) => !!substate.get('token') && !!substate.get('user')
  )

const makeSelectHeaders = (predefinedToken) =>
  createSelector(selectAuthenticationDomain, (substate) => {
    const token = predefinedToken ?? substate.get('token')?.toJS()
    const header = {
      ...(token
        ? { Authorization: `${token.token_type} ${token.access_token}` }
        : {})
    }
    return header
  })

const makeSelectAccessToken = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['token', 'access_token'])
  )

const makeSelectAccessSearchParam = (notFirst) =>
  createSelector(selectAuthenticationDomain, (substate) => {
    let searchParam = ''
    const accessToken = substate.getIn(['token', 'access_token'])
    if (accessToken) {
      searchParam = `${!notFirst ? '?' : '&'}access_token=${accessToken}`
    }
    return searchParam
  })

const makeSelectToken = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['token', 'access_token'])
  )

const makeSelectRefreshToken = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['token', 'refresh_token'])
  )

const makeSelectUser = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('user')?.toJS()
  )

const makeSelectOrganisationId = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['user', 'organisationId'])
  )

const makeSelectPermissions = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['user', 'permissions'])?.toJS()
  )

const makeSelectIsOwner = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['user', 'owner'])
  )

const makeSelectIsAdmin = () =>
  createSelector(selectAuthenticationDomain, (substate) => {
    const user = {
      settings: {
        admin: false
      },
      ...substate.get('user')?.toJS()
    }
    return user.settings.admin
  })

const makeSelectNoLicenseMessage = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['user', 'noLicenseMessage'])
  )

const makeSelectTimezone = () =>
  createSelector(
    selectAuthenticationDomain,
    (substate) => substate.getIn(['user', 'settings', 'timeZone']) ?? null
  )

const makeSelectUserId = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['user', 'userId'])
  )

const makeSelectOrganisationName = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['user', 'organisationName'])
  )

const makeSelectEmail = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('email')
  )

const makeSelectUserType = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('userType')
  )

const makeSelectUsername = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['user', 'username'])
  )

const makeSelectFullName = () =>
  createSelector(selectAuthenticationDomain, (substate) => {
    const { firstName, lastName } = substate.get('user')?.toJS() || {}

    return `${firstName} ${lastName}`
  })

const makeSelectPassword = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('password')
  )

const makeSelectLoginError = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('loginError')
  )

const makeSelectShow2FA = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('show2FA')
  )

const makeSelectTwoFaCode = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('twoFaCode')
  )

const makeSelectCurrentOccurred = () =>
  createSelector(selectAuthenticationDomain, () => {
    const occurred = new Date()
    return occurred.toISOString()
  })

const makeSelectLicense = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['user', 'license'])?.toJS()
  )

const makeSelectEmailVerifying = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('emailVerifying')
  )
const makeSelectEmailVerified = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('emailVerified')
  )
const makeSelectVerifyEmailWrong = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('verifyEmailWrong')
  )
const makeSelectLoginViaSamlError = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('loginViaSamlError')
  )
const makeSelectLoggingInViaSaml = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('loggingInViaSaml')
  )
const makeSelectHideMyProjects = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('hideMyProjects')
  )

const makeSelectRedirectToken = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('redirect_token')
  )

const makeSelectExpiredToken = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('expiredToken')
  )
const makeSelectShowLoginPopup = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('showLoginPopup')
  )

const makeSelectPasswordPolicy = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('passwordPolicy')
  )

const makeSelectServiceFlags = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.get('serviceFlags')
  )

const makeSelectUserTierName = () =>
  createSelector(selectAuthenticationDomain, (substate) =>
    substate.getIn(['user', 'tier', 'name'])
  )
export {
  selectAuthenticationDomain,
  makeSelectLoggedIn,
  makeSelectTokenAvailable,
  makeSelectUserAvailable,
  makeSelectHeaders,
  makeSelectToken,
  makeSelectUser,
  makeSelectEmail,
  makeSelectFullName,
  makeSelectPassword,
  makeSelectLoginError,
  makeSelectShow2FA,
  makeSelectTwoFaCode,
  makeSelectOrganisationId,
  makeSelectCurrentOccurred,
  makeSelectAccessToken,
  makeSelectAccessSearchParam,
  makeSelectUsername,
  makeSelectEmailVerifying,
  makeSelectEmailVerified,
  makeSelectVerifyEmailWrong,
  makeSelectUserId,
  makeSelectOrganisationName,
  makeSelectPermissions,
  makeSelectLicense,
  makeSelectLoginViaSamlError,
  makeSelectLoggingInViaSaml,
  makeSelectNoLicenseMessage,
  makeSelectTimezone,
  makeSelectIsOwner,
  makeSelectIsAdmin,
  makeSelectPrivacyPolicyAccepted,
  makeSelectTermsAndConditionsAccepted,
  makeSelectHideMyProjects,
  makeSelectExpiredToken,
  makeSelectRefreshToken,
  makeSelectShowLoginPopup,
  makeSelectRedirectToken,
  makeSelectPasswordPolicy,
  makeSelectGettingUser,
  makeSelectloggingIn,
  makeSelectUserType,
  makeSelectServiceFlags,
  makeSelectUserTierName
}
