/* eslint-disable max-len */
import { number, string } from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../../../components/stories/fundamentals/colors-styles/colors'

export const SliderSvg = styled.svg`
  width: ${prop('size', 16)}px;
  height: ${prop('size', 16)}px;
  .a,
  .b {
    fill: none;
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
  }
  .b {
    stroke-linecap: round;
  }
  .c {
    fill: #fff;
  }
  .d,
  .e {
    stroke: none;
  }
  .e {
    fill: ${prop('color', colorsOptions.greys['grey-100'])};
  }
`

function SliderIcon({ color, size }) {
  return (
    <SliderSvg
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      color={color}
      viewBox="0 0 17 17"
    >
      <g transform="translate(0.5 0.5)">
        <path
          className="a"
          d="M2,0H14a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
        />
        <line className="b" x2="12" transform="translate(1.985 7.985)" />
        <g className="c" transform="translate(3.985 4.985)">
          <path
            className="d"
            d="M 3 5.5 C 1.621500015258789 5.5 0.5 4.378499984741211 0.5 3 C 0.5 1.621500015258789 1.621500015258789 0.5 3 0.5 C 4.378499984741211 0.5 5.5 1.621500015258789 5.5 3 C 5.5 4.378499984741211 4.378499984741211 5.5 3 5.5 Z"
          />
          <path
            className="e"
            d="M 3 1 C 1.897200107574463 1 1 1.897200107574463 1 3 C 1 4.102799892425537 1.897200107574463 5 3 5 C 4.102799892425537 5 5 4.102799892425537 5 3 C 5 1.897200107574463 4.102799892425537 1 3 1 M 3 0 C 4.65684986114502 0 6 1.34315013885498 6 3 C 6 4.65684986114502 4.65684986114502 6 3 6 C 1.34315013885498 6 0 4.65684986114502 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z"
          />
        </g>
      </g>
    </SliderSvg>
  )
}

SliderIcon.propTypes = {
  color: string,
  size: number,
}
export default SliderIcon
