import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { weights } from '../stories/fundamentals/text-styles/text-styles'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'

/**
 * Description for input form
 * @component
 */
export default styled.p`
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
  margin-bottom: 10px;
  ${ifProp('center', 'text-align: center;')}
`
