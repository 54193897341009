import { createSelector } from 'reselect'

export const selectRegisterDomain = (state) => state.get('register')

export const makeSelectFirstName = () =>
  createSelector(selectRegisterDomain, (substate) => substate.get('firstName'))

export const makeSelectLastName = () =>
  createSelector(selectRegisterDomain, (substate) => substate.get('lastName'))

export const makeSelectEmail = () =>
  createSelector(selectRegisterDomain, (substate) => substate.get('email'))

export const makeSelectOrganisation = () =>
  createSelector(selectRegisterDomain, (substate) =>
    substate.get('organisation'),
  )

export const makeSelectCountry = () =>
  createSelector(selectRegisterDomain, (substate) => substate.get('country'))
export const makeSelectOriginationCode = () =>
  createSelector(selectRegisterDomain, (subState) =>
    subState.get('originationCode'),
  )

export const makeSelectRegisterUserError = () =>
  createSelector(selectRegisterDomain, (substate) =>
    substate.get('registerUserError'),
  )

export const makeSelectRegisterUser = () =>
  createSelector(selectRegisterDomain, (substate) =>
    substate.get('registerUser'),
  )

export const makeSelectUserRegistered = () =>
  createSelector(selectRegisterDomain, (substate) =>
    substate.get('userRegistered'),
  )

export const makeSelectEmailTaken = () =>
  createSelector(selectRegisterDomain, (substate) => substate.get('emailTaken'))

export const makeSelectPhone = () =>
  createSelector(selectRegisterDomain, (substate) => substate.get('phone'))

export const makeSelectCountryDetail = () =>
  createSelector(selectRegisterDomain, (substate) =>
    substate?.get('countryDetail'),
  )
export const makeSelectGettingCountry = () =>
  createSelector(selectRegisterDomain, (substate) =>
    substate.get('gettingCountry'),
  )
export const makeSelectGetCountryError = () =>
  createSelector(selectRegisterDomain, (substate) =>
    substate.get('getCountryError'),
  )
