import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeSelectWindowHeight } from '../App/selectors'
import Popup from '../../components/PopupNew'
import { Iframe } from './styledComponents'
import { makeSelectLocale } from '../LanguageProvider/selectors'
import config from '../../../proxyConfig'
import { makeSelectUser } from '../Authentication/selectors'

const UpgradePlanPopup = (props) => {
  const { windowHeight, user, close, locale } = props

  const license = user?.tier?.name?.toLowerCase() ?? ''

  return (
    <Popup
      closePopup={close}
      title="&nbsp;"
      size="LARGE"
      rounded={true}
      trueCenter={true}
      windowHeight={windowHeight}
      showCloseTopRight
    >
      <Iframe src={`${config.pricingTable}?lang=${locale}&tool=${license}`} />
    </Popup>
  )
}

UpgradePlanPopup.propTypes = {
  tier: PropTypes.object.isRequired,
  windowHeight: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
  windowHeight: makeSelectWindowHeight(),
  locale: makeSelectLocale(),
  user: makeSelectUser()
})

export default connect(mapStateToProps)(injectIntl(UpgradePlanPopup))
