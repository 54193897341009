import styled from 'styled-components'

const Svg = styled.svg`
  & path,
  & g,
  & g circle {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`

const Deactivate = (props) => (
  <Svg viewBox="0 0 32 32" {...props}>
    <path
      d="M72,172.55v7.438H83.379V171"
      transform="translate(-61.5 -157.989)"
    />
    <g transform="translate(15 15.277)">
      <circle cx="1.12" cy="1.12" r="1.12" />
      <circle cx="1.12" cy="1.12" r="1.52" />
    </g>
    <path
      d="M1,0H12a1,1,0,0,1,1,1V3.333a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z"
      transform="translate(9.139 9.916) rotate(-8)"
    />
  </Svg>
)

export default Deactivate
