import styled from 'styled-components'
import PropTypes from 'prop-types'
import { sizing } from '../stories/fundamentals/text-styles/text-styles'

const FloatingLabelTwo = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  ${sizing['text-s']};
`

FloatingLabelTwo.propTypes = {
  show: PropTypes.bool,
}

/**
 * A litte different floating label for input field
 * When hidden this label doesnt retain the space it covered when shown
 * @component
 */
export default FloatingLabelTwo
