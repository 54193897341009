const production = {
  url: 'https://api.docu-tools.com',
  reports: 'https://api.docu-tools.com',
  pricingTable: 'https://app.docu-tools.com/inapp-pricing-table/index.html',

  planImport: {
    url: 'https://tw55hj7od9.execute-api.eu-central-1.amazonaws.com',
    env: '/production',
    version: '/v1'
  },
  pdf2jpegBucket: 'docutools-prod-temporary',
  planCompareBucket: 'docutools-prod-plans',
  fcmSubscription: {
    url: 'https://iid.googleapis.com',
    prefix: '/iid/v1',
    suffix: '/rel/topics'
  },
  e2eTestUrl: 'https://cloud.docu.solutions',
  clientId: import.meta.env?.VITE_CLIENT_ID ?? null,
  clientSecret: import.meta.env?.VITE_CLIENT_SECRET ?? null
}

export default production
