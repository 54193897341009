/* eslint-disable max-len */
import { number, string } from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../../../components/stories/fundamentals/colors-styles/colors'

export const TimeSvg = styled.svg`
  height: ${prop('size', 17)}px;
  width: ${prop('size', 17)}px;
  .a {
    fill: none;
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
  }
  .b {
    fill: ${prop('color', colorsOptions.greys['grey-100'])};
  }
`

function TimeIcon({ color, size }) {
  return (
    <TimeSvg
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      size={size}
      viewBox="0 0 17 17"
    >
      <g transform="translate(0.5 0.5)">
        <rect className="a" width="16" height="16" rx="2" />
        <g transform="translate(2 2)">
          <g transform="translate(1.246 0.5)">
            <path
              className="b"
              d="M13.438,5.275l.6-.625a.475.475,0,0,0,0-.7.45.45,0,0,0-.35-.15.5.5,0,0,0-.35.15l-.625.6a4.775,4.775,0,0,0-2.475-1.025V3h.5a.5.5,0,0,0,0-1h-2a.5.5,0,0,0,0,1h.5v.525a4.75,4.75,0,1,0,4.2,1.75ZM9.738,12a3.75,3.75,0,1,1,3.75-3.75A3.75,3.75,0,0,1,9.738,12Z"
              transform="translate(-4.984 -2)"
            />
            <path
              className="b"
              d="M24.75,18.25H23V16.5a.5.5,0,0,0-1,0v2.75h2.75a.5.5,0,0,0,0-1Z"
              transform="translate(-17.746 -12.5)"
            />
          </g>
        </g>
      </g>
    </TimeSvg>
  )
}

TimeIcon.propTypes = {
  color: string,
  size: number,
}

export default TimeIcon
