import styled, { css } from 'styled-components'
import { ifNotProp, ifProp, prop } from 'styled-tools'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'

/**
 * @component
 */
export default styled.div`
  background: ${colorsOptions.greys.white};
  margin: 0 auto;
  max-width: ${prop('width', '350px')};
  width: 100%;
  padding: 0 6px;
  ${ifNotProp(
    'isInPopup',
    css`
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `,
  )}
  @media(max-width: 767px) {
    position: inherit;
    padding-top: 80px;
    padding-bottom: 30px;
    transform: none;
    top: auto;
    left: auto;
  }
  ${ifProp(
    'maxHeight',
    css`
      @media (max-height: ${prop('maxHeight') - 15}px, min-width: 767px) {
        transform: translate(-50%, 0);
        top: 15px;
      }
    `,
  )}
`
