import React from 'react'
import { FormattedMessage } from 'react-intl'
import LogoutButton from './LogoutButton'

export default () => (
  <div>
    <div>
      <FormattedMessage id="registerform_logoutRequired" />:
      <LogoutButton>
        <FormattedMessage id="registerform_logout" />
      </LogoutButton>
    </div>
  </div>
)
