import { InlineLoader, Loader, NoBgLoaderWrapper } from '../PageLoader'
import PopupNew, { popupSize } from '../PopupNew'

export const PageLoading = (
  <NoBgLoaderWrapper className="noscroller">
    <Loader className="loader" />
  </NoBgLoaderWrapper>
)

export const PopupLoading = (
  <PopupNew
    title=""
    size={popupSize.SMALL}
    contentText={<InlineLoader />}
    noFooter
    overPopup
  />
)

export const ComponentLoading = <InlineLoader />
