import { fromJS } from 'immutable'
import * as actionTypes from './constants'
import { CHANGE_LOCALE } from '../LanguageProvider/constants'
import { LOGOUT } from '../Authentication/constants'

const initialState = fromJS({})

function userSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_FIELDS:
      return state.set('savingFields', true).delete('savingFieldsError')
    case actionTypes.FIELDS_SAVED:
      return state.set('savingFields', false)
    case actionTypes.FIELDS_SAVE_ERROR:
      return state
        .set('savingFields', false)
        .set('savingFieldsError', action.error)
    case CHANGE_LOCALE:
      return state.set('savingLanguage', true)
    case actionTypes.CHANGE_LOCALE_FINISHED:
      return state.set('savingLanguage', false)
    case actionTypes.CHANGE_LOCALE_ERROR:
      return state
        .set('savingLanguage', false)
        .set('savingLanguageError', action.error)
    case actionTypes.UPLOAD_PROFILE_PICTURE:
      return state.set('profilePictureUploaded', false)
    case actionTypes.PROFILE_PICTURE_UPLOADED:
      return state.set('profilePictureUploaded', true)
    case actionTypes.PROFILE_PICTURE_UPLOAD_ERROR:
      return state
        .set('profilePictureUploaded', false)
        .set('profilePictureUploadError', action.error)
    case actionTypes.SAVE_PASSWORD:
      return state.set('savingPassword', true).set('savingPasswordError', null)
    case actionTypes.PASSWORD_SAVED:
      return state.set('savingPassword', false).set('passwordSaved', true)
    case actionTypes.PASSWORD_SAVE_ERROR:
      return state
        .set('savingPassword', false)
        .set('savingPasswordError', action.error)
    case actionTypes.GET_PASSWORD_POLICY_DONE:
      return state.set('passwordPolicy', action.policy)
    case actionTypes.ACTIVATE_TWO_FA:
    case actionTypes.DEACTIVATE_TWO_FA:
      return state.set('disableTwoFaCheckbox', true).set('twoFaSaved', false)
    case actionTypes.SHOW_QR:
      return state
        .set('disableTwoFaCheckbox', false)
        .set('twoFaSaved', true)
        .set('qrOpen', true)
    case actionTypes.OPEN_QR:
      return state.set('qrOpen', true)
    case actionTypes.DEACTIVATE_TWO_FA_SUCCESSFUL:
      return state.set('disableTwoFaCheckbox', false).set('twoFaSaved', true)
    case actionTypes.TWO_FA_ERROR:
      return state
        .set('disableTwoFaCheckbox', false)
        .set('twoFaError', action.error)
    case actionTypes.GET_TWO_FA_STATUS_DONE:
      return state
        .set('twoFaStatus', action.status)
        .set('twoFaError', action.error)
    case actionTypes.HIDE_QR:
      return state.set('qrOpen', false)
    case actionTypes.SHOW_VERIFY_TWO_FA_SMS:
      return state
        .set('showVerify2faSms', action.status)
        .set('twoFaSmsSaved', false)
        .set('activating2FaSMS', false)
        .delete('twoFaSmsError')
    case actionTypes.VERIFY_TWO_FA_SMS:
      return state.set('verifying2faSms', true).set('twoFaSmsSaved', false)
    case actionTypes.VERIFY_TWO_FA_SMS_DONE:
      return state
        .set('twoFaSmsSaved', true)
        .set('showVerify2faSms', false)
        .set('verifying2faSms', false)
    case actionTypes.DEACTIVATE_TWO_FA_SMS:
      return state.set('twoFaSmsSaved', false)
    case actionTypes.DEACTIVATE_TWO_FA_SMS_DONE:
      return state.set('twoFaSmsSaved', true)
    case actionTypes.TWO_FA_SMS_ERROR:
      return state
        .set('verifying2faSms', false)
        .set('twoFaSmsError', action.error)
        .set('activating2FaSMS', false)
    case actionTypes.SAVE_EMAIL:
      return state.set('savingEmail', true).set('savingEmailSuccess', false)
    case actionTypes.SAVE_EMAIL_ERROR:
      return state
        .set('savingEmail', false)
        .set('savingEmailError', action.error)
        .set('savingEmailSuccess', false)
    case actionTypes.SAVE_EMAIL_SUCCESS:
      return state
        .set('savingEmail', false)
        .set('savingEmailError', null)
        .set('savingEmailSuccess', true)
    case actionTypes.ACTIVATE_TWO_FA_SMS:
      return state.set('activating2FaSMS', true)
    case actionTypes.GET_LIMIT_DEVICES_LIST:
      return state
        .delete('getLimitDevicesListError')
        .set('gettingLimitDevices', true)
    case actionTypes.GET_LIMIT_DEVICES_LIST_DONE:
      return state
        .set('limitDevicesList', action.devices)
        .set('deviceLimit', action.deviceLimit)
        .set('gettingLimitDevices', false)
    case actionTypes.GET_LIMIT_DEVICES_LIST_ERROR:
      return state
        .set('getLimitDevicesListError', action.error)
        .set('gettingLimitDevices', false)
    case actionTypes.CLEAR_UNREGISTER_DEVICE:
      return state
        .delete('unregisterDeviceError')
        .delete('unregisterDeviceInProgress')
    case actionTypes.UNREGISTER_DEVICE:
      return state
        .delete('unregisterDeviceError')
        .set('unregisterDeviceInProgress', true)
    case actionTypes.UNREGISTER_DEVICE_DONE:
      return state
        .set('unregisterDeviceInProgress', false)
        .delete('limitDevicesList')
    case actionTypes.UNREGISTER_DEVICE_ERROR:
      return state
        .set('unregisterDeviceError', action.error)
        .set('unregisterDeviceInProgress', false)
    case LOGOUT:
      return fromJS({})
    default:
      return state
  }
}

export default userSettingsReducer
