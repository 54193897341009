export const serializeQueryString = (queryObject) => {
  const usp = new URLSearchParams(Object.entries(queryObject))
  const queryString = usp.toString()
  return queryString.length ? `?${queryString}` : ''
}

export const deserializeQueryString = (queryString) => {
  const queryObject = {}
  const usp = new URLSearchParams(queryString)
  usp.forEach((value, key) => {
    queryObject[key] = value
  })
  return queryObject
}

export const imageExists = async (imageUrl) => {
  const { status } = await fetch(imageUrl, { method: 'get' })
  return status === 200
}

export const download = (filename, url) => {
  const element = document.createElement('a')
  element.setAttribute('href', url)
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const getUrl = (urlString) => {
  const url = new URL(urlString)
  return url.pathname
}
