export const SET_UNAVAILABLE_FEATURE_SLUG = 'app/FeatureAvailability/SET_UNAVAILABLE_FEATURE_SLUG'
export const GET_UNAVAILABLE_FEATURE_SLUG = 'app/FeatureAvailability/GET_UNAVAILABLE_FEATURE_SLUG'
export const GET_FEATURE_LABEL_IDS = 'app/FeatureAvailability/GET_FEATURE_LABEL_IDS'
export const SET_FEATURE_LABEL_IDS = 'app/FeatureAvailability/SET_FEATURE_LABEL_IDS'
export const GET_FEATURE_INFO_BY_PROJECT = 'app/FeatureAvailability/GET_FEATURE_INFO_BY_PROJECT'
export const GET_FEATURE_INFO_BY_PROJECT_DONE = 'app/FeatureAvailability/GET_FEATURE_INFO_BY_PROJECT_DONE'
export const GET_FEATURE_INFO_BY_PROJECT_ERROR = 'app/FeatureAvailability/GET_FEATURE_INFO_BY_PROJECT_ERROR'
export const GET_FEATURE_INFO_BY_ORGANISATION = 'app/FeatureAvailability/GET_FEATURE_INFO_BY_ORGANISATION'
export const GET_FEATURE_INFO_BY_ORGANISATION_DONE = 'app/FeatureAvailability/GET_FEATURE_INFO_BY_ORGANISATION_DONE'
export const GET_FEATURE_INFO_BY_ORGANISATION_ERROR = 'app/FeatureAvailability/GET_FEATURE_INFO_BY_ORGANISATION_ERROR'

export const features = [
  'BULK_EDIT',
  'COPY_PROJECT',
  'DATASETS',
  'GPS',
  'ORGANIZATION_NAMES',
  'PIN_FIELDS',
  'QR_CODE',
  'REPORT_TEMPLATE',
  'SUPERMAN_ROLE',
  'UNLIMITED_REPORT_STORAGE',
  'PLAN_EXPORT'
]

export const organisationFeatures = [
  'REPORT_TEMPLATE',
  'COPY_PROJECT',
  'SUPERMAN_ROLE',
  'ORGANIZATION_NAMES',
  'GPS',
  'QR_CODE'
]

export const projectFeatures = [
  'UNLIMITED_REPORT_STORAGE',
  'BULK_EDIT',
  'DATASETS',
  'PIN_FIELDS',
  'PLAN_EXPORT'
]

export const painelFeatures = [
  'UNLIMITED_REPORT_STORAGE',
  'PIN_FIELDS'
]

export const featureSection = [
  'BULK_TASKS_CREATION',
  'BULK_TASKS_EDIT',
  'BULK_PINS_EDIT',
  'BULK_PINS_ADD_TASKS'
]
