/* eslint-disable no-underscore-dangle */
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import * as actionTypes from './constants'

import {
  makeSelectLocale,
  makeSelectLocaleChanged
} from '../LanguageProvider/selectors'
import { makeSelectHeaders, makeSelectRefreshToken } from './selectors'

import { changeLocale } from '../LanguageProvider/actions'
import * as notificationActions from '../NotificationMenu/actions'
import * as actions from './actions'

import { callAPI } from '../../utils/callAPI'
import { getAsyncInjectors } from '../../utils/asyncInjectors'
import history from '../../utils/history'
import rollbarIntegration from '../../utils/rollbarIntegration'
import config from '../../../proxyConfig'
import * as Sentry from '@sentry/react'
import { DateTime, Settings } from 'luxon'

function* setLocale(userData, localeChangedBeforeLogin = false) {
  if (
    userData.data &&
    userData.data.settings &&
    userData.data.settings.timeZone
  ) {
    Settings.defaultZone = userData.data.settings.timeZone
  } else {
    Settings.defaultZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  if (
    !localeChangedBeforeLogin &&
    userData.data &&
    userData.data.settings &&
    userData.data.settings.language
  ) {
    yield put(changeLocale(userData.data.settings.language))
  }
}

function* setupNotifications(headers) {
  yield put(notificationActions.getEmailFrequency(headers))
  yield put(notificationActions.getSubscriptions(headers))
  yield put(notificationActions.checkUnreadNotifications())
}

function* typeLicence(headers, organizationId) {
  try {
    const { data: subscriptionData } = yield call(callAPI, {
      method: 'get',
      url: `/api/v2/organisations/${organizationId}/subscription`,
      headers
    })

    const tier = subscriptionData?.tier?.code
    const name = subscriptionData?.tier?.name?.toLowerCase()

    if (tier) {
      const isLegacyLicense = tier.toLowerCase().includes('legacy')
      return {
        tier: {
          name,
          isLegacy: isLegacyLicense
        }
      }
    }

    return false
  } catch (err) {
    return false
  }
}

export function* login(action) {
  try {
    const { data: twoFaStatus } = yield call(callAPI, {
      method: 'get',
      url: '/api/twoFactor',
      params: {
        email: action.email
      }
    })
    yield put(actions.setPasswordPolicy(twoFaStatus))

    if (twoFaStatus && (twoFaStatus.sms || twoFaStatus.otp)) {
      if (twoFaStatus.sms) {
        yield call(callAPI, {
          method: 'get',
          url: '/api/mfa-api/sms',
          params: {
            email: action.email
          }
        })
      }
      yield put(actions.show2FA(twoFaStatus))
    } else {
      const Authorization = `Basic ${btoa(`${config.clientId}:${config.clientSecret}`)}`

      const [response] = yield all([
        call(callAPI, {
          method: 'post',
          url: '/api/oauth/token',
          headers: {
            Authorization
          },
          data: {
            username: action.email,
            password: action.password,
            grant_type: 'password'
          }
        }),
        call(initializeApp)
      ])

      yield put(actions.loginSuccessful(response.data))

      const headers = yield select(makeSelectHeaders())

      const localeChangedBeforeLogin = yield select(makeSelectLocaleChanged())
      const locale = yield select(makeSelectLocale())

      // set global DateTime locale
      if (typeof DateTime !== 'undefined') {
        Settings.defaultLocale = locale
      }

      if (localeChangedBeforeLogin) {
        yield call(callAPI, {
          method: 'patch',
          url: '/api/me',
          headers,
          data: {
            settings: {
              language: locale
            }
          }
        })
      }

      const userData = yield call(callAPI, {
        method: 'get',
        url: '/api/me',
        headers
      })

      const isLegacy = yield typeLicence(headers, userData.data.organisationId)

      const redirectTo = localStorage.getItem('redirectTo')

      // Remove redirect URL from local storage used to redirect loginSaml
      if (redirectTo) {
        localStorage.removeItem('redirectTo')
      }

      yield* setLocale(userData, localeChangedBeforeLogin)
      yield put(
        actions.setUser({
          ...userData.data,
          ...isLegacy
        })
      )
      yield* setupNotifications(headers)
    }
  } catch (err) {
    window.Rollbarerror('app/Authentication/sagas.js login error', err)
    yield put(actions.loginError(err))
  }
}

export function* twoFaLogin(action) {
  try {
    const Authorization = `Basic ${btoa(`${config.clientId}:${config.clientSecret}`)}`
    const [response] = yield all([
      call(callAPI, {
        method: 'post',
        url: '/api/oauth/token',
        headers: {
          Authorization
        },
        data: {
          username: action.email,
          password: action.password,
          code: action.twoFaCode,
          grant_type: 'password'
        }
      }),
      call(initializeApp)
    ])

    yield put(actions.loginSuccessful(response.data))

    const headers = yield select(makeSelectHeaders())

    const localeChangedBeforeLogin = yield select(makeSelectLocaleChanged())

    if (localeChangedBeforeLogin) {
      const locale = yield select(makeSelectLocale())

      yield call(callAPI, {
        method: 'patch',
        url: '/api/me',
        headers,
        data: {
          settings: {
            language: locale
          }
        }
      })
    }

    const userData = yield call(callAPI, {
      method: 'get',
      url: '/api/me',
      headers
    })

    yield* setLocale(userData, localeChangedBeforeLogin)

    const isLegacy = yield typeLicence(headers, userData.data.organisationId)

    yield put(
      actions.setUser({
        ...userData.data,
        ...isLegacy
      })
    )
    yield* setupNotifications(headers)
  } catch (err) {
    window.Rollbarerror('app/Authentication/sagas.js twoFaLogin error', err)
    yield put(actions.loginError(err))
  }
}

export function* getUser() {
  try {
    const headers = yield select(makeSelectHeaders())

    const [userData] = yield all([
      call(callAPI, {
        method: 'get',
        url: '/api/me',
        headers
      }),
      call(initializeApp)
    ])

    const isLegacy = yield typeLicence(headers, userData.data.organisationId)

    yield* setLocale(userData)
    yield put(
      actions.setUser({
        ...userData.data,
        ...isLegacy
      })
    )
    yield put(actions.getUserDone())

    if (navigator.onLine) {
      if (userData.data && userData.data.email) {
        const { data: twoFaStatus } = yield call(callAPI, {
          method: 'get',
          url: '/api/twoFactor',
          params: {
            email: userData.data.email
          }
        })
        yield put(actions.setPasswordPolicy(twoFaStatus))
      }

      yield* setupNotifications(headers)
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      yield put(actions.logout())
    } else {
      window.Rollbarerror('app/Authentication/sagas.js getUser error', err)
      yield put(actions.setUserError(err))
    }
  }
}

export function* verifyEmail(action) {
  try {
    yield call(callAPI, {
      method: 'post',
      url: '/api/me/email/verify',
      data: {
        token: action.token
      }
    })

    yield put(actions.verifyEmailDone())
  } catch (err) {
    if (err.response && err.response.status === 400) {
      yield put(actions.verifyEmailWrongEmail())
    } else {
      window.Rollbarerror('app/Authentication/sagas.js verifyEmail error', err)
      yield put(actions.verifyEmailError(err))
    }
  }
}

export function* getTokenByRefreshToken() {
  try {
    const refreshToken = yield select(makeSelectRefreshToken())
    const Authorization = `Basic ${btoa(`${config.clientId}:${config.clientSecret}`)}`
    const { data } = yield call(callAPI, {
      method: 'post',
      headers: {
        Authorization
      },
      url: '/api/oauth/token',
      data: {
        refresh_token: refreshToken,
        grant_type: 'refresh_token'
      }
    })
    yield put(actions.getTokenByRefreshTokenDone(data))

    // yield call(window.location.reload)
  } catch (err) {
    window.Rollbarerror(
      'app/Authentication/sagas.js getTokenByRefreshToken error',
      err
    )
    yield put(actions.showLoginPopup(true))
  }
}

export function* getTokenByRefreshTokenWatcher() {
  yield takeLatest(
    actionTypes.GET_TOKEN_BY_REFRESH_TOKEN,
    getTokenByRefreshToken
  )
}

export function* loginWatcher() {
  yield takeLatest(actionTypes.LOGIN, login)
}

export function* twoFaLoginWatcher() {
  yield takeLatest(actionTypes.TWOFA_LOGIN, twoFaLogin)
}

export function* getUserWatcher() {
  yield takeLatest(actionTypes.GET_USER, getUser)
}

export function* verifyEmailWatcher() {
  yield takeLatest(actionTypes.VERIFY_EMAIL, verifyEmail)
}

export function* loginViaSaml(action) {
  try {
    const headers = yield select(makeSelectHeaders(action.token))

    const [userData] = yield all([
      call(callAPI, {
        method: 'get',
        url: '/api/me',
        headers
      }),
      call(initializeApp)
    ])

    yield* setLocale(userData)

    yield put(actions.samlLoginDone())
    yield put(actions.loginSuccessful(action.token))

    yield* setupNotifications(headers)
    // TODO: Not setting up push notifications for SAML accounts on purpose,
    // or is this a bug due to code repetition?
    // yield* setupPushNotifications(headers, userData)

    const isLegacy = yield typeLicence(headers, userData.data.organisationId)

    yield put(
      actions.setUser({
        ...userData.data,
        ...isLegacy
      })
    )
    const redirectTo = localStorage.getItem('redirectTo')

    if (redirectTo) {
      localStorage.removeItem('redirectTo')
      return yield call(history.replace, redirectTo)
    }

    yield call(history.replace, '/')
  } catch (err) {
    window.Rollbarerror('app/Authentication/sagas.js loginViaSaml error', err)
    yield put(actions.logout())
    yield put(actions.loginViaSamlError(err))
  }
}

export function* loginViaSamlWatcher() {
  yield takeLatest(actionTypes.LOGIN_VIA_SAML, loginViaSaml)
}

export function* loginWithRedirect(action) {
  try {
    // check two factor

    const twoFAResponse = yield call(callAPI, {
      method: 'get',
      url: '/api/users/email/2fa',
      params: {
        email: action.email
      }
    })
    yield put(actions.setPasswordPolicy(twoFAResponse.data))

    if (twoFAResponse.data) {
      yield put(actions.show2FA())
    } else {
      const Authorization = `Basic ${btoa(`${config.clientId}:${config.clientSecret}`)}`

      const response = yield call(callAPI, {
        method: 'post',
        url: '/api/oauth/token',
        headers: {
          Authorization
        },
        data: {
          username: action.email,
          password: action.password,
          grant_type: 'password'
        }
      })

      yield put(actions.loginWithRedirectSuccessful(response.data))
    }
  } catch (err) {
    window.Rollbarerror('app/Authentication/sagas.js login error', err)
    yield put(actions.loginError(err))
  }
}

export function* loginWithRedirectWatcher() {
  yield takeLatest(actionTypes.LOGIN_WITH_REDIRECT, loginWithRedirect)
}

// FIXME: Move to a separate file since this is no longer a saga itself
let initialized = false
async function initializeApp() {
  if (initialized) return
  initialized = true
  try {
    // Start immediately but don't wait for completion
    const sentryPromise = ['production', 'staging', 'strabag'].includes(
      import.meta.env.VITE_TARGET_ENV
    )
      ? Sentry
      : Promise.resolve(null)

    const [reducersModule, sagasModule, SentryImported] = await Promise.all([
      import('../../reducers'), // Assuming reducers are exported from this module
      import('../../sagas'), // Assuming sagas are exported from this module
      sentryPromise
    ])

    rollbarIntegration(SentryImported)

    // TODO: find better way to pass the store in here
    const store = window.__STORE__

    const { injectReducers, injectSagas } = getAsyncInjectors(store)
    injectReducers(Object.entries(reducersModule))
    Object.values(sagasModule).map((v) => injectSagas(v))
  } catch (err) {
    initialized = false
    throw err
  }
}

export default [
  loginWatcher,
  twoFaLoginWatcher,
  getUserWatcher,
  verifyEmailWatcher,
  loginViaSamlWatcher,
  loginWithRedirectWatcher,
  getTokenByRefreshTokenWatcher
]
