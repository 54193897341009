import styled from 'styled-components'
import { TextContainer } from '../../components/stories/fundamentals/text-styles/styledComponents'
import BadgesComponent from '../../components/stories/atoms/badges'
import colorsOptions from '../../components/stories/fundamentals/colors-styles/colors'

export const StiledBadgesComponent = styled(BadgesComponent)`
  margin: 0px 8px;
`

export const StyledTextContainer = styled(TextContainer)`
  align-items: center;
  gap: 16px;
  align-self: stretch;
  padding: 24px;
  & .tierIcon {
    width: 90px;
    height: 90px;
  }
`
export const StyledImg = styled.img`
  width: 63px;
  height: 72px;
`

export const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
`
export const StyledPainel = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  padding: 24px;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  & .tierIcon {
    width: 90px;
    height: 90px;
  }
`

export const StyledFilterImg = styled.img`
  filter: grayscale(0%) opacity(100%) !important;
  width: ${({ small }) => (small ? '80px' : '90px')};
`

export const StyledFlag = styled.div`
  height: inherit;
  display: flow;
  span,
  button {
    ${({ disabled }) =>
      disabled ? `color: ${colorsOptions.greys['grey-70']}` : ''};
  }
  button {
    border-color: ${colorsOptions.greys['grey-30']};
  }
  img {
    ${({ disabled }) =>
      disabled ? 'filter: grayscale(100%) opacity(66%)' : ''};
  }
`

export const DialogButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
