export const SET_FIRST_NAME = 'app/Register/SET_FIRST_NAME'
export const SET_LAST_NAME = 'app/Register/SET_LAST_NAME'
export const SET_EMAIL = 'app/Register/SET_EMAIL'
export const SET_ORGANISATION = 'app/Register/SET_ORGANISATION'
export const SET_COUNTRY = 'app/Register/SET_COUNTRY'
export const REGISTER_USER = 'app/Register/REGISTER_USER'
export const REGISTER_USER_SUCCESSFUL = 'app/Register/REGISTER_USER_SUCCESSFUL'
export const REGISTER_USER_ERROR = 'app/Register/REGISTER_USER_ERROR'
export const EMAIL_TAKEN = 'app/Register/EMAIL_TAKEN'
export const SET_PHONE = 'app/Register/SET_PHONE'
export const SET_ORIGINATION_CODE = 'app/Register/SET_ORIGINATION_CODE'
export const GET_COUNTRY = 'app/Register/GET_COUNTRY'
export const GET_COUNTRY_DONE = 'app/Register/GET_COUNTRY_DONE'
export const GET_COUNTRY_ERROR = 'app/Register/GET_COUNTRY_ERROR'

export const eventCategory = 'Registration'
export const eventAction = 'new user registered'

export const SAML_DOMAINS = [
  '3dsite-project.com',
  'a2route.pl',
  'a2-strada.pl',
  'abo-asphalt.at',
  'abo-umwelt.at',
  'abr-gmbh.at',
  'agriegler.at',
  'amw-kundl.at',
  'andersart.net',
  'arge-ahb.de',
  'arge-bau-bsh.de',
  'arge-bau-sorb.de',
  'argegraben21.at',
  'arge-mbcenter.de',
  'argetp.at',
  'arge-tuxbach.at',
  'asf-fraesen.at',
  'asiacenter.hu',
  'asphalt-beton.at',
  'austria-asphalt.com',
  'a-way.at',
  'awm-asphaltwerk.at',
  'baugrundstueck-pantelitz.de',
  'bauholding.at',
  'bauholding.com',
  'baustelle.at',
  'bauxund.at',
  'baystag.com',
  'baytuerk.com',
  'bergsicherung-eu.com',
  'beton-ag.ch',
  'bfb-behmann.de',
  'bitunova.eu',
  'bitunova.hu',
  'bl-gmbh.com',
  'boehmgmbh.at',
  'bsl-eu.com',
  'bur-koeln.de',
  'career.strabag.com',
  'centersystems.com',
  'cestarsb.hr',
  'colas.de',
  'coldmix.nl',
  'cottbuser-fb.de',
  'crnagoraput.me',
  'csa15-vof.nl',
  'deutsche-asphalt.de',
  'dio-pernik.bg',
  'diw-aircraft.com',
  'diw-instandhaltung.at',
  'diw-instandhaltung.com',
  'diw-instandhaltung.de',
  'drumco.ro',
  'dsp.cz',
  'dywidag.com',
  'emb-mpp3.com',
  'energia-naturale.eu',
  'envirgy.at',
  'envirgy.co.at',
  'envirgy.com',
  'ernst-meyer.de',
  'etgelektro.at',
  'ext.strabag-pfs.com',
  'f-kirchhoff.de',
  'fk-systembau.de',
  'fli-riems.de',
  'fm.strabag.com',
  'frischbeton.eu',
  'gaul-baustoffe.de',
  'gfr-remex.de',
  'glasfaserland.at',
  'gte.at',
  'hachmannbau.de',
  'heissmischanlage.at',
  'heiwoe.com',
  'idag.at',
  'igm-ostra.hr',
  'ingsiegl.at',
  'insond.com',
  'jhp-mosty.cz',
  'kab.at',
  'kab.co.at',
  'kanal-total-brus.at',
  'keletaszfalt.hu',
  'kieserlingbau.de',
  'kieswerk-rheinbach.de',
  'kla.co.at',
  'kmgroup.dk',
  'kurz-bau.at',
  'lisag.at',
  'ludwig-voss.de',
  'lukaslang.com',
  'mab-gmbh.at',
  'magyaraszfalt.hu',
  'mail.mischek.at',
  'mav-gmbh.com',
  'mcqa.com',
  'merk.de',
  'mineral.eu',
  'mineral.eu.com',
  'mineral2000.bg',
  'mischek.at',
  'mittag.cz',
  'mobil-baustoffe.com',
  'nimab.se',
  'nordkette.com',
  'nordpark.at',
  'nordpark.com',
  'nyugataszfalt.hu',
  'oat.at',
  'oat.hu',
  'oat.sk',
  'ohneis.de',
  'oklug.at',
  'osta.at',
  'ot.strabag.com',
  'parcoplan.com',
  'partner.zueblin.de',
  'polskiasfalt.com.pl',
  'polskiasfalt.pl',
  'pomgrad.com',
  'pomgrad.hr',
  'possoegel.com',
  'preussebau-hh.de',
  'projektadresse.de',
  'prottelith.at',
  'pshpp.com',
  'pzc-split.hr',
  'repass.de',
  'riederasphalt.at',
  'roba-beton.de',
  'roeblingen.de',
  'rvb-kelheim.de',
  'saale-asphalt.de',
  'sat-roads.com',
  'sbsit.at',
  'sbsit.com',
  'seeresort-hautzendorf.at',
  'se-kunst.at',
  'sensor-dks.com',
  'sf-ausbau.de',
  'sfb-behmann.de',
  'sonderwunsch.zueblin.de',
  'sportberg-goldeck.com',
  'squareplus.at',
  'stassfurter-baubetriebe.de',
  'steffes-mies-bau.de',
  'stein-kies.at',
  'stephan-holz.de',
  'sternenfeld.com',
  'strabag.ca',
  'strabag.cl',
  'strabag.com',
  'strabag.us',
  'strabag-kunstforum.at',
  'strabag-newsroom.com',
  'strabagozkarjv.com',
  'strabag-pfs.com',
  'strabag-rail.com',
  'strabag-rail.pl',
  'strarco.mu',
  'strassenbauprodukte.eu',
  'straton-infra.com',
  'sus.at',
  'swo-prahova.com',
  'talkgate.at',
  'techgate.at',
  'techgate.eu',
  'tech-gate-vienna.at',
  'the-stage.at',
  'torkret.de',
  'tpaqi.com',
  'tss-koeln.de',
  'ttp-jv.ca',
  'valentinerkies.at',
  've30-2sbsm.de',
  'waw-bau.de',
  'wiebau.at',
  'wolfer-goebel.de',
  'zentraletechnik.com',
  'zentrale-technik.com',
  'zpc.com.pt',
  'zublin.cl',
  'zublin.ro',
  'zublin-international.com',
  'zublinllc.ae',
  'zueblin.ae',
  'zueblin.at',
  'zueblin.bg',
  'zueblin.ca',
  'zueblin.co.uk',
  'zueblin.com.hk',
  'zueblin.cz',
  'zueblin.de',
  'zueblin.dk',
  'zueblin.eu',
  'zueblin.hr',
  'zueblin.hu',
  'zueblin.nl',
  'zueblin.no',
  'zueblin.pl',
  'zueblin.ro',
  'zueblin.ru',
  'zueblin.se',
  'zueblin-international.com',
  'zueblin-nederland.com',
]

export const ABOUT_DOCU_TOOLS_OPTIONS = [
  { originationCode: 'COMPANY', label: 'about_company' },
  { originationCode: 'SEEN', label: 'about_seen' },
  { originationCode: 'WORKED_WITH_IT', label: 'about_worked_with' },
  { originationCode: 'BY_PROJECT', label: 'about_by_project' },
  { originationCode: 'RECOMMENDED', label: 'about_by_recommended' },
  { originationCode: 'INVITATION', label: 'about_invitation' },
  { originationCode: 'SOCIAL', label: 'about_social' },
  { originationCode: 'WEB', label: 'about_web' },
  { originationCode: 'ADVERTISING', label: 'about_advertising' },
]
