import proxyRules from '../../proxyConfig/rules'

class PathRewriter {
  constructor(rules) {
    this.rules = rules
    this.MAX_CACHE_SIZE = 14000
    this.cache = {} // TODO: Move cache to localStorage
    this.cacheSize = 0
    this.logger = console
  }

  rewritePath = ({ path, baseURL: defaultBaseURL, searchParams }) => {
    let urlToParse = (defaultBaseURL || self.location.origin) + path
    if (path.startsWith('http://') || path.startsWith('https://')) {
      urlToParse = path
    }

    const urlParts = new URL(urlToParse)
    const { search } = urlParts
    path = urlParts.pathname
    defaultBaseURL = urlParts.origin

    // Cachig requires a lot more work but doesnt seem worthwhile, since this path rewriter is a temporary implementation
    // const fullURL = `${defaultBaseURL}${path}`
    // const { timeStamp, ...cachedData } = this.cache[fullURL] || {}
    // if (Object.keys(cachedData).length) return cachedData

    let result = { path, baseURL: defaultBaseURL }
    let targetPath = path
    let baseURL = defaultBaseURL

    this.rules.some((rule) => {
      if (rule.from.test(path)) {
        targetPath = path.replace(rule.from, rule.to)
        baseURL = rule.target || defaultBaseURL
        result = { path: targetPath, baseURL }

        return true
      }
      return false
    })

    // some baseurl have a path, we extract the path into the right place
    const baseURLLocation = new URL(result.baseURL)
    const pathPrefix = baseURLLocation.pathname.replace('/', '')
    const finalPath = pathPrefix + result.path
    const finalURL = new URL(finalPath, baseURLLocation.origin)
    if (searchParams || search) {
      finalURL.search = search || new URLSearchParams(searchParams)
    }

    result = {
      path: finalPath + search,
      baseURL: baseURLLocation.origin,
      url: finalURL,
    }

    // this.addToCache(`${fullURL}`, { ...result, timeStamp: Date.now() })
    return result
  }

  addToCache = (key, value) => {
    if (this.cacheSize > this.MAX_CACHE_SIZE) {
      const twentyFourHoursAgo = Date.now() - 86400
      this.cache = this.cache.filter(
        (c) => c.timeStamp > twentyFourHoursAgo * 5,
      )
    }
    this.cacheSize += 1
    this.cache[key] = value
  }
}

export default PathRewriter
export const pathRewriterInstance = new PathRewriter(proxyRules)
