import styled from 'styled-components'

/**
 * @component
 * @deprecated It doesnt look reusable
 */
export default styled.div`
  text-align: center;
  margin-bottom: 29px;
`
