export const GET_PLAN = 'app/PlanVersions/GET_PLAN'
export const GET_PLAN_DONE = 'app/PlanVersions/GET_PLAN_DONE'
export const GET_PLAN_ERROR = 'app/PlanVersions/GET_PLAN_ERROR'

export const UPDATE_PLAN = 'app/PlanVersions/UPDATE_PLAN'
export const UPDATE_PLAN_DONE = 'app/PlanVersions/UPDATE_PLAN_DONE'
export const UPDATE_PLAN_ERROR = 'app/PlanVersions/UPDATE_PLAN_ERROR'

export const UPDATE_PLANS_BATCH = 'app/PlanVersions/UPDATE_PLANS_BATCH'
export const UPDATE_PLANS_BATCH_DONE =
  'app/PlanVersions/UPDATE_PLANS_BATCH_DONE'
export const UPDATE_PLANS_BATCH_ERROR =
  'app/PlanVersions/UPDATE_PLANS_BATCH_ERROR'

export const RESET_PIN = 'app/PlanVersions/RESET_PIN'
export const RESET_PIN_ERROR = 'app/PlanVersions/RESET_PIN_ERROR'
