import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  makeSelectLoggedIn
} from './selectors'
import { Loader, LoaderWrapper } from '../../components/PageLoader'
import Login from './Login'

const ForceLogin = ({ children }) => {
  const loggedIn = useSelector(makeSelectLoggedIn())
  const dtToken = localStorage.getItem('dtToken')

  if (!loggedIn && !!dtToken) {
    // not logged in, but dtToken is available
    return (
      <LoaderWrapper>
        <Loader className="loader" />
      </LoaderWrapper>
    )
  }
  if (loggedIn) {
    return <>{children}</>
  }

  const pathName = window.location.pathname

  if (pathName !== '/') {
    localStorage.setItem('redirectTo', pathName)
  }

  return <Login />
}

ForceLogin.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default ForceLogin
