import styled from 'styled-components'
import { ifProp, withProp } from 'styled-tools'

import FloatingLabelComponent from './FloatingLabel'
import FloatingLabelTwoComponent from './FloatingLabelTwo'
import InfoMsgComponent from './InfoMsg'
import LabelmsgComponent from './Labelmsg'
import FormHeaderComponent from './FormHeader'
import ErrormsgComponent from './Errormsg'
import CustomInputsComponent from './CustomInputs'
import FullContainerComponent from './FullContainer'
import InsideContainerComponent from './InsideContainer'
import LogoSectionComponent from './LogoSection'
import LogoImgComponent from './LogoImg'
import ErrormsgblockComponent from './Errormsgblock'
import CustomBtnComponent from './CustomBtn'
import OtherContainerComponent from './OtherContainer'
import OtherLinksComponent from './OtherLinks'
import StyledLinkComponent from './StyledLink'
import StyledLinkAComponent from './StyledLinkA'
import CustomDescriptionComponent from './CustomDescription'
import CheckboxWithLabelContainerComponent from './CheckboxWithLabelContainer'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'
import { sizing } from '../stories/fundamentals/text-styles/text-styles'

/**
 * Container for an input form
 * @component
 */
export const FormContainer = styled.div`
  margin-bottom: 13px;
  ${withProp('marginTop', (marginTop) => `margin-top: ${marginTop}px`)};
  &.upmargin {
    margin-top: -5px;
  }
  &.upmarginmore {
    margin-top: -8px;
  }
  ${ifProp(
    'center',
    `display: flex;
        align-items: center;
        justify-content: center;`,
  )}
`

export const selectDropDownStyle = (wrongInput, hideBorder, autoHeight) => ({
  control: (base) => ({
    ...base,
    height: autoHeight ? 'auto !important' : '25px',
    minHeight: '25px',
    border: 'none',
    borderBottom: hideBorder
      ? 'none'
      : `1px solid ${colorsOptions.greys['grey-50']}`,
    borderColor: wrongInput
      ? colorsOptions.secondaryColors.orange.strong
      : colorsOptions.greys['grey-50'],
    backgroundColor: colorsOptions.greys.white,
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: 0,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: colorsOptions.greys['grey-100'],
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: sizing['text-r'].fontSize,
  }),
})

export const FloatingLabel = FloatingLabelComponent
export const FloatingLabelTwo = FloatingLabelTwoComponent
export const InfoMsg = InfoMsgComponent
export const Labelmsg = LabelmsgComponent
export const FormHeader = FormHeaderComponent
export const Errormsg = ErrormsgComponent
export const CustomInputs = CustomInputsComponent
export const FullContainer = FullContainerComponent
export const InsideContainer = InsideContainerComponent
export const LogoSection = LogoSectionComponent
export const LogoImg = LogoImgComponent
export const Errormsgblock = ErrormsgblockComponent
export const CustomBtn = CustomBtnComponent
export const OtherContainer = OtherContainerComponent
export const OtherLinks = OtherLinksComponent
export const StyledLink = StyledLinkComponent
export const StyledLinkA = StyledLinkAComponent
export const CustomDescription = CustomDescriptionComponent
export const CheckboxWithLabelContainer = CheckboxWithLabelContainerComponent
