/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware } from 'redux'
import { fromJS } from 'immutable'
import createSagaMiddleware from 'redux-saga'
import createReducer from './createReducer'
import tokenExpiredMiddleware from './utils/tokenExpiredMiddleware'
import { composeWithDevTools } from 'redux-devtools-extension'

const sagaMiddleware = createSagaMiddleware()

export default function configureStore(initialState = {}) {
  const middlewares = [sagaMiddleware, tokenExpiredMiddleware]

  const middlewareEnhancer = applyMiddleware(...middlewares)

  const composedEnhancers = composeWithDevTools(middlewareEnhancer)

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composedEnhancers,
  )

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.asyncReducers = {} // Async reducer registry

  return store
}
