import { fromJS } from 'immutable'
import * as actionTypes from './constants'
import { LOGOUT } from '../Authentication/constants'

const initialState = fromJS({
  email: '',
})

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_EMAIL:
      return state.set('email', action.email).set('emailNotValid', false)
    case actionTypes.RESET_PASSWORD:
      return state.set('resetingPassword', true)
    case actionTypes.RESET_PASSWORD_ERROR:
      return state
        .set('resetingPassword', false)
        .set('resetPasswordError', action.error)
    case actionTypes.EMAIL_NOT_VALID:
      return state.set('resetingPassword', false).set('emailNotValid', true)
    case actionTypes.RESET_PASSWORD_DONE:
      return state.set('resetingPassword', false).set('resetPasswordDone', true)
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default reducer
