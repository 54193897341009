import { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  ErrorDiv,
  Button,
  DetailsDiv,
  NetworkErrorPopup,
  CustomBtn,
  Retry,
} from './styledComponents'

/**
 * This is the defaut docu error component
 */
class Error extends Component {
  static reloadPage = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }

  constructor(props) {
    super(props)

    this.state = {
      showDetails: false,
    }
  }

  closePopup = () => {
    const { onClosePopup } = this.props
    if (onClosePopup) onClosePopup()

    this.setState({ popupClosed: true })
  }

  toggleDetails = () => {
    this.setState(({ showDetails }) => ({ showDetails: !showDetails }))
  }

  render() {
    const { error, noDiv } = this.props
    const { popupClosed, showDetails } = this.state

    if (popupClosed) return null

    let {
      // eslint-disable-next-line prefer-const
      name,
      message,
      response,
      onRetry,
    } = error
    name = name || 'Error'
    message = message || error

    // eslint-disable-next-line no-nested-ternary
    const type =
      message === 'Network Error'
        ? 'network'
        : response
          ? 'response'
          : undefined

    const content = (
      <>
        {type === 'network' && (
          <NetworkErrorPopup>
            <FormattedMessage id="error_network" />
            <div className="footer">
              <CustomBtn onClick={Error.reloadPage}>
                <FormattedMessage id="refreshPage" />
              </CustomBtn>
              <CustomBtn onClick={this.closePopup}>
                <FormattedMessage id="close" />
              </CustomBtn>
            </div>
          </NetworkErrorPopup>
        )}
        {type === 'response' && (
          <span>
            <h3>
              <FormattedMessage id="error_default" />
            </h3>
            <p>
              {!!response.data && !!response.data.error_description && (
                <span>{response.data.error_description}</span>
              )}
              {onRetry && (
                <Retry onClick={onRetry}>
                  <span>&nbsp;</span>
                  <FormattedMessage id="try_again" />
                </Retry>
              )}
            </p>
          </span>
        )}
        {!type && (
          <span>
            <h3>
              <FormattedMessage id="error_default" />
            </h3>
            <Button onClick={this.toggleDetails}>
              <FormattedMessage id="error_defaultDetails" />
            </Button>
            {showDetails && (
              <DetailsDiv>
                <h4>{name}</h4>
                <p>{message}</p>
              </DetailsDiv>
            )}
          </span>
        )}
      </>
    )

    return noDiv ? content : <ErrorDiv>{content}</ErrorDiv>
  }
}

Error.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
  ]),
  noDiv: PropTypes.bool,
  onClosePopup: PropTypes.func,
}

export default Error
