import Immutable, { fromJS, Map, OrderedMap } from 'immutable'
import * as constants from './constants'
import { LOGOUT } from '../Authentication/constants'
import {
  UPDATE_PLAN_DONE,
  UPDATE_PLANS_BATCH_DONE,
} from '../PlanVersions/constants'
import { sortProjectContent } from '../../utils/sortManager'
import safeIsNaN from '../../utils/safeIsNaN'

const initialState = fromJS({
  loading: true, // can be deleted later
  sortBy: 'name', // can be deleted later
  folderBatchSelection: {},
  planBatchSelection: {},

  planFolders: {},
  planFolderTimestamps: {},
  plansStateFilter: 'Active', // [Inactive, All]
  plansSearch: '',
  plansSort: 'Last Modified', // [Name, Type]
})

function makeRootPlansObject(state, rootPlans) {
  let rootPlansObject = state.has('rootPlansObject')
    ? state.get('rootPlansObject')
    : fromJS({
        totalPages: 0,
        totalElements: 0,
        number: 0,
        rootId: null,
      })

  const pageNumber = rootPlans.number

  // set page options
  rootPlansObject = rootPlansObject
    .set('totalPages', rootPlans.totalPages)
    .set('totalElements', rootPlans.totalElements)
    .set('number', pageNumber)
    .set('rootId', rootPlans.id)

  // add content to content, id is already there -> merge it
  let content = state.has('rootPlansObject')
    ? state.getIn(['rootPlansObject', 'content'])
    : new OrderedMap()

  // delete all entries of current page
  content = content.filterNot(
    (element) => element?.get('pageNumber') === pageNumber,
  )

  // add new entries to current page
  rootPlans.content.forEach((element) => {
    content = content.set(element.id, fromJS({ ...element, pageNumber }))
  })

  return rootPlansObject.set('content', content)
}

function makeFolderPlansObject(state, action) {
  // object is already there because it's created on GET_FOLDER_PLANS
  let folderPlansObject = state.getIn(['planFolders', action.folderId])

  // if we get data from quick view the object can be undefined
  if (Immutable.Iterable.isIterable(folderPlansObject)) {
    // set page options
    folderPlansObject = folderPlansObject.merge(fromJS(action.plans))

    // add content to content, id is already there -> merge it
    let content = state.hasIn(['planFolders', action.folderId])
      ? state.getIn(['planFolders', action.folderId, 'content'])
      : new OrderedMap()

    const pageNumber = action.plans.number

    // delete old items of current page
    content = content.filterNot(
      (element) => element?.get('pageNumber') === pageNumber,
    )

    action.plans.content.forEach((element) => {
      content = content.set(element.id, fromJS({ ...element, pageNumber }))

      // add the open attribute to the folder
      if (element.type === 'Folder') {
        content = content.setIn([element.id, 'open'], false)
      }
    })

    return folderPlansObject.set('content', content)
  }

  return {}
}

function planSelectionReducer(state = initialState, action) {
  switch (action.type) {
    case constants.LOAD_PLANS: // can be deleted later
      return state
        .set('loading', true)
        .set('activePlansLoading', true)
        .set('projectId', action.projectId)
    case constants.PLANS_LOADING_ERROR: // can be deleted later
      return state
        .set('loading', false)
        .set('activePlansLoading', false)
        .set('error', action.error)
    case constants.PLANS_LOADED: // can be deleted later
      return state.set('loading', false).set('plans', action.plans)
    case constants.ACTIVE_PLANS_LOADED: // can be deleted later
      return state
        .set('activePlansLoading', false)
        .set('activePlans', action.plans)
    case constants.FILTER_PLANS: // can be deleted later
      return state.set('filter', action.filterWord)
    case constants.FOLDER_CREATED: {
      let newState = state

      let content
      const isInOpenFolder =
        action.folder.parentId &&
        newState.hasIn(['planFolders', action.folder.parentId])
      const isInRootFolder = !action.folder.parentId

      if (isInOpenFolder) {
        content = newState.getIn([
          'planFolders',
          action.folder.parentId,
          'content',
        ])
      } else if (isInRootFolder) {
        content = newState.getIn(['rootPlansObject', 'content'])
      }

      if (content) {
        content = content.set(action.folder.id, fromJS(action.folder))
        content.sort(sortProjectContent(newState.get('plansSort')))
      }

      if (isInOpenFolder) {
        newState = newState.setIn(
          ['planFolders', action.folder.parentId, 'content'],
          content,
        )
        newState = newState.setIn(
          ['planFolders', action.folder.parentId, 'items'],
          newState.getIn(['planFolders', action.folder.parentId, 'items']) + 1,
        )
      } else if (isInRootFolder) {
        newState = newState.setIn(['rootPlansObject', 'content'], content)
      }

      return newState
        .set('folderCreated', true)
        .set('createFolder', false)
        .set('lastCreatedFolder', action.folder)
    }
    case constants.CREATE_FOLDER_ERROR:
      return state
        .set('createFolderError', action.error)
        .set('createFolder', false)
    case constants.CLEAR_CREATE_FOLDER:
      return state
        .set('createFolderError', null)
        .set('folderCreated', false)
        .set('createFolder', false)
    case constants.CREATE_FOLDER:
      return state.set('createFolder', true)
    case constants.PLAN_MOVED: {
      let newState = state
      let plan

      // get folder out of old location an delete it there
      const rootId = newState.getIn(['rootPlansObject', 'rootId'])
      if (
        action.oldParentId &&
        newState.hasIn(['planFolders', action.oldParentId])
      ) {
        plan = newState.getIn([
          'planFolders',
          action.oldParentId,
          'content',
          action.planId,
        ])
        newState = newState.deleteIn([
          'planFolders',
          action.oldParentId,
          'content',
          action.planId,
        ])
        const parentIdOfOldParent = newState.getIn([
          'planFolders',
          action.oldParentId,
          'parentId',
        ])
        if (
          parentIdOfOldParent === null ||
          parentIdOfOldParent === newState.getIn(['rootPlansObject', 'rootId'])
        ) {
          const newCount =
            newState.getIn([
              'rootPlansObject',
              'content',
              action.oldParentId,
              'items',
            ]) - 1
          newState = newState.setIn(
            ['rootPlansObject', 'content', action.oldParentId, 'items'],
            newCount,
          )
        } else if (parentIdOfOldParent) {
          const newCount =
            newState.getIn([
              'planFolders',
              parentIdOfOldParent,
              'content',
              action.oldParentId,
              'items',
            ]) - 1
          newState = newState.setIn(
            [
              'planFolders',
              parentIdOfOldParent,
              'content',
              action.oldParentId,
              'items',
            ],
            newCount,
          )
        }
      } else if (!action.oldParentId || action.oldParentId === rootId) {
        plan = newState.getIn(['rootPlansObject', 'content', action.planId])
        newState = newState.deleteIn([
          'rootPlansObject',
          'content',
          action.planId,
        ])
      }

      if (plan) {
        plan = plan.set('parentId', action.parentId)
      }

      let content
      const isInOpenFolder =
        action.parentId && newState.hasIn(['planFolders', action.parentId])
      const isInRootFolder = !action.parentId

      if (isInOpenFolder) {
        content = newState.getIn(['planFolders', action.parentId, 'content'])
      } else if (isInRootFolder) {
        content = newState.getIn(['rootPlansObject', 'content'])
      }

      if (content) {
        content = content.set(action.planId, plan)
        content.sort(sortProjectContent(state.get('plansSort')))
      }

      if (isInRootFolder) {
        newState = newState.setIn(['rootPlansObject', 'content'], content)
      } else {
        let parentIdOfParent

        if (isInOpenFolder) {
          newState = newState.setIn(
            ['planFolders', action.parentId, 'content'],
            content,
          )
          parentIdOfParent = newState.getIn([
            'planFolders',
            action.parentId,
            'parentId',
          ])
        } else if (
          newState.hasIn(['rootPlansObject', 'content', action.parentId])
        ) {
          parentIdOfParent = null
        } else {
          newState.get('planFolders').forEach((folder, folderId) => {
            folder.get('content').forEach((item, itemId) => {
              if (itemId === action.parentId) {
                parentIdOfParent = folderId
              }
            })
          })
        }

        if (
          parentIdOfParent === null ||
          parentIdOfParent === newState.getIn(['rootPlansObject', 'rootId'])
        ) {
          const newCount =
            newState.getIn([
              'rootPlansObject',
              'content',
              action.parentId,
              'items',
            ]) + 1
          if (!safeIsNaN(newCount)) {
            newState = newState.setIn(
              ['rootPlansObject', 'content', action.parentId, 'items'],
              newCount,
            )
          }
        } else if (parentIdOfParent) {
          const newCount =
            newState.getIn([
              'planFolders',
              parentIdOfParent,
              'content',
              action.parentId,
              'items',
            ]) + 1
          if (!safeIsNaN(newCount)) {
            newState = newState.setIn(
              [
                'planFolders',
                parentIdOfParent,
                'content',
                action.parentId,
                'items',
              ],
              newCount,
            )
          }
        }
      }

      return newState.set('planMoved', true).set('movePlan', false)
    }
    case UPDATE_PLANS_BATCH_DONE: {
      let newState = state

      const rootId = newState.getIn(['rootPlansObject', 'rootId'])
      action.plans.forEach((plan) => {
        const actionInState = plan.active ? 'setIn' : 'deleteIn'

        if (plan.parentId === rootId) {
          newState = newState[actionInState](
            ['rootPlansObject', 'content', plan.id],
            { ...plan, parentId: null },
          )
        } else {
          newState = newState[actionInState](
            ['planFolders', plan.parentId, 'content', plan.id],
            plan,
          )

          // since we removed items, update the 'items' property in the parent's parent content property
          if (!plan.active) {
            const parentIdOfParent =
              plan.parentId &&
              newState.getIn(['planFolders', plan.parentId, 'parentId'])
            const locationOfParentItem =
              parentIdOfParent !== rootId
                ? [
                    'planFolders',
                    parentIdOfParent,
                    'content',
                    plan.parentId,
                    'items',
                  ]
                : ['rootPlansObject', 'content', plan.parentId, 'items']

            const newCount = newState.getIn(locationOfParentItem) - 1
            if (!safeIsNaN(newCount)) {
              newState = newState.setIn(locationOfParentItem, newCount)
            }
          }
        }
      })

      newState = newState
        .set('planBatchSelection', fromJS({}))
        .set('folderBatchSelection', fromJS({}))
      return newState
    }
    case constants.MOVE_PLAN_ERROR:
      return state.set('movePlanError', action.error).set('movePlan', false)
    case constants.CLEAR_MOVE_PLAN:
      return state
        .set('movePlanError', null)
        .set('planMoved', false)
        .set('movePlan', false)
    case constants.MOVE_PLAN:
      return state.set('movePlan', true)
    case constants.FOLDER_MOVED: {
      let newState = state
      let folder

      // get folder out of old location an delete it there
      if (
        action.oldParentId &&
        newState.hasIn(['planFolders', action.oldParentId])
      ) {
        folder = newState.getIn([
          'planFolders',
          action.oldParentId,
          'content',
          action.folderId,
        ])
        newState = newState.deleteIn([
          'planFolders',
          action.oldParentId,
          'content',
          action.folderId,
        ])
        const parentIdOfOldParent = newState.getIn([
          'planFolders',
          action.oldParentId,
          'parentId',
        ])
        if (
          parentIdOfOldParent === null ||
          parentIdOfOldParent === newState.getIn(['rootPlansObject', 'rootId'])
        ) {
          const newCount =
            newState.getIn([
              'rootPlansObject',
              'content',
              action.oldParentId,
              'items',
            ]) - 1
          newState = newState.setIn(
            ['rootPlansObject', 'content', action.oldParentId, 'items'],
            newCount,
          )
        } else if (parentIdOfOldParent) {
          const newCount =
            newState.getIn([
              'planFolders',
              parentIdOfOldParent,
              'content',
              action.oldParentId,
              'items',
            ]) - 1
          newState = newState.setIn(
            [
              'planFolders',
              parentIdOfOldParent,
              'content',
              action.oldParentId,
              'items',
            ],
            newCount,
          )
        }
      } else if (!action.oldParentId) {
        folder = newState.getIn(['rootPlansObject', 'content', action.folderId])
        newState = newState.deleteIn([
          'rootPlansObject',
          'content',
          action.folderId,
        ])
      }

      if (folder) {
        folder = folder.set('parentId', action.parentId)
      }

      let content
      const isInOpenFolder =
        action.parentId && newState.hasIn(['planFolders', action.parentId])
      const isInRootFolder = !action.parentId

      if (isInOpenFolder) {
        content = newState.getIn(['planFolders', action.parentId, 'content'])
      } else if (isInRootFolder) {
        content = newState.getIn(['rootPlansObject', 'content'])
      }

      if (content) {
        content = content.set(action.folderId, folder)
        content.sort(sortProjectContent(newState.get('plansSort')))
      }

      if (isInOpenFolder) {
        newState = newState.setIn(
          ['planFolders', action.parentId, 'content'],
          content,
        )
        const parentIdOfParent = newState.getIn([
          'planFolders',
          action.parentId,
          'parentId',
        ])
        if (parentIdOfParent) {
          newState = newState.setIn(
            [
              'planFolders',
              parentIdOfParent,
              'content',
              action.parentId,
              'items',
            ],
            newState.getIn([
              'planFolders',
              parentIdOfParent,
              'content',
              action.parentId,
              'items',
            ]) + 1,
          )
        } else {
          newState = newState.setIn(
            ['rootPlansObject', 'content', action.parentId, 'items'],
            newState.getIn([
              'rootPlansObject',
              'content',
              action.parentId,
              'items',
            ]) + 1,
          )
        }
      } else if (isInRootFolder) {
        newState = newState.setIn(['rootPlansObject', 'content'], content)
      }

      if (isInRootFolder) {
        newState = newState.setIn(['rootPlansObject', 'content'], content)
      } else {
        let parentIdOfParent

        if (isInOpenFolder) {
          newState = newState.setIn(
            ['planFolders', action.parentId, 'content'],
            content,
          )
          parentIdOfParent = newState.getIn([
            'planFolders',
            action.parentId,
            'parentId',
          ])
        } else if (
          newState.hasIn(['rootPlansObject', 'content', action.parentId])
        ) {
          parentIdOfParent = null
        } else {
          newState.get('planFolders').forEach((plansFolder, folderId) => {
            plansFolder.get('content').forEach((item, itemId) => {
              if (itemId === action.parentId) {
                parentIdOfParent = folderId
              }
            })
          })
        }

        if (
          parentIdOfParent === null ||
          parentIdOfParent === newState.getIn(['rootPlansObject', 'rootId'])
        ) {
          const newCount =
            newState.getIn([
              'rootPlansObject',
              'content',
              action.parentId,
              'items',
            ]) + 1
          if (!safeIsNaN(newCount)) {
            newState = newState.setIn(
              ['rootPlansObject', 'content', action.parentId, 'items'],
              newCount,
            )
          }
        } else if (parentIdOfParent) {
          const newCount =
            newState.getIn([
              'planFolders',
              parentIdOfParent,
              'content',
              action.parentId,
              'items',
            ]) + 1
          if (!safeIsNaN(newCount)) {
            newState = newState.setIn(
              [
                'planFolders',
                parentIdOfParent,
                'content',
                action.parentId,
                'items',
              ],
              newCount,
            )
          }
        }
      }

      return newState.set('folderMoved', true).set('moveFolder', false)
    }
    case constants.MOVE_FOLDER_ERROR:
      return state.set('moveFolderError', action.error).set('moveFolder', false)
    case constants.CLEAR_MOVE_FOLDER:
      return state
        .set('moveFolderError', null)
        .set('folderMoved', false)
        .set('moveFolder', false)
    case constants.MOVE_FOLDER:
      return state.set('moveFolder', true)
    case constants.SET_PROJECT_NAME:
      return state.set('projectName', action.projectName)
    case constants.SET_PLAN_SORT:
      return state.set('plansSort', action.sortBy)
    case constants.SELECT_FOLDER_BATCH:
      return state.setIn(
        ['folderBatchSelection', action.folder.id],
        action.folder,
      )
    case constants.DESELECT_FOLDER_BATCH:
      return state.deleteIn(['folderBatchSelection', action.folder.id])
    case constants.DESELECT_ALL_FOLDER_BATCH:
      return state.set('folderBatchSelection', fromJS({}))
    case constants.SELECT_PLAN_BATCH:
      return state.setIn(['planBatchSelection', action.plan.id], action.plan)
    case constants.DESELECT_PLAN_BATCH:
      return state.deleteIn(['planBatchSelection', action.plan.id])
    case constants.DESELECT_ALL_PLAN_BATCH:
      return state.set('planBatchSelection', fromJS({}))
    case constants.UPDATE_FOLDER:
      return state.set('updatingFolder', true)
    case constants.UPDATE_FOLDER_DONE: {
      let newState = state

      if (action.parentId !== undefined) {
        if (
          action.parentId &&
          newState.hasIn(['planFolders', action.parentId])
        ) {
          newState = newState.mergeIn(
            ['planFolders', action.parentId, 'content', action.folderId],
            fromJS(action.options),
          )
        } else if (!action.parentId) {
          newState = newState.mergeIn(
            ['rootPlansObject', 'content', action.folderId],
            fromJS(action.options),
          )
        }
      }

      return newState.set('updatingFolder', false)
    }
    case constants.UPDATE_FOLDER_ERROR:
      return state
        .set('updateFolderError', action.error)
        .set('updatingFolder', false)

    case constants.CHECK_CAN_CREATE_PLAN:
      return state.delete('canCreatePlan').delete('checkCanCreatePlanError')
    case constants.CHECK_CAN_CREATE_PLAN_DONE:
      return state.set('canCreatePlan', action.canCreatePlan)
    case constants.CHECK_CAN_CREATE_PLAN_ERROR:
      return state.set('checkCanCreatePlanError', action.error)

    case constants.SUBSCRIBE_NOTIFICATION:
    case constants.UNSUBSCRIBE_NOTIFICATION:
      return state
        .set('subscribingNotifications', true)
        .delete('subscribeNotificationsError')
    case constants.SUBSCRIBE_NOTIFICATION_DONE:
      return state.set('subscribingNotifications', false)
    case constants.SUBSCRIBE_NOTIFICATION_ERROR:
      return state
        .set('subscribingNotifications', false)
        .set('subscribeNotificationsError', action.error)
    case constants.DELETE_EMPTY_FOLDER_DONE: {
      let newState = state

      if (action.parentId) {
        newState = newState
          .deleteIn([
            'planFolders',
            action.parentId,
            'content',
            action.folderId,
          ])
          .setIn(
            ['planFolders', action.parentId, 'items'],
            newState.getIn(['planFolders', action.parentId, 'items']) - 1,
          )
      } else {
        newState = newState
          .deleteIn(['rootPlansObject', 'content', action.folderId])
          .setIn(
            ['rootPlansObject', 'items'],
            newState.getIn(['rootPlansObject', 'items']) - 1,
          )
      }

      return newState
    }
    case constants.DELETE_EMPTY_FOLDER_ERROR:
      return state.set('deleteEmptyFolderError', action.error)

    case constants.CLEAR_PLANS:
      return state
        .delete('rootPlansObject')
        .delete('rootPlansTimestamp')
        .set('planFolderTimestamps', fromJS({}))
        .set('planFolders', fromJS({}))
    case constants.CLEAR_FOLDER_PLANS: {
      const folder = fromJS({
        totalPages: 0,
        totalElements: 0,
        number: 0,
        open: true,
      })

      return state.setIn(
        ['planFolders', action.folderId],
        folder.set('content', new OrderedMap()),
      )
    }
    case constants.GET_ROOT_PLANS:
      return state
        .set('gettingRootPlans', true)
        .set('plansSort', action.sort || state.get('plansSort'))
        .set(
          'plansStateFilter',
          action.stateFilter || state.get('plansStateFilter'),
        )
        .set(
          'plansSearch',
          typeof action.search === 'string'
            ? action.search
            : state.get('plansSearch'),
        )
        .delete('getRootPlansError')
    case constants.GET_ROOT_PLANS_DONE: {
      const rootPlansObject = makeRootPlansObject(state, action.plans)

      return state
        .set('gettingRootPlans', action.cached)
        .set('rootPlansObject', rootPlansObject)
        .delete('getProjectTreeCancelToken')
        .set('rootPlansTimestamp', action.timestamp)
    }
    case constants.GET_ROOT_PLANS_ERROR:
      return state
        .set('gettingRootPlans', false)
        .set('getRootPlansError', action.error)

    case constants.GET_ROOT_PLANS_WITH_FOLDERS:
      return state
        .set('gettingRootPlans', true)
        .set('plansSort', action.sort || state.get('plansSort'))
        .set(
          'plansStateFilter',
          action.stateFilter || state.get('plansStateFilter'),
        )
        .set(
          'plansSearch',
          typeof action.search === 'string'
            ? action.search
            : state.get('plansSearch'),
        )
        .delete('getRootPlansWithFoldersError')
    case constants.GET_ROOT_PLANS_WITH_FOLDERS_DONE: {
      let newState = state

      const { rootPlans, folders } = action
      const rootPlansObject = makeRootPlansObject(state, rootPlans)

      newState = newState
        .set('gettingRootPlans', rootPlans.cached)
        .set('rootPlansObject', rootPlansObject)
        .delete('getProjectTreeCancelToken')
        .set('rootPlansTimestamp', rootPlans.timestamp)

      folders.forEach((folder) => {
        const folderPlansObject = makeFolderPlansObject(newState, folder)

        if (folderPlansObject) {
          newState = newState
            .set('gettingFolderPlans', folder.cached)
            .setIn(['planFolders', folder.folderId], folderPlansObject)
            .setIn(['planFolderTimestamps', folder.folderId], folder.timestamp)
            .delete('plansTreeCancelToken')
        }
      })

      return newState
    }
    case constants.GET_ROOT_PLANS_WITH_FOLDERS_ERROR:
      return state
        .set('gettingRootPlans', false)
        .set('getRootPlansWithFoldersError', action.error)

    // is called when there is still content to load
    case constants.GET_FOLDER_PLANS: {
      let newState = state

      if (newState.hasIn(['planFolders', action.folderId])) {
        newState = newState.setIn(
          ['planFolders', action.folderId, 'open'],
          true,
        )
      } else {
        const folder = fromJS({
          totalPages: 0,
          totalElements: 0,
          number: 0,
          open: true,
        })

        newState = newState.setIn(
          ['planFolders', action.folderId],
          folder
            .set('content', new OrderedMap())
            .set('parentId', action.folderId),
        )
      }

      return newState
        .set('gettingFolderPlans', action.folderId)
        .delete(`getFolderPlansError${action.folderId}`)
    }
    case constants.GET_FOLDER_PLANS_DONE: {
      const folderPlansObject = makeFolderPlansObject(state, action)

      if (folderPlansObject) {
        return state
          .set('gettingFolderPlans', action.cached)
          .setIn(['planFolders', action.folderId], folderPlansObject)
          .setIn(['planFolderTimestamps', action.folderId], action.timestamp)
          .delete('plansTreeCancelToken')
      }

      return state
    }
    case constants.GET_FOLDER_PLANS_ERROR:
      return state
        .set('gettingFolderPlans', false)
        .set(`getFolderPlansError${action.folderId}`, action.error)
    case constants.OPEN_PLANS_FOLDER:
      return state.setIn(['planFolders', action.folderId, 'open'], true)
    case constants.CLOSE_PLANS_FOLDER:
      return state.setIn(['planFolders', action.folderId, 'open'], false)
    case constants.SET_GET_PLANS_TREE_CANCEL_TOKEN: {
      return state.set('plansTreeCancelToken', action.cancelToken)
    }

    case UPDATE_PLAN_DONE: {
      let newState = state

      const stateFilter = state.get('plansStateFilter')
      if (
        (stateFilter === 'Active' && !action.plan.active) ||
        (stateFilter === 'Inactive' && action.plan.active)
      ) {
        const rootId = newState.getIn(['rootPlansObject', 'rootId'])
        if (rootId) {
          newState = newState
            .deleteIn(['rootPlansObject', 'content', action.plan.id])
            .setIn(
              ['rootPlansObject', 'items'],
              newState.getIn(['rootPlansObject', 'items']) - 1,
            )
        } else {
          newState = newState
            .deleteIn([
              'planFolders',
              action.parentId,
              'content',
              action.plan.id,
            ])
            .setIn(
              ['planFolders', action.parentId, 'items'],
              newState.getIn(['planFolders', action.parentId, 'items']) - 1,
            )
        }
      } else {
        const plan = {
          id: action.plan.id,
          name: action.plan.name,
          version: action.plan.currentRevision
            ? action.plan.currentRevision.version
            : '1',
          parentId: action.plan.parentId,
          type: 'Plan',
          active: action.plan.active,
          lastActivity: action.plan.lastModified,
          items: 0,
          permissions: action.plan.permissions,
          currentRevision: action.plan.currentRevision,
          currentRevisionId:
            action.plan.currentRevision && action.plan.currentRevision.id,
          openTasks: action.plan.openTaskCount,
          planType: action.plan.planType,
        }

        if (action.parentId) {
          newState = newState.setIn(
            ['planFolders', action.parentId, 'content', action.plan.id],
            fromJS(plan),
          )
        } else {
          plan.parentId = null
          newState = newState.setIn(
            ['rootPlansObject', 'content', action.plan.id],
            fromJS(plan),
          )
        }
      }

      return newState
    }
    case constants.MOVE_ITEMS_BATCH:
      return state.set('moveItemsBatchLoading', true)
    case constants.ITEMS_BATCH_MOVED:
      return state
        .set('itemsBatchMoved', true)
        .set('moveItemsBatchLoading', false)
        .set('folderBatchSelection', Map())
        .set('planBatchSelection', Map())
    case constants.MOVE_ITEMS_BATCH_ERROR:
      return state
        .set('moveItemsBatchError', action.error)
        .set('itemsBatchMoved', false)
        .set('moveItemsBatchLoading', false)
    case constants.CLEAR_MOVE_ITEMS_BATCH:
      return state
        .set('moveItemsBatchError', null)
        .set('itemsBatchMoved', false)
        .set('moveItemsBatchLoading', false)

    case constants.CLEAR_PROJECT:
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default planSelectionReducer
