import { Settings } from 'luxon'

import { takeLatest, call, put } from 'redux-saga/effects'

import { DEFAULT_LOCALE } from '../App/constants'
import { formatTranslationMessages, fillGaps } from '../../i18n'

import { CHANGE_LOCALE } from './constants'
import * as actions from './actions'

export function * changeLocaleWatcher () {
  yield takeLatest(CHANGE_LOCALE, changeLanguage)
}

function importLanguageChunk (locale) {
  switch (locale) {
    case 'en':
      return import(/* webpackChunkName: "lang-en" */ './chunks/en')
    case 'de':
      return import(/* webpackChunkName: "lang-de" */ './chunks/de')
    case 'pl':
      return import(/* webpackChunkName: "lang-pl" */ './chunks/pl')
    case 'cs':
      return import(/* webpackChunkName: "lang-cs" */ './chunks/cs')
    case 'bg':
      return import(/* webpackChunkName: "lang-bg" */ './chunks/bg')
    case 'da':
      return import(/* webpackChunkName: "lang-da" */ './chunks/da')
    case 'fr':
      return import(/* webpackChunkName: "lang-fr" */ './chunks/fr')
    case 'hu':
      return import(/* webpackChunkName: "lang-hu" */ './chunks/hu')
    case 'sk':
      return import(/* webpackChunkName: "lang-sk" */ './chunks/sk')
    case 'tr':
      return import(/* webpackChunkName: "lang-tr" */ './chunks/tr')
    case 'pt':
      return import(/* webpackChunkName: "lang-pt" */ './chunks/pt')
    case 'it':
      return import(/* webpackChunkName: "lang-it" */ './chunks/it')
    case 'es':
      return import(/* webpackChunkName: "lang-es" */ './chunks/es')
    case 'sr':
      return import(/* webpackChunkName: "lang-sr" */ './chunks/sr')
    case 'sv':
      return import(/* webpackChunkName: "lang-sv" */ './chunks/sv')
    case 'hr':
      return import(/* webpackChunkName: "lang-hr" */ './chunks/hr')
    case 'ru':
      return import(/* webpackChunkName: "lang-ru" */ './chunks/ru')
    case 'ro':
      return import(/* webpackChunkName: "lang-ro" */ './chunks/ro')
    case 'sl':
      return import(/* webpackChunkName: "lang-sl" */ './chunks/sl')
    case 'nl':
      return import(/* webpackChunkName: "lang-nl" */ './chunks/nl')
    case 'no':
      return import(/* webpackChunkName: "lang-no" */ './chunks/no')
    default:
      return import(/* webpackChunkName: "lang-en" */ './chunks/en')
  }
}

function * changeLanguage (action) {
  const locale = action.locale || DEFAULT_LOCALE

  const { countryNames, messages } = yield call(importLanguageChunk, locale)

  // Set language choosed for luxon
  Settings.defaultLocale = locale

  // Copied from i18n, no clue what this does
  const formattedMessages = fillGaps(
    formatTranslationMessages(locale, messages)
  )

  yield put(actions.changeLocaleDone(locale, formattedMessages, countryNames))
}
