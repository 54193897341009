/* eslint-disable max-len */
import { number, string } from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../../../components/stories/fundamentals/colors-styles/colors'

const PercentageIconSvg = styled.svg`
  width: ${prop('size', 17)}px;
  height: ${prop('size', 17)}px;
  .a {
    fill: none;
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
  }
  .b {
    fill: ${prop('color', colorsOptions.greys['grey-100'])};
  }
`

function PercentageIcon({ color, size }) {
  return (
    <PercentageIconSvg
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      color={color}
      viewBox="0 0 17 17"
    >
      <g transform="translate(0.5 0.5)">
        <g transform="translate(-16 -360)">
          <rect
            className="a"
            width="16"
            height="16"
            rx="2"
            transform="translate(16 360)"
          />
        </g>
        <path
          className="b"
          d="M-.577,5.312A1.4,1.4,0,0,1-.1,4.24,1.666,1.666,0,0,1,1.074,3.8a1.666,1.666,0,0,1,1.169.442,1.44,1.44,0,0,1,0,2.148,1.666,1.666,0,0,1-1.169.442A1.666,1.666,0,0,1-.1,6.388,1.407,1.407,0,0,1-.577,5.312Zm.557,5.22L2.8,7.24,5.4,3.822l.61.537L3.271,7.6.59,11.068Zm1.8-5.22a.887.887,0,0,0-.21-.591.626.626,0,0,0-1,0,.89.89,0,0,0-.21.588.9.9,0,0,0,.21.6.638.638,0,0,0,.5.249.629.629,0,0,0,.5-.251A.91.91,0,0,0,1.782,5.312Zm1.5,4.277a1.407,1.407,0,0,1,.481-1.077A1.666,1.666,0,0,1,4.931,8.07,1.669,1.669,0,0,1,6.1,8.51a1.445,1.445,0,0,1,0,2.148,1.669,1.669,0,0,1-1.172.439,1.671,1.671,0,0,1-1.169-.439A1.4,1.4,0,0,1,3.281,9.589Zm2.363,0a.9.9,0,0,0-.212-.6.638.638,0,0,0-.5-.251.629.629,0,0,0-.5.251.91.91,0,0,0-.208.6.887.887,0,0,0,.21.591.626.626,0,0,0,1,0A.89.89,0,0,0,5.644,9.589Z"
          transform="translate(5 1)"
        />
      </g>
    </PercentageIconSvg>
  )
}

PercentageIcon.propTypes = {
  color: string,
  size: number,
}

export default PercentageIcon
