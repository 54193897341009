import styled from 'styled-components'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'
import {
  sizing,
  weights,
} from '../stories/fundamentals/text-styles/text-styles'

/**
 * @component
 * @deprecated Doesnt seem very reuseable
 */
export default styled.div`
  ${weights['text-500']};
  color: ${colorsOptions.greys['grey-50']};
  ${sizing['text-s']};
  margin-bottom: 10px;
  display: block;
  text-align: center;
`
