/* global self */
import { pathRewriterInstance } from './pathRewriter'
export const ACCEPT = 'Accept'
export const TYPE = 'content-type'
export const TYPE_JSON = 'application/json'
export const TYPE_TEXT = 'text/plain'
export const TYPE_ANY = '*/*'

export const ACCEPT_HEADERS = {
  [ACCEPT]: `${TYPE_JSON}, ${TYPE_TEXT}, ${TYPE_ANY}`,
}
export const JSON_HEADERS = {
  [TYPE]: `${TYPE_JSON};charset=UTF-8`,
}

export const urlWithParams = (url, params, origin) => {
  const u = new URL(url, origin)
  if (params) {
    u.search = new URLSearchParams([
      ...u.searchParams,
      ...Object.entries(params),
    ])
  }
  return u.href
}

/**
 * This is a defacto axios replacement for the subset of the features we're using.
 * This is to avoid loading the full library on the initial page load.
 * Renamed to `callAPI` because it IS NOT feature-complete with axios.
 * FIXME: Cancellations support via `AbortController`
 * MAYBE: Replace axios completely?
 * @param {object} config
 */
export async function callAPI(config) {
  const {
    method: m = 'GET',
    url,
    params,
    headers,
    data,
    cors,
    asFetch,
  } = config

  const { path, baseURL } = pathRewriterInstance.rewritePath({
    path: url,
    baseURL: self.location.origin,
  }) // eslint-disable-line no-restricted-globals

  const method = m.toUpperCase()
  const bodyAllowed = data && method !== 'GET' && method !== 'HEAD'

  const response = await fetch(urlWithParams(path, params, baseURL), {
    method,
    headers: {
      ...ACCEPT_HEADERS,
      ...headers,
      ...(bodyAllowed ? JSON_HEADERS : {}),
    },
    ...(bodyAllowed ? { body: JSON.stringify(data) } : {}),
    cors,
  })

  if (asFetch) return response

  if (!response.ok) {
    const error = new Error(response.statusText)
    error.response = await mimicAxiosResponse(response, config)
    throw error
  }
  return mimicAxiosResponse(response, config)
}

export const callAPIAsFetch = async (url, config = {}) => {
  let body = {}
  if (config.body) {
    delete config.headers['Content-Type']
    body = { data: JSON.parse(config.body) }
  }
  return callAPI({ url, ...config, ...body, asFetch: true })
}

async function mimicAxiosResponse(response, config) {
  const { status, statusText, headers } = response

  const data = headers.get(TYPE)?.startsWith(TYPE_JSON)
    ? await response.json()
    : null

  return {
    data,
    status,
    statusText,
    headers,
    config,
  }
}
