/* eslint-disable operator-linebreak */
import { urlWithParams } from './callAPI'

const ZENDESK_BASE_URL = 'https://support.docu-tools.com'

const zendeskLambdaHref = ['development', 'staging'].includes(
  import.meta.env.VITE_TARGET_ENV
)
  ? 'https://b3mq5s5374.execute-api.eu-central-1.amazonaws.com/staging/'
  : 'https://j8ln1bt2m5.execute-api.eu-central-1.amazonaws.com/production/'

export function makeZendeskRedirectLink (token, returnTo) {
  return urlWithParams(zendeskLambdaHref, {
    token,
    return_to: returnTo
  })
}

export function lambdaifyZendeskLink (token, locale, pathname = '', params) {
  const zendeskURL = urlWithParams(
    `/hc/${locale}${pathname}`,
    params,
    ZENDESK_BASE_URL
  )
  return makeZendeskRedirectLink(token, zendeskURL)
}

export function getZendeskLinks (token, locale, organizationName) {
  const zendeskLandingPageLink = lambdaifyZendeskLink(token, locale)
  const zendeskNewTicketLink = lambdaifyZendeskLink(
    token,
    locale,
    '/requests/new',
    {
      organization: organizationName,
      ...(import.meta.env.VITE_REACT_APP_VERSION
        ? { version: import.meta.env.VITE_REACT_APP_VERSION }
        : {})
    }
  )

  return { zendeskLandingPageLink, zendeskNewTicketLink }
}
