import React from "react";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import { colorOptions, sizeOptions, variantComponents } from "./badges";
import { minimalVariant } from "./styledComponents";

const BadgesComponent = forwardRef(
  ({ size, variant, color, disabled = false, ...props }, fowardedRef) => {
    const Component = variantComponents[variant] || minimalVariant;
    return (
      <Component
        color={disabled ? "disabled" : color}
        size={size}
        ref={fowardedRef}
        {...props}
      />
    );
  }
);

BadgesComponent.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizeOptions)),
  variant: PropTypes.oneOf(Object.keys(variantComponents)),
  color: PropTypes.oneOf(colorOptions),
  disabled: PropTypes.bool,
};

export default BadgesComponent;
