export const EMAIL_FREQUENCY_ITEMS = ['Never', 'Periodically', 'Instantly']

export const UPDATE_EMAIL_FREQUENCY =
  'app/NotificationMenu/UPDATE_EMAIL_FREQUENCY'
export const UPDATE_EMAIL_FREQUENCY_ERROR =
  'app/NotificationMenu/UPDATE_EMAIL_FREQUENCY_ERROR'
export const GET_NOTIFICATIONS = 'app/NotificationMenu/GET_NOTIFICATIONS'
export const MARK_NOTIFICATIONS_AS_READ =
  'app/NotificationMenu/MARK_NOTIFICATIONS_AS_READ'
export const GET_EMAIL_FREQUENCY_DONE =
  'app/NotificationMenu/GET_EMAIL_FREQUENCY_DONE'
export const GET_EMAIL_FREQUENCY = 'app/NotificationMenu/GET_EMAIL_FREQUENCY'
export const GET_SUBSCRIPTIONS_DONE =
  'app/NotificationMenu/GET_SUBSCRIPTIONS_DONE'
export const TOGGLE_SUBSCRIPTION_DONE =
  'app/NotificationMenu/TOGGLE_SUBSCRIPTION_DONE'
export const TOGGLE_SUBSCRIPTION_ERROR =
  'app/NotificationMenu/TOGGLE_SUBSCRIPTION_ERROR'
export const GET_SUBSCRIPTIONS_ERROR =
  'app/NotificationMenu/GET_SUBSCRIPTIONS_ERROR'
export const GET_SUBSCRIPTIONS = 'app/NotificationMenu/GET_SUBSCRIPTIONS'
export const GET_NOTIFICATIONS_DONE =
  'app/NotificationMenu/GET_NOTIFICATIONS_DONE'
export const GET_NOTIFICATIONS_ERROR =
  'app/NotificationMenu/GET_NOTIFICATIONS_ERROR'
export const SET_NOTIFICATIONS_PAGE =
  'app/NotificationMenu/SET_NOTIFICATIONS_PAGE'
export const CHECK_UNREAD_NOTIFICATIONS =
  'app/NotificationMenu/CHECK_UNREAD_NOTIFICATIONS'
export const CHECK_UNREAD_NOTIFICATIONS_DONE =
  'app/NotificationMenu/CHECK_UNREAD_NOTIFICATIONS_DONE'
export const CHECK_UNREAD_NOTIFICATIONS_ERROR =
  'app/NotificationMenu/CHECK_UNREAD_NOTIFICATIONS_ERROR'

export const checkUnreadNotificationDelay = 60000 // millisecs
