import styled from 'styled-components'

export default styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: ${(props) => props.topHeight}px;
  bottom: 0;
  overflow: hidden;
  z-index: 990;
`
