import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import Input from '../Input'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'

/**
 * Input for input form
 * @component
 */
export default styled(Input)`
  border-color: ${ifProp(
    'wrongInput',
    colorsOptions.secondaryColors.orange.strong,
    css`
      ${prop('borderColor', colorsOptions.greys['grey-50'])}
    `,
  )};
  ${ifProp(
    'widthPercent',
    `width: ${prop('widthPercent')}%; display: inline-block;`,
  )}
  &::placeholder {
    ${ifProp('placeholderStyle', prop('placeholderStyle'))}
  }
`
