import { CHANGE_LOCALE, CHANGE_LOCALE_DONE } from './constants'

export function changeLocale(locale, reload, retries = 1) {
  return {
    type: CHANGE_LOCALE,
    locale,
    reload,
    retries,
  }
}

export function changeLocaleDone(locale, messages, countryNames) {
  return {
    type: CHANGE_LOCALE_DONE,
    locale,
    messages,
    countryNames,
  }
}
