const staging = {
  url: 'https://dev.docu-tools.com',
  reports: 'https://dev.docu-tools.com',
  pricingTable: 'https://pricing-table.webv2.dev.docu-tools.com',
  planImport: {
    url: 'https://gy3w0qs5z1.execute-api.eu-central-1.amazonaws.com',
    env: '/staging',
    version: '/v1'
  },
  pdf2jpegBucket: 'docu-tools-staging-temporary-952ydc',
  planCompareBucket: 'docu-tools-staging-plans-952ydc',
  fcmSubscription: {
    url: 'https://iid.googleapis.com',
    prefix: '/iid/v1',
    suffix: '/rel/topics'
  },
  clientId: import.meta.env?.VITE_CLIENT_ID ?? null,
  clientSecret: import.meta.env?.VITE_CLIENT_SECRET ?? null
}

export default staging
