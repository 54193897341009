import conformsTo from 'lodash/conformsTo'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import invariant from 'invariant'
import warning from 'warning'

import createReducer from '../createReducer'

/**
 * Validate the shape of redux store
 */
export function checkStore(store) {
  const shape = {
    dispatch: isFunction,
    subscribe: isFunction,
    getState: isFunction,
    replaceReducer: isFunction,
    runSaga: isFunction,
    asyncReducers: isObject,
  }
  invariant(
    conformsTo(store, shape),
    '(app/utils...) asyncInjectors: Expected a valid redux store',
  )
}

function injectReducer(store, name, reducer, isValid = true) {
  if (!isValid) checkStore(store)

  invariant(
    isString(name) && !isEmpty(name) && isFunction(reducer),
    '(app/utils...) injectAsyncReducer: Expected `reducer` to be a reducer function',
  )

  if (Reflect.has(store.asyncReducers, name)) return
  store.asyncReducers[name] = reducer // eslint-disable-line no-param-reassign
}

/**
 * Inject an asynchronously loaded reducer
 */
export function injectAsyncReducer(store, isValid) {
  return (name, reducer) => {
    injectReducer(store, name, reducer, isValid)
    store.replaceReducer(createReducer(store.asyncReducers))
  }
}

/**
 * Inject asynchronously loaded reducers
 */
export function injectAsyncReducers(store, isValid) {
  return (reducers) => {
    if (!isValid) checkStore(store)

    invariant(
      Array.isArray(reducers),
      '(app/utils...) injectAsyncReducers: Expected `reducers` to be an array of name-reducer pairs',
    )

    reducers.forEach(([name, asyncReducer]) =>
      injectReducer(store, name, asyncReducer, isValid),
    )

    store.replaceReducer(createReducer(store.asyncReducers))
  }
}

/**
 * Inject an asynchronously loaded saga
 */
export function injectAsyncSagas(store, isValid) {
  return function injectSagas(sagas) {
    if (!isValid) checkStore(store)

    invariant(
      Array.isArray(Object.values(sagas)),
      '(app/utils...) injectAsyncSagas: Expected `sagas` to be an array of generator functions',
    )

    warning(
      !isEmpty(Object.values(sagas)),
      '(app/utils...) injectAsyncSagas: Received an empty `sagas` array',
    )

    Object.values(sagas).map(store.runSaga)
  }
}

/**
 * Helper for creating injectors
 */
export function getAsyncInjectors(store) {
  checkStore(store)

  return {
    injectSagas: injectAsyncSagas(store, true),
    injectReducer: injectAsyncReducer(store, true),
    injectReducers: injectAsyncReducers(store, true),
  }
}
