import styled from 'styled-components'
import {
  sizing,
  weights,
} from '../stories/fundamentals/text-styles/text-styles'

/**
 * @component
 */
export default styled.span`
  ${sizing['text-r']};
  ${weights['text-600']};
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`
