import config from '.'

const {
  url,
  reports,
  apiv2,
  planImport,
  notification,
  plansApi,
  projectsApi,
  tasksApi,
  mfaApi,
  membershipsApi,
  teamGroupsApi,
  pinsApi,
  assigneesApi,
  filterApi,
} = config

const rules = [
  {
    from: /^\/api\/oauth/,
    to: '/oauth',
    target: url,
  },
  {
    from: /^\/api\/reports/,
    to: `${apiv2}/reports`,
    target: reports,
  },
  {
    from: /^\/api\/plan-import/,
    to: `${apiv2}/plan-import`,
    target: planImport.url,
  },
  {
    from: /^\/api\/templates/,
    to: `${apiv2}/templates`,
    target: reports,
  },
  {
    from: /^\/api\/notifications/,
    to: `${notification.path}${notification.version}/notifications`,
    target: url,
  },
  {
    from: /^\/api\/plans-api\/v3/,
    to: `${plansApi.path}${plansApi.version3}`,
    target: url,
  },
  {
    from: /^\/api\/plans-api/,
    to: `${plansApi.path}${plansApi.version}`,
    target: url,
  },
  {
    from: /^\/api\/projects-api/,
    to: `${projectsApi.path}${projectsApi.version}`,
    target: url,
  },
  {
    from: /^\/api\/tasks-api/,
    to: `${tasksApi.path}${tasksApi.version}`,
    target: url,
  },
  {
    from: /^\/api\/mfa-api/,
    to: `${mfaApi.path}${mfaApi.version}`,
    target: url,
  },
  {
    from: /^\/api\/memberships-api/,
    to: `${membershipsApi.path}${membershipsApi.version}`,
    target: url,
  },
  {
    from: /^\/api\/team-groups-api/,
    to: `${teamGroupsApi.path}${teamGroupsApi.version}`,
    target: url,
  },
  {
    from: /^\/api\/pins-api/,
    to: `${pinsApi.path}${pinsApi.version}`,
    target: url,
  },
  {
    from: /^\/api\/assignees/,
    to: `${assigneesApi.path}${assigneesApi.version}`,
    target: url,
  },
  {
    from: /^\/api\/filter-api/,
    to: `${filterApi.path}${filterApi.version}`,
    target: url,
  },
  {
    from: /^\/api\/v4\/pins-api/,
    to: `${pinsApi.path}/v4/pins`,
    target: url,
  },
  {
    from: /^\/api\/v2/,
    to: `${apiv2}`,
    target: url,
  },
  {
    from: /^\/api(?!\/v1|\/v3|\/v4)/, // /api NOT followed by /v1, /v2, /v3 or /v4
    to: `${apiv2}`,
    target: url,
  },
]

export default rules
