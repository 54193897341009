import styled, { css } from 'styled-components'
import { any, string } from 'prop-types'
import { ifProp, prop } from 'styled-tools'
import { sizing } from '../stories/fundamentals/text-styles/text-styles'

const FloatingLabel = styled.label`
  visibility: ${ifProp('visible', 'visible', 'hidden')};
  opacity: ${ifProp('visible', '1', '0')};
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  ${sizing['text-s']};
  color: ${prop('color')};
  ${ifProp(
    'maxLength',
    css`
      max-width: ${prop('maxLength')}px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `,
  )}
`

FloatingLabel.propTypes = {
  visible: any,
  maxLength: string,
  color: string,
}

/**
 * Floating label for input field
 * @component
 */
export default FloatingLabel
