export const causeOptions = {
  TO_LOW: 'TO_LOW',
  TO_HIGH: 'TO_HIGH'
}

export const featuresOptions = {
  COPY_PROJECT: 'copy_project',
  DATASETS: 'datasets',
  BULK_TASKS_CREATION: 'bulk_tasks_creation',
  BULK_PINS_ADD_TASKS: 'bulk_tasks_creation',
  BULK_TASKS_EDIT: 'bulk_tasks_edit',
  BULK_PINS_EDIT: 'bulk_pins_edit',
  REPORT_TEMPLATE: 'report_template',
  PLAN_EXPORT: 'plan_export',
  PIN_FIELDS: 'pin_fields'
}

export const buildFeatureRestrictionAlert = (
  featureSlug,
  featureSection,
  tier,
  cause,
  canUpgrade,
  feature,
  legacyUser,
  externalProject,
  currentTier,
  requiredTier
) => {
  const selectedFeature = featureSection
    ? featuresOptions[featureSection]
    : featuresOptions[featureSlug]

  const icon = requiredTier

  if (legacyUser) {
    const defaultLabels = {
      icon,
      currentTier,
      requiredTier,
      titleId: 'restriction_title_to_high',
      messageId: 'restriction_message_is_admin',
      subMessageId: 'restriction_submessage_is_admin',
      closeButton: 'restriction_admin_button'
    }

    if (selectedFeature === featuresOptions.PIN_FIELDS) {
      defaultLabels.closeButton = null
    }

    return defaultLabels
  }

  if (cause === causeOptions.TO_LOW) {
    if (!externalProject) {
      const defaultLabels = {
        icon,
        currentTier,
        requiredTier,
        titleId: 'restriction_title_internal',
        messageId: `restriction_message_internal_${selectedFeature}`,
        subMessageId: `restriction_submessage_internal_${selectedFeature}`,
        closeButton: 'restriction_close_button',
        actionButton: 'restriction_action_button'
      }

      if (selectedFeature === featuresOptions.PIN_FIELDS) {
        defaultLabels.closeButton = null
      }

      if (canUpgrade) {
        return {
          ...defaultLabels,
          subMessageId: `restriction_can_upgrade_submessage_internal_${selectedFeature}`,
          actionButton: 'restriction_can_upgrade_action_button'
        }
      }
    }

    const defaultLabels = {
      icon,
      currentTier,
      requiredTier,
      titleId: 'restriction_title_external',
      messageId: `restriction_message_external_${selectedFeature}`,
      subMessageId: 'restriction_submessage_external',
      closeButton: 'restriction_close_button',
      actionButton: 'restriction_action_button'
    }

    if (selectedFeature === featuresOptions.PIN_FIELDS) {
      defaultLabels.closeButton = null
    }

    if (canUpgrade) {
      return {
        ...defaultLabels,
        subMessageId: 'restriction_can_upgrade_submessage_external',
        actionButton: 'restriction_can_upgrade_action_button'
      }
    }

    return defaultLabels
  }

  const defaultLabels = {
    icon,
    currentTier,
    requiredTier,
    titleId: 'restriction_title_to_high',
    messageId: 'restriction_message_to_high',
    subMessageId: 'restriction_submessage_to_high',
    closeButton: 'restriction_close_button',
    actionButton: 'restriction_action_button'
  }

  if (selectedFeature === featuresOptions.PIN_FIELDS) {
    defaultLabels.closeButton = null
  }

  return defaultLabels
}
