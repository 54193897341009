export const popupSize = {
  X_LARGE: 'X_LARGE',
  LARGE: 'LARGE',
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL',
}
export const buttonType = {
  CANCEL: 'CANCEL',
  DELETE: 'DELETE',
  DOWNLOAD: 'DOWNLOAD',
  BUTTON: 'BUTTON',
  A: 'A',
  ROUND_ICON: 'ROUND_ICON',
  LINK: 'LINK',
  DROPDOWN: 'DROPDOWN',
}
export const loadingType = {
  DOTS: 'DOTS',
  JUST_TEXT: 'JUST_TEXT',
}
