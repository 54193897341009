import { node, string } from 'prop-types'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'
import {
  sizing,
  weights,
} from '../stories/fundamentals/text-styles/text-styles'

const Wrapper = styled.span`
  /* font-size: ${prop('size')}; */
  /* font-family: ${prop('weight')}; */
  color: ${prop('color')};
  margin: ${prop('margin')};
  ${ifProp('disabled', `color: ${colorsOptions.greys['grey-60']};`)}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: ${prop('textalign')};
  ${ifProp(
    { as: 'p' },
    css`
      white-space: normal;
    `,
  )}
  ${({ size = 'text-r' }) => sizing[size]};
  ${({ weight = 'text-500' }) => weights[weight]};
`

function Typography({
  size = 'text-r',
  children,
  weight = 'text-500',
  color = colorsOptions.greys['grey-100'],
  as,
  margin,
  textalign,
  ...props
}) {
  return (
    <Wrapper
      as={as}
      size={size}
      weight={weight}
      color={color}
      margin={margin}
      textalign={textalign}
      {...props}
    >
      {children}
    </Wrapper>
  )
}

Typography.propTypes = {
  size: string,
  color: string,
  weight: string,
  children: node,
  as: string,
  margin: string,
  textalign: string,
}

export default Typography
