import styled from 'styled-components'
import { prop, ifProp } from 'styled-tools'
import PropTypes from 'prop-types'
import colorsOptions from '../stories/fundamentals/colors-styles/colors'
import {
  sizing,
  weights,
} from '../stories/fundamentals/text-styles/text-styles'

const Errormsg = styled.div`
  color: ${prop('color', colorsOptions.secondaryColors.orange.strong)};
  ${ifProp('center', 'text-align: center;')}
  ${ifProp('hide', 'visibility: hidden;')}
  ${sizing['text-r']};
  ${weights['text-600']};
  margin-bottom: ${prop('marginBottom', '5')}px;
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`

Errormsg.propTypes = {
  color: PropTypes.string,
  center: PropTypes.bool,
  marginBottom: PropTypes.number,
  /** Hide the error message but preserve the area occupied by the error component */
  hide: PropTypes.bool,
}

/**
 * Shows error messages on forms
 * @component
 */
export default Errormsg
