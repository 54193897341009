import { string } from 'prop-types'
import { Datasets, Combo, Multi, Master, Pocket } from '../icons'

const TierIcon = ({ icon }) => {
  switch (icon) {
    case 'datasets':
      return <Datasets className="tierIcon" />
    case 'combo':
      return <Combo className="tierIcon" />
    case 'multi':
      return <Multi className="tierIcon" />
    case 'enterprise':
      return <Master className="tierIcon" />
    default:
      return <Pocket className="tierIcon" />
  }
}

TierIcon.propTypers = {
  icon: string
}

export default TierIcon
