/* eslint-disable no-param-reassign */
import Axios from 'axios'
import { pathRewriterInstance } from './pathRewriter'

const axiosInstance = () => {
  const axios = Axios.create({})
  axios.CancelToken = Axios.CancelToken
  axios.isCancel = Axios.isCancel

  axios.interceptors.request.use((config) => {
    const { path, baseURL } = pathRewriterInstance.rewritePath({
      baseURL: config.baseURL,
      path: config.url,
    })
    config.baseURL = baseURL
    config.url = path
    return config
  })

  return axios
}
export const axios = axiosInstance()

export const setDefaultToken = (token) => {
  axios.defaults.headers.common.Authorization = token
}

export default axios
