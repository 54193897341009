import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom'

export function withHistory(Component) {
  return (props) => <Component {...props} history={useHistory()} />
}

export function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />
}

export function withLocation(Component) {
  return (props) => <Component {...props} location={useLocation()} />
}

export function withRouteMatch(Component) {
  return (props) => <Component {...props} match={useRouteMatch()} />
}

export function withRouter(Component) {
  return (props) => (
    <Component
      {...props}
      history={useHistory()}
      location={useLocation()}
      match={useRouteMatch()}
      params={useParams()}
    />
  )
}
