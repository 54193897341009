export const SAVE_FIELDS = 'app/UserSettings/SAVE_FIELDS'
export const CHANGE_LOCALE_FINISHED = 'app/UserSettings/CHANGE_LOCALE_FINISHED'
export const CHANGE_LOCALE_ERROR = 'app/UserSettings/CHANGE_LOCALE_ERROR'
export const FIELDS_SAVED = 'app/UserSettings/FIELDS_SAVED'
export const FIELDS_SAVE_ERROR = 'app/UserSettings/FIELDS_SAVE_ERROR'
export const UPLOAD_PROFILE_PICTURE = 'app/UserSettings/UPLOAD_PROFILE_PICTURE'
export const PROFILE_PICTURE_UPLOADED =
  'app/UserSettings/PROFILE_PICTURE_UPLOADED'
export const PROFILE_PICTURE_UPLOAD_ERROR =
  'app/UserSettings/PROFILE_PICTURE_UPLOAD_ERROR'
export const SAVE_PASSWORD = 'app/UserSettings/SAVE_PASSWORD'
export const PASSWORD_SAVED = 'app/UserSettings/PASSWORD_SAVED'
export const GET_PASSWORD_POLICY = 'app/UserSettings/GET_PASSWORD_POLICY'
export const GET_PASSWORD_POLICY_DONE =
  'app/UserSettings/GET_PASSWORD_POLICY_DONE'
export const PASSWORD_SAVE_ERROR = 'app/UserSettings/PASSWORD_SAVE_ERROR'
export const ACTIVATE_TWO_FA = 'app/UserSettings/ACTIVATE_TWO_FA'
export const DEACTIVATE_TWO_FA = 'app/UserSettings/DEACTIVATE_TWO_FA'
export const HIDE_QR = 'app/UserSettings/HIDE_QR'
export const OPEN_QR = 'app/UserSettings/OPEN_QR'
export const SHOW_QR = 'app/UserSettings/SHOW_QR'
export const TWO_FA_ERROR = 'app/UserSettings/TWO_FA_ERROR'
export const DEACTIVATE_TWO_FA_SUCCESSFUL =
  'app/UserSettings/DEACTIVATE_TWO_FA_SUCCESSFUL'
export const ACTIVATE_TWO_FA_SMS = 'app/UserSettings/ACTIVATE_TWO_FA_SMS'
export const DEACTIVATE_TWO_FA_SMS = 'app/UserSettings/DEACTIVATE_TWO_FA_SMS'
export const DEACTIVATE_TWO_FA_SMS_DONE =
  'app/UserSettings/DEACTIVATE_TWO_FA_SMS_DONE'
export const SHOW_VERIFY_TWO_FA_SMS = 'app/UserSettings/SHOW_VERIFY_TWO_FA_SMS'
export const VERIFY_TWO_FA_SMS = 'app/UserSettings/VERIFY_TWO_FA_SMS'
export const VERIFY_TWO_FA_SMS_DONE = 'app/UserSettings/VERIFY_TWO_FA_SMS_DONE'
export const TWO_FA_SMS_ERROR = 'app/UserSettings/TWO_FA_SMS_ERROR'
export const SAVE_EMAIL = 'app/UserSettings/SAVE_EMAIL'
export const SAVE_EMAIL_ERROR = 'app/UserSettings/SAVE_EMAIL_ERROR'
export const SAVE_EMAIL_SUCCESS = 'app/UserSettings/SAVE_EMAIL_SUCCESS'
export const GET_TWO_FA_STATUS = 'app/UserSettings/GET_TWO_FA_STATUS'
export const GET_TWO_FA_STATUS_DONE = 'app/UserSettings/GET_TWO_FA_STATUS_DONE'
export const GET_LIMIT_DEVICES_LIST = 'app/UserSettings/GET_LIMIT_DEVICES_LIST'
export const GET_LIMIT_DEVICES_LIST_DONE =
  'app/UserSettings/GET_LIMIT_DEVICES_LIST_DONE'
export const GET_LIMIT_DEVICES_LIST_ERROR =
  'app/UserSettings/GET_LIMIT_DEVICES_LIST_ERROR'
export const CLEAR_UNREGISTER_DEVICE =
  'app/UserSettings/CLEAR_UNREGISTER_DEVICE'
export const UNREGISTER_DEVICE = 'app/UserSettings/UNREGISTER_DEVICE'
export const UNREGISTER_DEVICE_DONE = 'app/UserSettings/UNREGISTER_DEVICE_DONE'
export const UNREGISTER_DEVICE_ERROR =
  'app/UserSettings/UNREGISTER_DEVICE_ERROR'

export const validationErrors = {
  TOO_SHORT: 'app/UserSettings/TOO_SHORT',
  TOO_LONG: 'app/UserSettings/TOO_LONG',
  REQUIRED: 'app/UserSettings/REQUIRED',
  NOT_VALID: 'app/UserSettings/NOT_VALID',
  NOT_MATCH: 'app/UserSettings/NOT_MATCH',
  MATCH: 'app/UserSettings/MATCH',
  NOT_CONTAINS_REQUIRED_VALUES: 'app/UserSettings/NOT_CONTAINS_REQUIRED_VALUES',
  CONTAINS_NAME: 'app/UserSettings/CONTAINS_NAME',
}

export const imageSizes = {
  SMALL: 'UPLOAD_IMAGE_SIZE_SMALL',
  MEDIUM: 'UPLOAD_IMAGE_SIZE_MEDIUM',
  LARGE: 'UPLOAD_IMAGE_SIZE_LARGE',
}

export const MFA_ORG_ID = {
  production: '74951be4-8f72-4efc-bc7e-88f2ffb69e25',
  development: '985d153a-e8b6-4f14-a2d8-8cbe12c838d2',
  staging: '985d153a-e8b6-4f14-a2d8-8cbe12c838d2',
}

export const DEFAULT_POLICY = {
  allowReuse: false,
  blockNames: false,
  minimumLength: 8,
  maximumLength: 0,
  requiredCharacterClasses: [],
  daysToLife: 0,
}

export const deviceSizeType = {
  TABLET: 'TABLET',
  PHONE: 'PHONE',
}

export const deviceType = {
  I_PAD: 'iPad',
  I_PHONE: 'iPhone',
  ANDROID: 'Android',
}
export const mobilePages = {
  MAIN: 'MAIN',
  CALL_PHONE: 'CALL PHONE',
  PHOTO_SIZE: 'PHOTO SIZE',
  LANGUAGE: 'LANGUAGE',
  TWO_STEP_INFO: 'TWO STEP INFO',
  BETA_INFO: 'BETA INFO',
  PASSWORD: 'PASSWORD',
  CHECK_FOR_UPDATES: 'CHECK_FOR_UPDATES',
  ENABLE_BETA_APP: 'ENABLE_BETA_APP',
  TWO_FA: 'TWO_FA',
  SHOW_QR_CODE: 'SHOW_QR_CODE',
}

export const imageSizeIds = {
  UPLOAD_IMAGE_SIZE_SMALL: 'photoSizeSelection_small',
  UPLOAD_IMAGE_SIZE_MEDIUM: 'photoSizeSelection_medium',
  UPLOAD_IMAGE_SIZE_LARGE: 'photoSizeSelection_large',
}
export const imageSizeFromId = {
  photoSizeSelection_small: 'SMALL',
  photoSizeSelection_medium: 'MEDIUM',
  photoSizeSelection_large: 'LARGE',
}
export const PHOTO_SIZE = ['SMALL', 'MEDIUM', 'LARGE']

export const SERVICE_LINE = '+43 1 890 32 27 27'
