import * as actionTypes from './constants'

export function openQuickSelectProject() {
  return {
    type: actionTypes.OPEN_PROJECTS,
  }
}
export function closeQuickSelectProject() {
  return {
    type: actionTypes.CLOSE_PROJECTS,
  }
}

export function openProjectFolder(folders) {
  return {
    type: actionTypes.OPEN_PROJECT_FOLDER,
    folders,
  }
}

export function openQuickSelectPlan() {
  return {
    type: actionTypes.OPEN_PLANS,
  }
}
export function closeQuickSelectPlan() {
  return {
    type: actionTypes.CLOSE_PLANS,
  }
}
export function openPlanFolder(folders) {
  return {
    type: actionTypes.OPEN_PLAN_FOLDER,
    folders,
  }
}

export function setProjectFilter(filter) {
  return {
    type: actionTypes.SET_PROJECT_FILTER,
    filter,
  }
}

export function setPlanFilter(filter) {
  return {
    type: actionTypes.SET_PLAN_FILTER,
    filter,
  }
}

export function clearPlanFolderSelectBox() {
  return {
    type: actionTypes.CLEAR_PLAN_FOLDER_SELECT_BOX,
  }
}
export function clearPlans() {
  return {
    type: actionTypes.CLEAR_PLANS,
  }
}
export function clearFolderPlans(folderId) {
  return {
    type: actionTypes.CLEAR_FOLDER_PLANS,
    folderId,
  }
}
export function getRootPlans(projectId, page, search, refresh, retries = 1) {
  return {
    type: actionTypes.GET_ROOT_PLANS,
    projectId,
    page,
    search,
    refresh,
    retries,
  }
}
export function getRootPlansDone(plans, timestamp, cached) {
  return {
    type: actionTypes.GET_ROOT_PLANS_DONE,
    plans,
    timestamp,
    cached,
  }
}
export function getRootPlansError(error) {
  return {
    type: actionTypes.GET_ROOT_PLANS_ERROR,
    error,
  }
}
export function getFolderPlans(folderId, page, parentId, refresh, retries = 1) {
  return {
    type: actionTypes.GET_FOLDER_PLANS,
    folderId,
    page,
    parentId,
    refresh,
    retries,
  }
}
export function getFolderPlansDone(
  folderId,
  plans,
  parentId,
  timestamp,
  cached,
) {
  return {
    type: actionTypes.GET_FOLDER_PLANS_DONE,
    folderId,
    plans,
    parentId,
    timestamp,
    cached,
  }
}
export function getFolderPlansError(folderId, error) {
  return {
    type: actionTypes.GET_FOLDER_PLANS_ERROR,
    error,
    folderId,
  }
}
export function setCurrentPlanFolderId(currentFolderId) {
  return {
    type: actionTypes.SET_CURRENT_PLAN_FOLDER_ID,
    currentFolderId,
  }
}
export function setGetPlanTreeCancelToken(cancelToken) {
  return {
    type: actionTypes.SET_GET_PLAN_TREE_CANCEL_TOKEN,
    cancelToken,
  }
}
