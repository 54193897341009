import { difference } from 'lodash'

import { DEFAULT_LOCALE } from './containers/App/constants'
import { messages as enTranslationMessages } from './containers/LanguageProvider/chunks/en'

export const appLocales = [
  'bg',
  'hr',
  'cs',
  'da',
  'de',
  'en',
  'es',
  'fr',
  'hu',
  'it',
  'nl',
  'no',
  'pl',
  'pt',
  'tr',
  'ro',
  'ru',
  'sk',
  'sl',
  'sr',
  'sv',
]

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {}

  return Object.keys(messages).reduce((formattedMessages, key) => {
    let message = messages[key]
    if (!message && locale !== DEFAULT_LOCALE) {
      message = defaultFormattedMessages[key]
    }

    // eslint-disable-next-line no-param-reassign
    formattedMessages[key] = message

    return formattedMessages
  }, {})
}

export const fillGaps = (formattedMessages) => {
  const diffKeys = difference(
    Object.keys(enTranslationMessages),
    Object.keys(formattedMessages),
  )
  diffKeys.forEach((missingKey) => {
    // eslint-disable-next-line no-param-reassign
    formattedMessages[missingKey] = enTranslationMessages[missingKey]
  })
  return formattedMessages
}
