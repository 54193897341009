export const LOAD_PROJECTS = 'app/ProjectSelection/LOAD_PROJECTS'
export const LOAD_OWN_PROJECTS = 'app/ProjectSelection/LOAD_OWN_PROJECTS'
export const SET_OWN_PROJECT_SORT = 'app/ProjectSelection/SET_OWN_PROJECT_SORT'
export const PROJECTS_LOADED = 'app/ProjectSelection/PROJECTS_LOADED'
export const ACTIVE_PROJECTS_LOADED =
  'app/ProjectSelection/ACTIVE_PROJECTS_LOADED'
export const OWN_PROJECTS_LOADED = 'app/ProjectSelection/OWN_PROJECTS_LOADED'
export const PROJECTS_LOAD_ERROR = 'app/ProjectSelection/PROJECTS_LOAD_ERROR'
export const FILTER_PROJECTS = 'app/ProjectSelection/FILTER_PROJECTS'
export const CREATE_FOLDER = 'app/ProjectSelection/CREATE_FOLDER'
export const FOLDER_CREATED = 'app/ProjectSelection/FOLDER_CREATED'
export const CREATE_FOLDER_ERROR = 'app/ProjectSelection/CREATE_FOLDER_ERROR'
export const CLEAR_CREATE_FOLDER = 'app/ProjectSelection/CLEAR_CREATE_FOLDER'
export const PROJECT_DRAG_N_DROP = 'app/ProjectSelection/PROJECT_DRAG_N_DROP'
export const FOLDER_DRAG_N_DROP = 'app/ProjectSelection/FOLDER_DRAG_N_DROP'
export const MOVE_PROJECT = 'app/ProjectSelection/MOVE_PROJECT'
export const PROJECT_MOVED = 'app/ProjectSelection/PROJECT_MOVED'
export const MOVE_PROJECT_ERROR = 'app/ProjectSelection/MOVE_PROJECT_ERROR'
export const CLEAR_MOVE_PROJECT = 'app/ProjectSelection/CLEAR_MOVE_PROJECT'
export const MOVE_FOLDER = 'app/ProjectSelection/MOVE_FOLDER'
export const FOLDER_MOVED = 'app/ProjectSelection/FOLDER_MOVED'
export const MOVE_FOLDER_ERROR = 'app/ProjectSelection/MOVE_FOLDER_ERROR'
export const CLEAR_MOVE_FOLDER = 'app/ProjectSelection/CLEAR_MOVE_FOLDER'

export const ITEMS_BATCH_MOVED = 'app/ProjectSelection/ITEMS_BATCH_MOVED'
export const MOVE_ITEMS_BATCH_ERROR =
  'app/ProjectSelection/MOVE_ITEMS_BATCH_ERROR'
export const MOVE_ITEMS_BATCH = 'app/ProjectSelection/MOVE_ITEMS_BATCH'
export const SELECT_FOLDER_BATCH = 'app/ProjectSelection/SELECT_FOLDER_BATCH'
export const DESELECT_FOLDER_BATCH =
  'app/ProjectSelection/DESELECT_FOLDER_BATCH'
export const DESELECT_ALL_FOLDER_BATCH =
  'app/ProjectSelection/DESELECT_ALL_FOLDER_BATCH'
export const SELECT_PROJECT_BATCH = 'app/ProjectSelection/SELECT_PROJECT_BATCH'
export const DESELECT_PROJECT_BATCH =
  'app/ProjectSelection/DESELECT_PROJECT_BATCH'
export const DESELECT_ALL_PROJECT_BATCH =
  'app/ProjectSelection/DESELECT_ALL_PROJECT_BATCH'
export const ITEMS_BATCH_DRAG_N_DROP =
  'app/ProjectSelection/ITEMS_BATCH_DRAG_N_DROP'
export const CLEAR_MOVE_ITEMS_BATCH =
  'app/ProjectSelection/CLEAR_MOVE_FOLDERS_BATCH'

export const UPDATE_FOLDER = 'app/ProjectSelection/UPDATE_FOLDER'
export const UPDATE_FOLDER_DONE = 'app/ProjectSelection/UPDATE_FOLDER_DONE'
export const UPDATE_FOLDER_ERROR = 'app/ProjectSelection/UPDATE_FOLDER_ERROR'

export const UPDATE_PROJECT_BATCH = 'app/ProjectSelection/UPDATE_PROJECT_BATCH'
export const UPDATE_PROJECT_BATCH_DONE =
  'app/ProjectSelection/UPDATE_PROJECT_BATCH_DONE'
export const UPDATE_PROJECT_BATCH_ERROR =
  'app/ProjectSelection/UPDATE_PROJECT_BATCH_ERROR'

export const UPDATE_PROJECT = 'app/ProjectSelection/UPDATE_PROJECT'
export const UPDATE_PROJECT_DONE = 'app/ProjectSelection/UPDATE_PROJECT_DONE'
export const UPDATE_PROJECT_ERROR = 'app/ProjectSelection/UPDATE_PROJECT_ERROR'

export const CHECK_CAN_CREATE_PROJECT =
  'app/ProjectSelection/CHECK_CAN_CREATE_PROJECT'
export const CHECK_CAN_CREATE_PROJECT_DONE =
  'app/ProjectSelection/CHECK_CAN_CREATE_PROJECT_DONE'
export const CHECK_CAN_CREATE_PROJECT_ERROR =
  'app/ProjectSelection/CHECK_CAN_CREATE_PROJECT_ERROR'

export const USER_LEAVE_PROJECT = 'app/ProjectSelection/USER_LEAVE_PROJECT'
export const USER_LEAVE_PROJECT_DONE =
  'app/ProjectSelection/USER_LEAVE_PROJECT_DONE'
export const USER_LEAVE_PROJECT_ERROR =
  'app/ProjectSelection/USER_LEAVE_PROJECT_ERROR'

export const DELETE_EMPTY_FOLDER = 'app/ProjectSelection/DELETE_EMPTY_FOLDER'
export const DELETE_EMPTY_FOLDER_DONE =
  'app/ProjectSelection/DELETE_EMPTY_FOLDER_DONE'
export const DELETE_EMPTY_FOLDER_ERROR =
  'app/ProjectSelection/DELETE_EMPTY_FOLDER_ERROR'

// ---- new project selection

export const CLEAR_PROJECTS = 'app/ProjectSelection/CLEAR_PROJECTS'
export const GET_ROOT_PROJECTS = 'app/ProjectSelection/GET_ROOT_PROJECTS'
export const GET_ROOT_PROJECTS_DONE =
  'app/ProjectSelection/GET_ROOT_PROJECTS_DONE'
export const GET_ROOT_PROJECTS_ERROR =
  'app/ProjectSelection/GET_ROOT_PROJECTS_ERROR'

export const GET_FOLDER_PROJECTS = 'app/ProjectSelection/GET_FOLDER_PROJECTS'
export const GET_FOLDER_PROJECTS_DONE =
  'app/ProjectSelection/GET_FOLDER_PROJECTS_DONE'
export const GET_FOLDER_PROJECTS_ERROR =
  'app/ProjectSelection/GET_FOLDER_PROJECTS_ERROR'
export const OPEN_PROJECT_FOLDER = 'app/ProjectSelection/OPEN_PROJECT_FOLDER'
export const CLOSE_PROJECT_FOLDER = 'app/ProjectSelection/CLOSE_PROJECT_FOLDER'

export const GET_PROJECT_PATH = 'app/ProjectSelection/GET_PROJECT_PATH'
export const GET_PROJECT_PATH_DONE =
  'app/ProjectSelection/GET_PROJECT_PATH_DONE'
export const GET_PROJECT_PATH_ERROR =
  'app/ProjectSelection/GET_PROJECT_PATH_ERROR'

export const SET_GET_PROJECT_TREE_CANCEL_TOKEN =
  'app/ProjectSelection/SET_GET_PROJECT_TREE_CANCEL_TOKEN'
export const FAILED = 'FAILED'

export const VIEW_TYPES = {
  list: 'list',
  mobileList: 'mobileList',
  tiles: 'tiles',
}
export const SORT_TYPES = {
  name: 'name',
  lastActivity: 'lastActivity',
  lastModified: 'lastModified',
  date: 'date',
  type: 'type',
  favorites: 'favorites',
  organisationName: 'organisationName',
  offlineOnline: 'offlineOnline',
}

export const ONLINE_OFFLINE_GROUPS = [
  'offlineAvailableContent',
  'onlineOnlyAvailableContent',
]
