import * as actionTypes from './constants'

export function getFeatureInfoByProject(featureSlug, projectId) {
  return {
    type: actionTypes.GET_FEATURE_INFO_BY_PROJECT,
    featureSlug,
    projectId
  }
}

export function getFeatureInfoByProjectDone(
  projectId,
  featureSlug,
  featureInfo
) {
  return {
    type: actionTypes.GET_FEATURE_INFO_BY_PROJECT_DONE,
    projectId,
    featureSlug,
    featureInfo
  }
}

export function getFeatureInfoByProjectError(projectId, featureSlug, err) {
  return {
    type: actionTypes.GET_FEATURE_INFO_BY_PROJECT_ERROR,
    projectId,
    featureSlug,
    err
  }
}

export function getFeatureInfoByOrganisation(featureSlug) {
  return {
    type: actionTypes.GET_FEATURE_INFO_BY_ORGANISATION,
    featureSlug
  }
}

export function getFeatureInfoByOrganisationDone(
  organisationId,
  featureSlug,
  featureInfo
) {
  return {
    type: actionTypes.GET_FEATURE_INFO_BY_ORGANISATION_DONE,
    organisationId,
    featureSlug,
    featureInfo
  }
}

export function getFeatureInfoByOrganisationError(
  organisationId,
  featureSlug,
  err
) {
  return {
    type: actionTypes.GET_FEATURE_INFO_BY_ORGANISATION_ERROR,
    organisationId,
    featureSlug,
    err
  }
}

export function getFeatureLabelIds() {
  return {
    type: actionTypes.GET_FEATURE_LABEL_IDS
  }
}

export function setFeatureLabelIds(featureLabelIds) {
  return {
    type: actionTypes.SET_FEATURE_LABEL_IDS,
    featureLabelIds
  }
}

export function getUnavailableFeatureSlug() {
  return {
    type: actionTypes.GET_UNAVAILABLE_FEATURE_SLUG
  }
}

export function setUnavailableFeatureSlug(
  featureSlug,
  featureSection,
  tier,
  restriction,
  currentTier,
  requiredTier
) {
  return {
    type: actionTypes.SET_UNAVAILABLE_FEATURE_SLUG,
    featureSlug,
    featureSection,
    tier,
    restriction,
    currentTier,
    requiredTier
  }
}
