import { createSelector } from 'reselect'

const selectFeatureInfoState = (state) => state.get('featureInfo')

export const makeSelectGetUnavailableFeatureSlug = () =>
  createSelector(selectFeatureInfoState, (substate) =>
    substate?.get('unavailableFeatureSlug')
  )

export const makeSelectFeatureInfo = (featureSlug, projectId) =>
  createSelector(selectFeatureInfoState, (substate) => {
    return (
      substate?.get(`${featureSlug}-${projectId}`) ||
      substate?.get(featureSlug) ||
      {}
    )
  })

export const makeSelectGettingFeatureInfo = () =>
  createSelector(selectFeatureInfoState, (substate) =>
    substate?.get('gettingFeatureInfo')
  )

export const makeSelectGetFeatureInfoError = () =>
  createSelector(selectFeatureInfoState, (substate) =>
    substate?.get('getFeatureInfoError')
  )

export const makeSelectSetUnavailableFeatureSlug = (unavailableFeatureSlug) =>
  createSelector(selectFeatureInfoState, (substate) =>
    substate?.set('unavailableFeatureSlug', unavailableFeatureSlug)
  )

export const makeSelectGetUnavailableFeatureSection = () =>
  createSelector(selectFeatureInfoState, (substate) =>
    substate?.get('unavailableFeatureSection')
  )
export const makeSelectGetFeatureLabelIds = () =>
  createSelector(selectFeatureInfoState, (substate) =>
    substate?.get('featureLabelIds')
  )
